














import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';
import { TimelinePoint } from '@/repository/artsub-backend-types';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

@Component({
  components: {
    LineChartGenerator,
  },
})
export default class ChartLikeViewLine extends Vue {
  @Prop({ required: true })
  labels!: Array<string>;

  @Prop({ required: true })
  viewsTimeline!: Array<TimelinePoint>;

  @Prop({ required: true })
  likesTimeline!: Array<TimelinePoint>;

  @Prop({ type: String, default: 'line-chart' })
  chartId!: string;

  @Prop({ type: String, default: 'label' })
  datasetIdKey!: string;

  @Prop({ type: Number, default: 400 })
  width!: number;

  @Prop({ type: Number, default: 500 })
  height!: number;

  @Prop({ type: String, default: '' })
  cssClasses!: string;

  plugins = [
    {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart: { width?: any; height?: any; ctx?: any; chartArea?: any }) => {
        const { ctx } = chart;
        const { chartArea } = chart;

        ctx.save();
        ctx.fillStyle = '#EEEEEE';
        ctx.fillRect(
          20,
          chartArea.top - 15,
          chartArea.left + chartArea.right - 50,
          chartArea.bottom,
        );
        ctx.restore();
      },
    },
    {
      id: 'legend_margin',
      beforeInit: (chart: any, legend: any, options: any) => {
        const fitValue = chart.legend.fit;

        // eslint-disable-next-line no-param-reassign
        chart.legend.fit = function fit() {
          fitValue.bind(chart.legend)();
          // eslint-disable-next-line no-return-assign
          return (this.height += 20);
        };
      },
    },
  ];

  // eslint-disable-next-line class-methods-use-this
  get chartData(): any {
    return {
      labels: this.labels,
      datasets: [
        {
          label: '閲覧数',
          borderColor: '#2196f3',
          backgroundColor: '#2196f3',
          data: this.viewsTimeline.map(({ count }) => count),
        },
        {
          label: 'いいね数',
          borderColor: '#fa5096',
          backgroundColor: '#fa5096',
          data: this.likesTimeline.map(({ count }) => count),
        },
      ],
    };
  }

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          boxHeight: 1,
          boxWidth: 30,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        left: 40,
        right: 60,
        bottom: 40,
      },
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          color: '#FFFFFF',
        },
      },
      y: {
        grid: {
          drawBorder: false,
          color: '#FFFFFF',
        },
      },
    },
  };
}
