












































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getTopViewsDigitalArtList,
  getUserLikesTimeline,
  getUserViewsTimeline,
} from '@/repository/artsub-backend';
import ChartLikeViewLineMobile from '@/components/ChartLikeViewLineMobile.vue';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import { HazeruArtDigitalArt, TimelinePoint, User } from '../../repository/artsub-backend-types';
import ChartLikeViewLine from '../../components/ChartLikeViewLine.vue';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    ChartLikeViewLine,
    ChartLikeViewLineMobile,
  },
})
export default class ArtistAnalytics extends Vue {
  user!: User | null;

  jwtToken!: string | null;

  labels: Array<string> = [];

  viewsTimeline: Array<TimelinePoint> = [];

  likesTimeline: Array<TimelinePoint> = [];

  popularDigitalArts: Array<DigitalArtAdapter> = [];

  isLoaded = false;

  displayPeriods = ['直近7日', '直近28日'];

  selectedDisplayPeriod = '直近7日';

  async created(): Promise<void> {
    if (this.user) {
      await this.fetchTimeline();
      this.popularDigitalArts = (await getTopViewsDigitalArtList(this.jwtToken)).data.map(
        (art: HazeruArtDigitalArt) => new DigitalArtAdapter(art),
      );
      this.isLoaded = true;
    }
  }

  @Watch('selectedDisplayPeriod')
  async changeSelectedDisplayPeriod(): Promise<void> {
    this.isLoaded = false;
    if (this.user) {
      await this.fetchTimeline();
    }
    this.isLoaded = true;
  }

  async fetchTimeline(): Promise<void> {
    if (this.user) {
      this.viewsTimeline = (
        await getUserViewsTimeline(this.jwtToken, this.user.cognito_id, this.timelineDays)
      ).data.timeline;
      this.likesTimeline = (
        await getUserLikesTimeline(this.jwtToken, this.user.cognito_id, this.timelineDays)
      ).data.timeline;
      this.labels = this.viewsTimeline.map((point) => point.date);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  rankingIcon(i: number): string {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`@/assets/ranking/${i}.svg`);
  }

  get timelineDays(): number {
    switch (this.selectedDisplayPeriod) {
      case '直近7日':
        return 7;
      case '直近28日':
        return 28;
      default:
        return 7;
    }
  }

  get isMobile(): boolean {
    return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
  }
}
