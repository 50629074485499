type Icon = {
  id: string;
  user_id: string; // eslint-disable-line camelcase
  filename: string;
  sha1digest: string;
  contenttype: string;
  presigned_get_url: string; // eslint-disable-line camelcase
  updated_datetime: string; // eslint-disable-line camelcase
};

type IconPostBody = {
  filename: string;
  sha1digest: string;
  conenttype: string;
};

type ShippingAddress = {
  id: string;
  first_name: string; // eslint-disable-line camelcase
  last_name: string; // eslint-disable-line camelcase
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  postal_code: string; // eslint-disable-line camelcase
  state: string;
  phone: string;
  updated_datetime: string; // eslint-disable-line camelcase
};

type ShippingAddressPostBody = {
  first_name: string; // eslint-disable-line camelcase
  last_name: string; // eslint-disable-line camelcase
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  postal_code: string; // eslint-disable-line camelcase
  state: string;
  phone: string;
};

type ArtistApprovalRequest = {
  introduction: string;
  is_approved: boolean; // eslint-disable-line camelcase
  requested_by: string; // eslint-disable-line camelcase
  requested_datetime: string; // eslint-disable-line camelcase
  approved_datetime: string; // eslint-disable-line camelcase
};

type ArtistBankInformation = {
  id: string | null;
  bank_code: number | null; // eslint-disable-line camelcase
  branch_code: number | null; // eslint-disable-line camelcase
  account_number: number | null; // eslint-disable-line camelcase
  account_name: string; // eslint-disable-line camelcase
  account_yomi: string; // eslint-disable-line camelcase
};

function generateArtistBankInformationDefaults(): ArtistBankInformation {
  return {
    id: null,
    bank_code: null,
    branch_code: null,
    account_number: null,
    account_name: '',
    account_yomi: '',
  };
}

// 自分自身のユーザ型。住所等のプライベート情報が入っている
type User = {
  cognito_id: string; // eslint-disable-line camelcase
  stripe_id: string; // eslint-disable-line camelcase
  stripe_customer_id: string; // eslint-disable-line camelcase
  language: string;
  postal_code: string; // eslint-disable-line camelcase
  shipping_addresses: Array<ShippingAddress>; // eslint-disable-line camelcase
  username: string;
  description: string;
  icon: Icon | null;
  is_patron: boolean; // eslint-disable-line camelcase
  is_artist: boolean; // eslint-disable-line camelcase
  terms_of_service_approval_datetime: string | null; // eslint-disable-line camelcase
  artist_approval_request: ArtistApprovalRequest | null; // eslint-disable-line camelcase
  likes: Array<string>;
  likes_count: number; // eslint-disable-line camelcase
  like_id: string | null; // eslint-disable-line camelcase
};

// 自分以外のユーザ型
type OtherUser = {
  cognito_id: string; // eslint-disable-line camelcase
  language: string;
  username: string; // eslint-disable-line camelcase
  description: string;
  icon: Icon | null;
  likes: Array<string>;
  likes_count: number; // eslint-disable-line camelcase
  like_id: string | null; // eslint-disable-line camelcase
};

type HazeruArtArtistPagination = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<OtherUser>;
};

function generateUserDefaultConfig(): User {
  return {
    cognito_id: '', // eslint-disable-line camelcase
    stripe_id: '', // eslint-disable-line camelcase
    stripe_customer_id: '', // eslint-disable-line camelcase
    language: '',
    postal_code: '',
    shipping_addresses: [],
    username: '', // eslint-disable-line camelcase
    description: '',
    icon: null,
    is_patron: false, // eslint-disable-line camelcase
    is_artist: false, // eslint-disable-line camelcase
    terms_of_service_approval_datetime: null, // eslint-disable-line camelcase
    artist_approval_request: null, // eslint-disable-line camelcase
    likes: [],
    likes_count: 0,
    like_id: null,
  };
}

type TimelinePoint = {
  date: string;
  count: number;
};

type HazeruArtPayoutEvent = {
  id: string;
  user_id: string; // eslint-disable-line camelcase
  payout: number;
  created_datetime: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtTag = {
  name: string;
};

type HazeruArtDigitalArtThumbnail = {
  id: string;
  resolution_type: string; // eslint-disable-line camelcase
  filename: string;
  sha1digest: string;
  contenttype: string;
  presigned_get_url: string; // eslint-disable-line camelcase
  updated_datetime: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArt = {
  id: string;
  artist_id: string; // eslint-disable-line camelcase
  title: string;
  tags: Array<HazeruArtDigitalArtTag>;
  likes: Array<string>;
  likes_count: number; // eslint-disable-line camelcase
  like_id: string | null; // eslint-disable-line camelcase
  views?: number;
  filename: string;
  sha1digest: string;
  contenttype: string;
  description: string;
  presigned_get_url: string; // eslint-disable-line camelcase
  digital_art_thumbnails: Array<HazeruArtDigitalArtThumbnail>; // eslint-disable-line camelcase
};

function generateDigitalArtDefaultConfig(): HazeruArtDigitalArt {
  return {
    id: '',
    artist_id: 'artist', // eslint-disable-line camelcase
    title: 'title',
    tags: [],
    filename: 'img.jpg',
    sha1digest: '',
    contenttype: 'image/jpg',
    description: 'description',
    presigned_get_url: '', // eslint-disable-line camelcase
    digital_art_thumbnails: [],
    like_id: '',
    likes: [],
    likes_count: 0,
    views: 0,
  };
}
const HazeruArtDigitalArtCategoryType = {
  PORTRAIT: '人物画',
  ANIMAL_PAINTING: '動物画',
  MARINE_LIFE_PAINTING: '海洋生物画',
  STILL_LIFE_PAINTING: '静物画',
  LANDSCAPE_PAINTING: '風景画',
  ABSTRACT_PAINTING: '抽象画',
  INSECT_PAINTING: '昆虫画',
  TEXT_PAINTING: '文字画',
  CHARACTER_PAINTING: 'キャラクター',
  POP_ART: 'ポップアート',
  OTHER: 'その他',
} as const;

type HazeruArtDigitalArtCategoryType = typeof HazeruArtDigitalArtCategoryType[keyof typeof HazeruArtDigitalArtCategoryType];

const getCategoryTypeItems = (): Array<HazeruArtDigitalArtCategoryType> =>
  Object.values(HazeruArtDigitalArtCategoryType);

type Pagination<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
};

type HazeruArtDigitalArtPagination = Pagination<HazeruArtDigitalArt>;

type HazeruArtDigitalArtCreate = {
  title: string;
  tags: Array<HazeruArtDigitalArtTag>;
  filename: string;
  sha1digest: string;
  contenttype: string;
  description: string;
};

type HazeruArtDigitalArtPresignedPutUrlResponse = {
  presigned_put_url: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtProductCategoryInfo = {
  size: string;
  color: string;
  display_options: string; // eslint-disable-line camelcase
  // eslint-disable-next-line camelcase
  printable_area: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  physics: {
    width_milli: number; // eslint-disable-line camelcase
    height_milli: number; // eslint-disable-line camelcase
  };
};

type HazeruArtDigitalArtProductCatalogItemCategory =
  | 't_shirt'
  | 'acrylic'
  | 'tapestry'
  | 'long_t_shirt'
  | 'parker'
  | 'other';

type HazeruArtDigitalArtProductCatalogItem = {
  id: string;
  name: string;
  description: string;
  category: HazeruArtDigitalArtProductCatalogItemCategory;
  category_info: HazeruArtDigitalArtProductCategoryInfo; // eslint-disable-line camelcase
  presigned_get_url: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtProduct = {
  id: string;
  digital_art: HazeruArtDigitalArt | null; // eslint-disable-line camelcase
  name: string;
  price: number;
  description: string;
  category: HazeruArtDigitalArtProductCatalogItemCategory;
  category_info: HazeruArtDigitalArtProductCategoryInfo; // eslint-disable-line camelcase
  stripe_product_id: string; // eslint-disable-line camelcase
  presigned_get_url: string; // eslint-disable-line camelcase
  presigned_get_watermark_ichimatsu_url: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtCustomProductGroup = {
  id: string;
  digital_art_id: string; // eslint-disable-line camelcase
  group_id: string; // eslint-disable-line camelcase
  product_name: string; // eslint-disable-line camelcase
  art_left: number; // eslint-disable-line camelcase
  art_top: number; // eslint-disable-line camelcase
  art_right: number; // eslint-disable-line camelcase
  art_bottom: number; // eslint-disable-line camelcase
  price: number;
  category: string;
  category_info: HazeruArtDigitalArtProductCategoryInfo; // eslint-disable-line camelcase
  description: string;
  presigned_get_url: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtProductDetail = {
  id: string;
  count: number;
  digital_art: HazeruArtDigitalArt; // eslint-disable-line camelcase
  name: string;
  price: number;
  description: string;
  category: HazeruArtDigitalArtProductCatalogItemCategory;
  category_info: HazeruArtDigitalArtProductCategoryInfo; // eslint-disable-line camelcase
  stripe_product_id: string; // eslint-disable-line camelcase
  presigned_get_url: string; // eslint-disable-line camelcase
  presigned_get_watermark_ichimatsu_url: string; // eslint-disable-line camelcase
};

type HazeruArtDigitalArtProductCartItem = {
  digital_art_product: HazeruArtDigitalArtProduct; // eslint-disable-line camelcase
  amount: number;
};

type HazeruArtArtistApprovalRequestPostBody = {
  comment: string;
};

type HazeruArtArtistApprovalRequestDigitalArtPostBody = {
  filename: string;
  sha1digest: string;
  contenttype: string;
};

type HazeruArtArtistApprovalRequestDigitalArtPresignedPutUrlResponse = {
  presigned_put_url: string; // eslint-disable-line camelcase
};

type HazeruArtContractEvent = {
  id: string;
  patron: User;
  product: HazeruArtDigitalArtProduct;
  event_type: string; // eslint-disable-line camelcase
  created_datetime: string; // eslint-disable-line camelcase
};

type HazeruArtChargeEvent = {
  id: string;
  contract_event: HazeruArtContractEvent; // eslint-disable-line camelcase
  stripe_charge_id: string; // eslint-disable-line camelcase
  amount: number;
  currency: string;
  created_datetime: string; // eslint-disable-line camelcase
};

type HazeruArtSoldDigitalArtProduct = {
  id: string;
  digital_art: HazeruArtDigitalArt; // eslint-disable-line camelcase
  profit: number;
  currency: string;
  created_datetime: string; // eslint-disable-line camelcase
};

type HazeruArtArtistBalance = {
  artist_id: string; // eslint-disable-line camelcase
  amount: number;
  currency: string;
};

type HazeruArtNotification = {
  id: string;
  message: string;
  icon: string;
  link: string | null;
  read: boolean;
  sent_at: string; // eslint-disable-line camelcase
};

type StripePaymentIntentShippingAddress = {
  postal_code: string; // eslint-disable-line camelcase
  state: string;
  city: string;
  line1: string;
  line2: string;
};

type StripePaymentIntentShipping = {
  address: StripePaymentIntentShippingAddress;
  name: string;
  phone: string;
};

type StripePaymentIntentSucceeded = {
  amount: number;
  created_datetime: string; // eslint-disable-line camelcase
  currency: string;
  products: Array<HazeruArtDigitalArtProductDetail>;
  shipping: StripePaymentIntentShipping;
};

type StripePaymentIntentSucceededPagination = Pagination<StripePaymentIntentSucceeded>;

function convertToStripePaymentIntentShipping(
  info: ShippingAddressPostBody,
): StripePaymentIntentShipping {
  return {
    address: {
      state: info.state,
      city: info.city,
      line1: info.line1,
      line2: info.line2 || '',
      postal_code: info.postal_code,
    },
    name: `${info.last_name}${info.first_name}`,
    phone: info.phone,
  };
}

type StripePaymentIntent = {
  id: string;
  customer: string;
  amount: number;
  currency: string;
  client_secret: string; // eslint-disable-line camelcase
  shipping: StripePaymentIntentShipping | null;
  metadata: { [key: string]: number }; // keyはDigitalArtProductId、valueは個数となっている
};

type StripeBillingDetails = {
  address: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null; // eslint-disable-line camelcase
    state: string | null;
  };
  email: string | null;
  name: string | null;
  phone: string | null;
};

type StripeCard = {
  brand: string;
  checks: {
    address_line1_check: boolean | null; // eslint-disable-line camelcase
    address_postal_code_check: boolean | null; // eslint-disable-line camelcase
    cvc_check: boolean | null; // eslint-disable-line camelcase
  };
  country: string;
  exp_month: number; // eslint-disable-line camelcase
  exp_year: number; // eslint-disable-line camelcase
  fingerprint: string;
  funding: string;
  generated_from: string | null; // eslint-disable-line camelcase
  last4: string;
  networks: {
    available: Array<string>;
    preferred: string | null;
  };
  // eslint-disable-next-line camelcase
  three_d_secure_usage: {
    supported: boolean;
  };
  wallet: string | null;
};

type StripePaymentMethod = {
  id: string;
  object: string;
  billing_details: StripeBillingDetails; // eslint-disable-line camelcase
  card: StripeCard;
  created: number;
  customer: string | null;
  livemode: boolean;
  type: string;
  metadata: { [key: string]: string };
};

type StripePaymentMethodListApi = {
  object: string;
  url: string;
  has_more: boolean; // eslint-disable-line camelcase
  data: Array<StripePaymentMethod>;
};

type DigitalArtLikePostBody = {
  art: string;
};

type ArtistLikePostBody = {
  artist: string;
};

type DigitalArtCollectionPostBody = {
  name: string;
};

type DigitalArtCollectionItemPostBody = {
  art: string;
  collection: string;
};

type DigitalArtCollectionItem = {
  art: HazeruArtDigitalArt;
  id: string;
  order: string;
};

type DigitalArtCollection = {
  items: ReadonlyArray<DigitalArtCollectionItem>;
  id: string;
  name: string;
  created_datetime: string; // eslint-disable-line camelcase
  updated_datetime: string; // eslint-disable-line camelcase
  created_by: OtherUser; // eslint-disable-line camelcase
  updated_by: string; // eslint-disable-line camelcase
};

type DigitalArtCollectionPagination = Pagination<DigitalArtCollection>;

type DigitalArtCollectionDetail = {
  collection: DigitalArtCollection;
  created_user: User; // eslint-disable-line camelcase
};

type DisplayFrame = {
  id: string;
  name: string;
  displayed_art_presigned_get_url: string | null; // eslint-disable-line camelcase
  collection: DigitalArtCollection | null;
  created_datetime: string; // eslint-disable-line camelcase
  updated_datetime: string; // eslint-disable-line camelcase
};

type DisplayFramePagination = Pagination<DisplayFrame>;

type DisplayFrameChangeAction = {
  frameId: string;
  type: 'single' | 'collection' | 'next';
  collection: string | null;
  digitalart: string | null;
};

type SurveyAnswer = {
  id: string;
  answer: string;
  order: number;
  display: boolean;
  survey: string;
};

type SurveyResult = {
  answer_id: string; // eslint-disable-line camelcase
  survey_id: string; // eslint-disable-line camelcase
};

type Survey = {
  answers: Array<SurveyAnswer>;
  created_datetime: string; // eslint-disable-line camelcase
  id: string;
  question: string;
};

export {
  ArtistBankInformation,
  ArtistLikePostBody,
  Icon,
  IconPostBody,
  User,
  OtherUser,
  TimelinePoint,
  ShippingAddress,
  ShippingAddressPostBody,
  generateArtistBankInformationDefaults,
  generateUserDefaultConfig,
  HazeruArtPayoutEvent,
  HazeruArtDigitalArtTag,
  HazeruArtDigitalArt,
  HazeruArtDigitalArtThumbnail,
  generateDigitalArtDefaultConfig,
  HazeruArtDigitalArtCategoryType,
  getCategoryTypeItems,
  HazeruArtDigitalArtPagination,
  HazeruArtDigitalArtCreate,
  HazeruArtDigitalArtPresignedPutUrlResponse,
  HazeruArtDigitalArtProduct,
  HazeruArtDigitalArtProductDetail,
  HazeruArtDigitalArtCustomProductGroup,
  HazeruArtDigitalArtProductCartItem,
  HazeruArtDigitalArtProductCategoryInfo,
  HazeruArtDigitalArtProductCatalogItemCategory,
  HazeruArtDigitalArtProductCatalogItem,
  HazeruArtArtistApprovalRequestPostBody,
  HazeruArtArtistApprovalRequestDigitalArtPostBody,
  HazeruArtArtistApprovalRequestDigitalArtPresignedPutUrlResponse,
  HazeruArtArtistPagination,
  HazeruArtContractEvent,
  HazeruArtChargeEvent,
  HazeruArtSoldDigitalArtProduct,
  HazeruArtArtistBalance,
  HazeruArtNotification,
  StripePaymentIntent,
  StripePaymentIntentShipping,
  convertToStripePaymentIntentShipping,
  StripeBillingDetails,
  StripeCard,
  StripePaymentMethod,
  StripePaymentMethodListApi,
  StripePaymentIntentSucceeded,
  StripePaymentIntentSucceededPagination,
  DigitalArtLikePostBody,
  Pagination,
  DigitalArtCollectionPostBody,
  DigitalArtCollectionItemPostBody,
  DigitalArtCollectionItem,
  DigitalArtCollection,
  DigitalArtCollectionDetail,
  DigitalArtCollectionPagination,
  DisplayFrame,
  DisplayFramePagination,
  DisplayFrameChangeAction,
  ArtistApprovalRequest,
  Survey,
  SurveyResult,
  SurveyAnswer,
};
