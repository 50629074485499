































































import {
  Component,
  Vue,
  Emit,
  Prop,
} from 'vue-property-decorator';
import { ShippingAddressPostBody } from '../repository/artsub-backend-types';
import UserArtProductPaymentProfile from './UserArtProductPaymentProfile.vue';

@Component({
  components: {
    UserArtProductPaymentProfile,
  },
})
export default class UserArtProductPaymentProfileList extends Vue {
  @Prop({ default: false })
  readonly!: boolean;

  @Prop()
  name!: string;

  @Prop({ default: [] })
  shippingAddresses!: Array<ShippingAddressPostBody>;

  usingShippingAddressIndex = -1;

  newShippingAddress: ShippingAddressPostBody | null = null;

  mounted(): void {
    if (this.shippingAddresses.length > 0) {
      this.usingShippingAddressIndex = 0;
    }
  }

  exportNewShippingAddress(data: ShippingAddressPostBody): void {
    this.newShippingAddress = data;
  }

  // 第2要素には新規作成の場合はtrue,そうではない場合はfalseを入れる
  @Emit('export')
  export(): [ShippingAddressPostBody | null, boolean] {
    const shippingAddress = this.selectedShippingAddress;
    const isCreated = this.usingShippingAddressIndex === -1;

    return [shippingAddress, isCreated];
  }

  @Emit('delete')
  // eslint-disable-next-line class-methods-use-this
  deleteAddress(address: ShippingAddressPostBody): ShippingAddressPostBody {
    return address;
  }

  get selectedShippingAddress(): ShippingAddressPostBody | null {
    if (this.usingShippingAddressIndex === -1) {
      (this.$refs as any).newShippingInformation.export();

      return this.newShippingAddress;
    }

    return this.shippingAddresses[this.usingShippingAddressIndex];
  }
}
