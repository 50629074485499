const convertPresignedUrlToImProjectUrl = (presignedUrl: string): string => {
  // presigned urlは https://{s3のエンドポイント}/{Bucket}/{Key} となっている
  const url = presignedUrl; // eslint-disable-line no-underscore-dangle
  const parsedUrl = new URL(url);
  const path = parsedUrl.pathname.split("%23")[0].split("/").slice(2).join("/");

  return `${process.env.VUE_APP_IM_PROJECT_URL}/${path}`;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  convertPresignedUrlToImProjectUrl,
};
