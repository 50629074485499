




















































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DigitalArtProductCartItemAdapter from '@/adapter/digitalArtProductCartItemAdapter';
import {
  StripePaymentIntent,
} from '../../repository/artsub-backend-types';
import CartBody from '../../components/CartBody.vue';
import { calcProductTotalCost } from '../../lib/costCalculator';
import {
  getDigitalArtProduct,
  getPaymentIntent,
  putPaymentIntentProduct,
} from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['jwtToken']),
  components: {
    CartBody,
  },
})
export default class UserArtProductCart extends Vue {
  jwtToken!: string | null;

  productCartItems: Array<DigitalArtProductCartItemAdapter> = [];

  paymentIntent: StripePaymentIntent | null = null;

  async created(): Promise<void> {
    try {
      const res = await getPaymentIntent(this.jwtToken);
      this.paymentIntent = res.data;
      console.log(this.paymentIntent);
    } catch (e) {
      console.log(e);
    }

    if (this.paymentIntent !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for await (
        const [digitalArtProductId, amount] of Object.entries(this.paymentIntent.metadata)
      ) {
        const res = await getDigitalArtProduct(this.jwtToken, digitalArtProductId);
        this.productCartItems.push(new DigitalArtProductCartItemAdapter(
          { digital_art_product: res.data, amount }
        ));
      }
    }
  }

  async updatePaymentIntent(
    changedProductCartItems: Array<DigitalArtProductCartItemAdapter>,
  ): Promise<void> {
    const totalCost = calcProductTotalCost(changedProductCartItems);
    const metadata: { [key: string]: number } = {};

    changedProductCartItems.forEach((changedProductCartItem) => {
      metadata[changedProductCartItem.digital_art_product.id] = changedProductCartItem.amount;
    });

    if (this.paymentIntent !== null) {
      await putPaymentIntentProduct(this.jwtToken, this.paymentIntent.id, totalCost, metadata);
    }

    this.$router.go(0);
  }
}
