import { Icon } from "@/repository/artsub-backend-types";

export default class IconAdapter implements Icon {
  readonly contenttype: string;

  readonly filename: string;

  readonly id: string;

  // eslint-disable-next-line camelcase
  readonly presigned_get_url: string;

  readonly sha1digest: string;

  // eslint-disable-next-line camelcase
  readonly updated_datetime: string;

  // eslint-disable-next-line camelcase
  readonly user_id: string;

  constructor(params: Icon) {
    this.contenttype = params.contenttype;
    this.filename = params.filename;
    this.id = params.id;
    // eslint-disable-next-line no-underscore-dangle
    this.presigned_get_url = params.presigned_get_url;
    this.sha1digest = params.sha1digest;
    this.updated_datetime = params.updated_datetime;
    this.user_id = params.user_id;
  }
}
