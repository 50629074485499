









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HazeruMagazineButtonLarge extends Vue {
  hazeruMagazineUrl = process.env.VUE_APP_HAZERU_MAGAZINE_URL;
}
