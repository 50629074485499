





import { Component, Vue } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';

@Component
export default class Logout extends Vue {
  error = '';

  async created(): Promise<void> {
    try {
      console.log('logout');
      await Auth.signOut();
      // emit event not needed? (previously depreciated eventbus used here...)
      // AmplifyEventBus.$emit('authState', 'signedOut');
    } catch (e) {
      this.error = e;
    }
  }
}
