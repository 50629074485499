










































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  postDigitalArtLike,
  deleteDigitalArtLike,
  postDigitalArtCollection,
  postDigitalArtCollectionItem, postFrameChangeAction,
} from '@/repository/artsub-backend';
import {
  DigitalArtCollection,
  DigitalArtCollectionItemPostBody,
  HazeruArtDigitalArt,
  DigitalArtCollectionPostBody, DisplayFrame,
} from '@/repository/artsub-backend-types';
import { gaArtLike } from '@/lib/gtag';
import DisplayFrameSelectionCard from '@/components/DisplayFrameSelectionCard.vue';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';

@Component({
  computed: mapGetters(['jwtToken']),
  components: { DisplayFrameSelectingCard: DisplayFrameSelectionCard },
})
export default class ArtList extends Vue {
  @Prop({ default: () => [] })
  arts!: Array<DigitalArtAdapter>;

  @Prop({ default: null })
  prefix!: string;

  @Prop({ default: false })
  onSeeMore!: boolean;

  @Prop({ default: () => [] })
  digitalArtCollections!: Array<DigitalArtCollection>;

  @Prop({ default: () => [] })
  displayFrames!: Array<DisplayFrame>;

  jwtToken!: string | null;

  registeredFrames = [
    'dummy frame1',
    'dummy frame2',
    'dummy frame3',
    'dummy frame4',
  ]

  newCollectionName = '';

  frameDialog = false;

  addDialog = false;

  newAddDialog = false;

  collectionMenu = false;

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  toSeeMore(): void {
    this.$router.push(`/user/art/list/${this.prefix}`);
  }

  // eslint-disable-next-line class-methods-use-this
  makeDigitalArtKey(prefix: string, art: DigitalArtAdapter): string {
    return `${prefix}-${art.id}`;
  }

  async addDigitalArtLike(art: DigitalArtAdapter): Promise<void> {
    gaArtLike(art);

    try {
      const res = await postDigitalArtLike(this.jwtToken, { art: art.id });
      if (art) {
        this.$set(art, 'like_id', res.data.id);
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeDigitalArtLike(art: DigitalArtAdapter): Promise<void> {
    if (art.like_id) {
      try {
        await deleteDigitalArtLike(this.jwtToken, art.like_id);
        if (art) {
          this.$set(art, 'like_id', null);
        }
      } catch (e) {
        console.log(e);
        console.log(e.response.status_code);
      }
    }
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '90vw';
      case 'sm': return '90vw';
      case 'md': return '210px';
      case 'lg': return '210px';
      case 'xl': return '210px';
      default: return '210px';
    }
  }

  get isSubmittable(): boolean {
    return this.newCollectionName !== '';
  }

  async castToDisplayFrame(
    data: [DisplayFrame, DigitalArtAdapter, DigitalArtCollectionAdapter | null],
  ): Promise<void> {
    const [displayFrame, art, _] = data;
    await postFrameChangeAction(this.jwtToken, {
      frameId: displayFrame.id,
      type: 'single',
      collection: null,
      digitalart: art.id,
    });
    this.frameDialog = false;
  }

  async addToCollection(
    art: DigitalArtAdapter,
    collection: DigitalArtCollectionAdapter
  ): Promise<void> {
    const postBody: DigitalArtCollectionItemPostBody = {
      art: art.id,
      collection: collection.id,
    };
    const res = await postDigitalArtCollectionItem(this.jwtToken, postBody);
    console.log(res);
    this.addDialog = false;
    // this.collectionMenu = false;
  }

  async createNewCollectionAndAdd(art: DigitalArtAdapter): Promise<void> {
    const body: DigitalArtCollectionPostBody = {
      // eslint-disable-next-line no-restricted-globals
      name: this.newCollectionName,
    };
    console.log('new collection');
    const res = await postDigitalArtCollection(this.jwtToken, body);
    const newDigitalArtCollection = res.data;
    await this.addToCollection(art, newDigitalArtCollection);

    this.newCollectionName = '';
    this.newAddDialog = false;
  }
}

