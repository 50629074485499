





































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import axios from 'axios';

import { HazeruArtDigitalArt } from '@/repository/artsub-backend-types';
import { postDigitalArt, getDigitalArtPresignedPutUrl } from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class ArtistArt extends Vue {
  jwtToken!: string | null;

  title = '';

  tag = '';

  tags: Array<string> = [];

  image: File | null = null;

  imageUrl: string | null = null;

  description = '';

  imageSha1 = '';

  imageContentType = '';

  defaultImage: File = require('@/assets/icon/art-img.jpeg') // eslint-disable-line global-require

  isSubmitting = false;

  flagTermsOfService = false;

  overFilesizeLimitationMsg = false;

  termsOfServiceUrl = process.env.VUE_APP_TERMS_OF_SERVICE_URL;

  @Watch('image')
  async imageChanged(): Promise<void> {
    if (!this.image) {
      this.imageSha1 = '';
      this.imageContentType = '';

      return;
    }
    const imageBuffer = await this.image.arrayBuffer();
    const digest = await crypto.subtle.digest('sha-1', imageBuffer);
    this.imageSha1 = Buffer.from(new Uint8Array(digest)).toString('hex');
    this.imageContentType = this.image.type;
  }

  async submit(): Promise<void> {
    if (this.image !== null) {
      this.isSubmitting = true;
      const res = await postDigitalArt(this.jwtToken, {
        filename: this.image.name,
        contenttype: this.imageContentType,
        sha1digest: this.imageSha1,
        title: this.title,
        tags: this.tags.map((tag) => ({ name: tag })),
        description: this.description,
      });

      const product: HazeruArtDigitalArt = res.data;
      const resPresigned = await getDigitalArtPresignedPutUrl(this.jwtToken, product.id);
      const presignedPutUrl = resPresigned.data.presigned_put_url;

      const headers = { 'content-type': 'application/octet-stream' };
      await axios.put(presignedPutUrl, this.image, { headers });

      this.isSubmitting = false;
      await this.$router.push('/artist/art');
    }
  }

  remove(item: string): void {
    this.tags = this.tags.filter((v: string) => v !== item);
  }

  clear(): void {
    this.title = '';
    this.tags = [];
    this.image = null;
    this.description = '';
    this.flagTermsOfService = false;
  }

  get isSubmittable(): boolean {
    return this.title !== ''
      && this.description !== ''
      && this.image !== null
      && this.flagTermsOfService;
  }

  updateArtImage(event: any): void {
    console.log('event triggered');
    const uploadFilesizeLimitation = 52428800;
    if (event !== null) {
      if (event.target.files[0].size > uploadFilesizeLimitation) {
        this.overFilesizeLimitationMsg = true;
        return
      }
      this.overFilesizeLimitationMsg = false;
      [this.image] = event.target.files;
      this.imageUrl = URL.createObjectURL(this.image);
    }
    console.log(this.image);
  }
}
