





























import {
  Component, Prop,
  Vue,
} from 'vue-property-decorator';
import {
  OtherUser,
} from '@/repository/artsub-backend-types';
import { mapGetters } from 'vuex';
import { deleteArtistLike, postArtistLike } from '@/repository/artsub-backend';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class ArtistLikeBtn extends Vue {
  jwtToken!: string | null;

  @Prop({ default: () => null })
  artist!: OtherUser;

  async addArtistLike(artistId: string): Promise<void> {
    try {
      const res = await postArtistLike(this.jwtToken, { artist: artistId });
      if (this.artist) {
        console.log(res.data);
        this.artist.like_id = res.data.id;
        this.artist.likes_count += 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeArtistLike(likeId: string): Promise<void> {
    try {
      await deleteArtistLike(this.jwtToken, likeId);
      if (this.artist) {
        this.artist.like_id = null;
        this.artist.likes_count -= 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }
}
