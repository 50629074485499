import { ArtistApprovalRequest, ShippingAddress, User } from '@/repository/artsub-backend-types';
import IconAdapter from '@/adapter/iconAdapter';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class UserAdapter implements User {
  // eslint-disable-next-line camelcase
  readonly cognito_id: string;

  readonly description: string;

  readonly icon: IconAdapter | null;

  language: string;

  // eslint-disable-next-line camelcase
  readonly like_id: string | null;

  readonly likes: Array<string>;

  // eslint-disable-next-line camelcase
  readonly likes_count: number;

  username: string;

  // eslint-disable-next-line camelcase
  readonly artist_approval_request: ArtistApprovalRequest | null;

  // eslint-disable-next-line camelcase
  readonly is_artist: boolean;

  // eslint-disable-next-line camelcase
  readonly is_patron: boolean;

  // eslint-disable-next-line camelcase
  readonly postal_code: string;

  // eslint-disable-next-line camelcase
  readonly shipping_addresses: Array<ShippingAddress>;

  // eslint-disable-next-line camelcase
  stripe_customer_id: string;

  // eslint-disable-next-line camelcase
  readonly stripe_id: string;

  // eslint-disable-next-line camelcase
  readonly terms_of_service_approval_datetime: string | null;

  constructor(params: User) {
    this.cognito_id = params.cognito_id;
    this.description = params.description;
    // eslint-disable-next-line no-underscore-dangle
    this.icon = params.icon !== null ? new IconAdapter(params.icon) : null;
    this.language = params.language;
    this.like_id = params.like_id;
    this.likes = params.likes;
    this.likes_count = params.likes_count;
    this.username = params.username;
    this.artist_approval_request = params.artist_approval_request;
    this.is_artist = params.is_artist;
    this.is_patron = params.is_patron;
    this.postal_code = params.postal_code;
    this.shipping_addresses = params.shipping_addresses;
    this.stripe_customer_id = params.stripe_customer_id;
    this.stripe_id = params.stripe_id;
    this.terms_of_service_approval_datetime = params.terms_of_service_approval_datetime;
  }

  // eslint-disable-next-line camelcase
  get icon_presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    if (this.icon === null) {
      // eslint-disable-next-line global-require
      return require('@/assets/defaults/default_icon.png');
    }

    // eslint-disable-next-line no-underscore-dangle
    return this.icon.presigned_get_url;
  }

  setUsername = (username: string): void => {
    this.username = username;
  };

  setLanguage = (language: string): void => {
    this.language = language;
  };

  setStripeCustomerId = (stripeCustomerId: string): void => {
    this.stripe_customer_id = stripeCustomerId;
  };
}
