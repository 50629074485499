































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getCartHistory,
} from '@/repository/artsub-backend';
import {
  HazeruArtDigitalArtProductCategoryInfo,
  StripePaymentIntentSucceeded,
  StripePaymentIntentSucceededPagination,
} from '@/repository/artsub-backend-types';
import DigitalArtProductDetailAdapter from '@/adapter/digitalArtProductDetailAdapter';
import StripePaymentIntentSucceededAdapter from '@/adapter/stripePaymentIntentSucceededAdapter';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class UserPurchaseHistory extends Vue {
  jwtToken!: string | null;

  displayOptionsJP: { [key: string]: string } = {
    none: '穴あけ加工なし',
    stand: 'スタンド付き',
    wallmount: '壁掛け',
  };

  purchaseHistories: Array<StripePaymentIntentSucceededAdapter> = [];

  async created(): Promise<void> {
    const res: StripePaymentIntentSucceededPagination = (await getCartHistory(this.jwtToken)).data;
    this.purchaseHistories = res.results.map(
      (history: StripePaymentIntentSucceeded) => new StripePaymentIntentSucceededAdapter(history)
    );
    console.log(res);
  }

  // eslint-disable-next-line class-methods-use-this
  toReadableDatetime(dt: string): string {
    return dt.slice(0, 10);
  }

  toReadableCategoryInfo(categoryInfo: HazeruArtDigitalArtProductCategoryInfo): string {
    const textInfo: string[] = [];
    if (typeof categoryInfo.color !== 'undefined') {
      textInfo.push(`カラー : ${categoryInfo.color}`);
    }
    if (typeof categoryInfo.size !== 'undefined') {
      textInfo.push(`サイズ : ${categoryInfo.size}`);
    }
    if (typeof categoryInfo.display_options !== 'undefined') {
      textInfo.push(`形 : ${this.displayOptionsJP[categoryInfo.display_options]}`);
    }
    return textInfo.join(' / ');
  }

  // eslint-disable-next-line class-methods-use-this
  purchasePage(productDetail: DigitalArtProductDetailAdapter): string {
    return `/user/art/${productDetail.digital_art.id}/product/${productDetail.category}`;
  }
}
