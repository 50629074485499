



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { HazeruArtDigitalArtProduct } from '@/repository/artsub-backend-types';
import DigitalArtProductAdapter from '@/adapter/digitalArtProductAdapter';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class ProductsList extends Vue {
  jwtToken!: string | null;

  categories: Array<{
    name: string;
    category: string;
    id: number;
    products: Array<DigitalArtProductAdapter>;
  }> = [];

  sizeOrder: { [name: string]: number } = {
    S: 1,
    M: 2,
    L: 3,
    XL: 4,
  };

  @Prop({ default: () => null })
  artId!: string;

  @Prop({ default: () => [] })
  ignoreCategory!: Array<string>;

  @Prop({ default: [] })
  products!: Array<DigitalArtProductAdapter>;

  @Watch('products')
  changeProducts(): void {
    if (this.products.length === 0) return;

    const registeredCategory = ['t_shirt', 'long_t_shirt', 'parker', 'acrylic', 'tapestry'];

    // カテゴリーごとにフィルタリングしてcategoriesに格納
    this.pushCategory('半袖Tシャツ', 't_shirt', 1);
    this.pushCategory('長袖Tシャツ', 'long_t_shirt', 2);
    this.pushCategory('パーカー', 'parker', 3);
    this.pushCategory('アクリル板', 'acrylic', 4);
    this.pushCategory('キャンバス生地ポスター', 'tapestry', 5);

    // その他：こちらは現状想定していない挙動
    if (this.products.filter((p) => !registeredCategory.includes(p.category)).length !== 0) {
      this.categories.push({
        name: 'その他',
        category: 'other',
        id: 6,
        products: this.products.filter((p) => !registeredCategory.includes(p.category)),
      });
    }

    // リストに表示しないカテゴリーを排除
    this.categories = this.categories.filter(
      (category) => !this.ignoreCategory.includes(category.category),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getColors(products: Array<DigitalArtProductAdapter>): Array<string> {
    const colors = Array.from(
      new Set<string>(products.map((product) => `/color/${product.category_info.color}.png`)),
    );

    const colorOrdersTable: { [key: string]: number } = {
      '/color/white.png': 1,
      '/color/gray.png': 2,
      '/color/black.png': 3,
    };
    colors.sort((a, b) => {
      if (colorOrdersTable[a] > colorOrdersTable[b]) {
        return 1;
      }
      if (colorOrdersTable[a] < colorOrdersTable[b]) {
        return -1;
      }

      return 0;
    });

    return colors;
  }

  // eslint-disable-next-line class-methods-use-this
  getSizeText(products: Array<HazeruArtDigitalArtProduct>): string {
    return Array.from(new Set<string>(products.map((product) => product.category_info.size)))
      .sort(this.sizeCompare)
      .join(' / ');
  }

  toArtProduct(category: string): void {
    console.log(`/user/art/${this.artId}/product/${category}`);
    this.$router.push(`/user/art/${this.artId}/product/${category}`);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sizeCompare(a: string, b: string) {
    const aOrderRank = this.sizeOrder[a] || 0;
    const bOrderRank = this.sizeOrder[b] || 0;
    if (aOrderRank < bOrderRank) {
      return -1;
    }
    if (aOrderRank > bOrderRank) {
      return 1;
    }
    return 0;
  }

  pushCategory(name: string, category: string, id: number): void {
    const categoricalProducts = this.products.filter((p) => p.category === category);
    if (categoricalProducts.length !== 0) {
      this.categories.push({
        name,
        category,
        id,
        products: categoricalProducts,
      });
    }
  }

  get productSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return '43vw';
      case 'sm':
        return '43vw';
      case 'md':
        return '17vw';
      case 'lg':
        return '17vw';
      case 'xl':
        return '17vw';
      default:
        return '17vw';
    }
  }
}
