import { DisplayFrame } from '@/repository/artsub-backend-types';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class DisplayFrameAdapter implements DisplayFrame {
  readonly id: string;

  readonly name: string;

  readonly collection: DigitalArtCollectionAdapter | null;

  // eslint-disable-next-line camelcase
  private readonly _displayed_art_presigned_get_url: string | null;

  // eslint-disable-next-line camelcase
  readonly created_datetime: string;

  // eslint-disable-next-line camelcase
  readonly updated_datetime: string;

  constructor(params: DisplayFrame) {
    this.id = params.id;
    this.name = params.name;
    this.collection =
      params.collection !== null ? new DigitalArtCollectionAdapter(params.collection) : null;
    // eslint-disable-next-line no-underscore-dangle
    this._displayed_art_presigned_get_url = params.displayed_art_presigned_get_url;
    this.created_datetime = params.created_datetime;
    this.updated_datetime = params.updated_datetime;
  }

  // eslint-disable-next-line camelcase
  get displayed_art_presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    if (this._displayed_art_presigned_get_url === null) {
      return '';
    }

    // eslint-disable-next-line no-underscore-dangle
    return convertPresignedUrlToImProjectUrl(this._displayed_art_presigned_get_url);
  }

  get readableCreatedDatetime(): string {
    return this.created_datetime.slice(0, 10);
  }

  get readableUpdatedDatetime(): string {
    return this.updated_datetime.slice(0, 10);
  }
}
