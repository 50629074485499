
























































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/layouts/common/Footer.vue';
import HazeruMagazineButtonLarge from '@/components/hazeru_magazine/HazeruMagazineButtonLarge.vue';
import HazeruMagazineLongBnr from '@/components/hazeru_magazine/HazeruMagazineLongBnr.vue';

@Component({
  components: {
    HazeruMagazineLongBnr,
    HazeruMagazineButtonLarge,
    Footer,
  },
})
export default class Home extends Vue {
  drawer = false;

  headerLinks = [
    {
      text: 'About',
      to: '#about',
    },
    {
      text: 'Features',
      to: '#feature',
    },
    {
      text: 'Contents',
      to: '#content',
    },
    {
      text: 'Members',
      to: '#member',
    },
    {
      text: 'Company',
      to: '#company',
    },
  ];

  features = [
    {
      group: 'ALL',
      contents: [
        {
          title: '基本機能は全て無料',
          text: '登録費や月額利用料は一切ありません。\n'
            + 'オンラインサロン、SNSのようにコミュニケーションや情報収集の為だけであれば無料でサービスを利用できます。\n'
            + '作品やグッズを購入した場合のみ、その費用が発生します。',
          img: require('@/assets/300px/hazeru_art_icn01-300.png'), // eslint-disable-line global-require
        },
      ],
    },
    {
      group: 'ARTIST',
      contents: [
        {
          title: '簡単にアート作品を登録',
          text: 'アート登録ページからjpeg/png形式で自分の作品を登録するだけです。\n'
            + '登録にあたり、作品の説明や想いなども入力していただくことで、消費者の胸に響く作品紹介ページができます。',
          img: require('@/assets/300px/hazeru_art_icn02-300.png'), // eslint-disable-line global-require
        },
        {
          title: '作品を広く流通',
          text: 'HAZEZRU ARTのプラットフォームによって作品が流通することで、守られた環境で多くの消費者に作品を届けることができ、'
            + 'それによって新たな収益をアーティストは得ることができます。\n\n'
            + 'また、作品の収益の70%がアーティストに支払われます。\n'
            + 'HAZEZRU ARTの収益よりもアーティストを応援したいという強い想いを表しています。',
          img: require('@/assets/300px/hazeru_art_icn03-300.png'), // eslint-disable-line global-require
        },
        {
          title: '作品の著作権を守る\nテクノロジー',
          text: 'デジタルアート作品は、手軽に共有できる高い利便性の一方で、コピーがされやすく、'
            + 'アーティストにとってはweb上の公開が難しい面もありました。\n'
            + 'そうした課題を解決し、アーティストを守るために、アーティストがHAZERU ARTに登録した作品がオリジナルであることをAIなどを使って保証します。'
            + '既に登録されている作品のコピー作品が登録された場合には、検知されます。\n\n'
            + '著作権なども問題を気にすることなく、アート作品製作に集中ができます。',
          img: require('@/assets/300px/hazeru_art_icn04-300.png'), // eslint-disable-line global-require
        },
        {
          title: 'グローバルな展開',
          text: 'HAZERU ARTは英語対応もしたグローバル展開をするサービスです。アーティストの作品は国境や文化を超え、多くの消費者へ届きます。',
          img: require('@/assets/300px/hazeru_art_icn05-300.png'), // eslint-disable-line global-require
        },
      ],
    },
    {
      group: 'USER',
      contents: [
        {
          title: '様々なジャンル、\n膨大な作品数',
          text: 'HAZERU ARTには、審査をクリアもしくは才能あるアーティストの紹介を受けて登録した若手からベテランの様々なアーティストがいます。'
            + 'そして様々なジャンルの作品が毎日のように登録されます。きっとお気に入りのアーティスト、作品に巡り会うことができます。',
          img: require('@/assets/300px/hazeru_art_icn06-300.png'), // eslint-disable-line global-require
        },
        {
          title: 'あなたに合った作品に\n出会える',
          text: 'HAZERU ARTには数百人のアーティスト、数千以上の作品が登録されています。この中からあなたの趣味趣向にあった作品に巡り会うのは至難の業です。'
            + 'そこでHAZERU ARTには高精度なAI（レコメンドエンジン）が実装されており、より手軽に自分の好みの作品やアーティストに巡り会えます。',
          img: require('@/assets/300px/hazeru_art_icn07-300.png'), // eslint-disable-line global-require
        },
        {
          title: 'アートをシェアリングするという発想',
          text: 'アート作品の購入には、数十万円、数百万円という値がつくことも珍しくありません。'
            + 'またデジタルアートについてもNFT（ブロックチェーン）の技術を使い所有権を購入することもありますが、こちらも同様に高価格となります。'
            + 'アーティストにとっては渾身の作品なので、妥当と言えます。'
            + 'しかしながら、この金額感はアートへの興味が強くない消費者には手の出せない金額でしょう。\n'
            + 'HAZERU ARTでは沢山のユーザーと1つの作品をシェアする考え方なので、低価格で多くの作品を鑑賞したり、グッズにすることが出来ます。',
          img: require('@/assets/300px/hazeru_art_icn08-300.png'), // eslint-disable-line global-require
        },
      ],
    },
  ];

  contents = [
    {
      title: 'お気に入りのアーティストを見つける',
      text: '各アーティストは自分の紹介ページを作成し持つことができます。\n'
        + 'お気に入りのアーティストを見つけてフォローしたり、作品を購入したり、アーティストのイベントに行きましょう！',
      img: require('@/assets/300px/hazeru_art_icn_con01-300.png'), // eslint-disable-line global-require
    },
    {
      title: 'お気に入りの作品を見つける',
      text: '膨大な作品の中からあなたに好みに合った作品に巡り会えるでしょう！',
      img: require('@/assets/300px/hazeru_art_icn_con02-300.png'), // eslint-disable-line global-require
    },
    {
      title: 'グッズで購入できる',
      text: '気に入ったアート作品は、Tシャツやアクリル板印刷などにして購入することが可能です。'
        + 'お気に入りの作品がプリントされたグッズを身に纏って、ワンランク豊な生活を！',
      img: require('@/assets/300px/hazeru_art_icn_con03-300.png'), // eslint-disable-line global-require
    },
    {
      title: 'アートを鑑賞して楽しむ',
      text: '気に入ったアート作品は、Tシャツやアクリル板に印刷などをして購入することが可能です。\n'
        + 'お気に入りの作品がプリントされたグッズを身に纏い、部屋に飾ることでワンランク豊な生活を！',
      img: require('@/assets/300px/hazeru_art_icn_con04-300.png'), // eslint-disable-line global-require
    },
    {
      title: 'リアルなイベントで出会う \nアートを好きになる',
      text: 'アーティストによっては、個展などのイベントを開催しています。気になるアーティストのページからイベントの案内を受け取って、'
        + 'イベントに出向き生のアート作品に触れることもいかがでしょうか？',
      img: require('@/assets/300px/hazeru_art_icn_con05-300.png'), // eslint-disable-line global-require
    },
  ]

  members = [
    {
      // eslint-disable-next-line global-require
      photo: require('@/assets/members/SHOGAMI_icon.jpg'),
      name: 'Takuro Shogami',
      text: '慶應義塾大学卒業後、株式会社オリエンタルランドにて東京ディスニーリゾートのグッズの開発を行い、'
        + 'その後コンサルティングファームや国内外のベンチャーやスタートアップにて事業開発や営業などの責任者を歴任。'
        + '2018年5月に株式会社KICONIA WORKSを創業し、AIを活用した開発支援を行なっている。'
        + '30代後半になり、芸術に興味を持ち始めたが、高額な絵画を購入することはできず悩み、'
        + '周囲の知り合いのアーティストを支援したいという想いからHAZERU ARTの構想を始めた。'
        + '美術の成績は2か3。決してセンスがあるわけではない。',
    },
    {
      // eslint-disable-next-line global-require
      photo: require('@/assets/members/Shane_icon.jpg'),
      name: 'Shane Cousins',
      text: '米国の大学を卒業後、インフラエンジニアとしてモトローラに入社。'
        + 'インフラやネットワーク周りの改善や設計を行うとともに、位置推定システムの開発も担当。'
        + 'その後、AIのスタートアップにてプロダクトオーナーとしてプラットフォームの開発に従事。'
        + '2018年末にKICONIA WORKSに参画。数々のシステム開発を担当し、特にインフラ周りの開発に強みを持つ。'
        + 'よく分からない街中の看板を写真に撮ってはInstagramにあげている。',
    },
    {
      // eslint-disable-next-line global-require
      photo: require('@/assets/members/GOYA_icon.jpg'),
      name: 'GOYA',
      text: '沖縄工業高等専門学校を卒業後、大手開発ベンダーに入社し、データサイエンティスト・エンジニアとして様々な業界の機械学習を用いたシステム開発を実施。'
        + 'その後2019年にKICONIA WORKSに参画し、オールマイティープレイヤーとして、AIの開発からUIの開発、'
        + 'インフラの開発までも行う。最近はe-Sports観戦にハマっており、火曜日に開催しているApexの大会を楽しみにしている。'
        + '元々アートに全く興味がなかったが、サービス開発をしていく中で、好きになってきている。',
    },
    {
      // eslint-disable-next-line global-require
      photo: require('@/assets/members/MEL-HEN.jpg'),
      name: ' SIGHTRIP',
      text: 'ペインターのRUSOWとAyanicocoによるアートユニット〼meL-Hen。'
        + '作品の中でまるで旅をしているような感覚を持ってもらいたいという想いから「視覚（SIGHT）×旅（TRIP）＝SIGHTRIP（サイトリップ）」として活動。'
        + '色彩豊かなアクリルペイントを軸に、ブラックライトを使った視覚効果を生み出す作品や、白と黒を巧みに使ったMONOCHROMEの世界観を表現した作品等を制作。'
        + 'キャンバス作品から、店舗などへの大型な壁画制作、企業へのアートワーク提供、音楽イベントでのライブペインティング出演や、ロゴ・キャラクターデザインなど、多岐に渡る活動をする。\n'
        + 'HAZERUARTも開発当初からアドバイザリーとして参画。',
    },
    {
      // eslint-disable-next-line global-require
      photo: require('@/assets/members/kimpo.png'),
      name: 'Kimpo',
      text: '神戸大学在学中より、神戸で最もイケてるデザインプロダクションに出入り。'
        + '卒業後そのまま入社し、CI／VI開発を数多く手がける。その後プロダクションを移り、主に大手教育系企業のVIやセールスプロモーション全般におけるデザイン制作などを担当。'
        + '2015年にクライアントの幅広いニーズをキャッチアップすべく、HIUP合同会社の立ち上げに参画。どこを切っても純度の高いトータルクリエイションに取り組む。'
        + 'クリエイティブディレクター兼アートディレクター兼デザイナー兼・・・',
    },
  ];

  company = [
    {
      key: '会社名',
      value: '株式会社KICONIA WORKS（KICONIA WORKS, Inc.）',
    },
    {
      key: '設立',
      value: '2018年5月',
    },
    {
      key: '事業内容',
      value: 'AIを中心とした最新テクノロジーを用いたビジネス構築支援及びサービス開発',
    },
    {
      key: '所在地',
      value: '東京都渋谷区渋谷3-10-1 渋谷MJビル3F',
    },
  ]

  scrolled = false;

  async created(): Promise<void> {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(): void {
    this.scrolled = window.scrollY > 50;
  }

  get isMobile(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return false;
      case 'sm': return false;
      case 'md': return true;
      case 'lg': return true;
      case 'xl': return true;
      default: return false;
    }
  }
}
