
































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  DisplayFrame,
} from '@/repository/artsub-backend-types';
import {
  getDisplayFrameList,
} from '@/repository/artsub-backend';
import UserAdapter from '@/adapter/userAdapter';
import DisplayFrameAdapter from '@/adapter/displayFrameAdapter';
import ArtLikeBtn from '../../components/ArtLikeBtn.vue';
import ProductsList from '../../components/ProductsList.vue';
import ShareButtons from '../../components/ShareButtons.vue';
import DisplayFrameSelectionCard from '../../components/DisplayFrameSelectionCard.vue';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    ArtLikeBtn,
    ProductsList,
    ShareButtons,
    DisplayFrameSelectionCard,
  },
})
export default class UserFrame extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  displayFrames: Array<DisplayFrameAdapter> = [];

  loading = true;

  async created(): Promise<void> {
    if (this.jwtToken !== null) {
      this.displayFrames = (await getDisplayFrameList(this.jwtToken))
        .data.results.map((frame: DisplayFrame) => new DisplayFrameAdapter(frame));
    }

    console.log(this.displayFrames);
    this.loading = false;
  }

  get totalDisplayFrames(): number {
    return this.displayFrames.length;
  }
}
