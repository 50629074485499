



























































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ArtList from '@/layouts/user/ArtList.vue';
import HazeruMagazineButtonLarge from '@/components/hazeru_magazine/HazeruMagazineButtonLarge.vue';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import DigitalArtTagAdapter from '@/adapter/digitalArtTagAdapter';
import UserAdapter from '@/adapter/userAdapter';
import DisplayFrameAdapter from '@/adapter/displayFrameAdapter';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';
import SurveyDialog from "@/components/SurveyDialog.vue";
import {
  getFeaturedDigitalArtList,
  getNewestDigitalArtList,
  getRecommendedDigitalArtList,
  postDigitalArtLike,
  deleteDigitalArtLike,
  getDigitalArtCollectionList,
  getDisplayFrameList,
  getSurveyList,
  getSurveyResult,
} from '../../repository/artsub-backend';
import {
  User,
  HazeruArtDigitalArt,
  HazeruArtDigitalArtCategoryType,
  getCategoryTypeItems,
  HazeruArtDigitalArtTag,
  DigitalArtCollection,
  DisplayFrame,
  Survey, SurveyResult,
} from '../../repository/artsub-backend-types';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    SurveyDialog,
    ArtList,
    HazeruMagazineButtonLarge,
  },
})
export default class UserHome extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  newestArts: Array<DigitalArtAdapter> = [];

  featuredArts: Array<DigitalArtAdapter> = [];

  recommendedArts: Array<DigitalArtAdapter> = [];

  digitalArtCollections: Array<DigitalArtCollectionAdapter> = [];

  selectedCategories: Array<HazeruArtDigitalArtCategoryType> = [];

  displayFrames: Array<DisplayFrameAdapter> = [];

  surveys: Array<Survey> = [];

  surveyResults: Array<SurveyResult> = [];

  scrolled = false;

  activeTab: 'category' | 'popular-tag' | null = null;

  popularTags: ReadonlyArray<DigitalArtTagAdapter> = [
    new DigitalArtTagAdapter({ name: '犬' }),
    new DigitalArtTagAdapter({ name: '猫' }),
    new DigitalArtTagAdapter({ name: '動物' }),
    new DigitalArtTagAdapter({ name: '海' }),
    new DigitalArtTagAdapter({ name: '自然' }),
    new DigitalArtTagAdapter({ name: 'アクリル画' }),
    new DigitalArtTagAdapter({ name: '幻想' }),
    new DigitalArtTagAdapter({ name: '植物' }),
    new DigitalArtTagAdapter({ name: 'ロゴ' }),
  ];

  showSurveyDialog = true;

  async created(): Promise<void> {
    if (this.jwtToken !== null) {
      await this.$store.dispatch('syncAuthenticatedUser');
    }

    const promises: Array<Promise<void>> = [
      this.setNewestArts(this.jwtToken),
      this.setFeaturedArts(this.jwtToken),
      this.setRecommended(this.jwtToken),
      this.setDigitalArtCollectionList(this.jwtToken),
      this.setSurveys(this.jwtToken),
      this.setSurveyResults(this.jwtToken),
    ];

    if (this.jwtToken !== null) {
      promises.push(this.setDisplayFrameList(this.jwtToken));
    }

    await Promise.all(promises);

    window.addEventListener('scroll', this.handleScroll);
  }

  async setNewestArts(jwt: string | null): Promise<void> {
    const resNewest = await getNewestDigitalArtList(jwt);
    this.newestArts = resNewest.data.map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art));
  }

  async setFeaturedArts(jwt: string | null): Promise<void> {
    const resFeatured = await getFeaturedDigitalArtList(jwt);
    this.featuredArts = resFeatured.data
      .map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art)
    );
  }

  async setRecommended(jwt: string | null): Promise<void> {
    const resRecommended = await getRecommendedDigitalArtList(jwt);
    this.recommendedArts = resRecommended.data
      .map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art));
  }

  async setDigitalArtCollectionList(jwt: string | null): Promise<void> {
    const res = await getDigitalArtCollectionList(jwt);
    const collections: Array<DigitalArtCollection> = res.data.results;
    this.digitalArtCollections = collections
      .filter((collection) => collection.created_by.cognito_id === this.user?.cognito_id)
      .map((collection) => new DigitalArtCollectionAdapter(collection))
      .slice(0, 5);
  }

  async setSurveys(jwt: string | null): Promise<void> {
    const surveys = await getSurveyList(jwt);
    this.surveys = surveys.data;
    console.log(this.surveys);
  }

  async setSurveyResults(jwt: string | null): Promise<void> {
    const resSurveyResult = await getSurveyResult(jwt);
    this.surveyResults = resSurveyResult.data;
  }

  async setDisplayFrameList(jwt: string | null): Promise<void> {
    const res = await getDisplayFrameList(jwt);
    this.displayFrames = res.data.results
      .map((frame: DisplayFrame) => new DisplayFrameAdapter(frame));
  }

  get isArtistApplying(): boolean {
    return this.user !== null
      && this.user.artist_approval_request !== null
      && !this.user.artist_approval_request.is_approved;
  }

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  // eslint-disable-next-line class-methods-use-this
  makeDigitalArtKey(prefix: string, art: HazeruArtDigitalArt): string {
    return `${prefix}-${art.id}`;
  }

  async addDigitalArtLike(art: HazeruArtDigitalArt): Promise<void> {
    try {
      const res = await postDigitalArtLike(this.jwtToken, { art: art.id });
      if (art) {
        this.$set(art, 'like_id', res.data.id);
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeDigitalArtLike(art: HazeruArtDigitalArt): Promise<void> {
    if (art.like_id) {
      try {
        await deleteDigitalArtLike(this.jwtToken, art.like_id);
        if (art) {
          this.$set(art, 'like_id', null);
        }
      } catch (e) {
        console.log(e);
        console.log(e.response.status_code);
      }
    }
  }

  async searchArt(): Promise<void> {
    await this.$router.push({ path: '/user/art', query: { categories: this.selectedCategories.join(',') } });
  }

  clickCategoryTab(): void {
    if (this.activeTab === 'category') {
      this.activeTab = null;
      return;
    }
    this.activeTab = 'category';
  }

  clickPopularTagTab(): void {
    if (this.activeTab === 'popular-tag') {
      this.activeTab = null;
      return;
    }
    this.activeTab = 'popular-tag';
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '90vw';
      case 'sm': return '90vw';
      case 'md': return '220px';
      case 'lg': return '220px';
      case 'xl': return '220px';
      default: return '220px';
    }
  }

  get sections(): Array<any> {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return [
          { title: '注目', href: '#featuredSection' },
          { title: '新着', href: '#newestSection' },
          { title: 'おすすめ', href: '#recommendedSection' },
        ];
      case 'sm':
        return [
          { title: '注目', href: '#featuredSection' },
          { title: '新着', href: '#newestSection' },
          { title: 'おすすめ', href: '#recommendedSection' },
        ];
      default:
        return [
          { title: 'いま注目のアート', href: '#featuredSection' },
          { title: '新着アート', href: '#newestSection' },
          { title: 'おすすめアート', href: '#recommendedSection' },
        ];
    }
  }

  handleScroll(): void {
    this.scrolled = window.scrollY > 50;
  }

  get isMobile(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return true;
      case 'sm': return true;
      case 'md': return false;
      case 'lg': return false;
      case 'xl': return false;
      default: return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get categories(): Array<HazeruArtDigitalArtCategoryType> {
    return getCategoryTypeItems();
  }

  toCollection(collection: DigitalArtCollectionAdapter): void {
    this.$router.push(`/user/collection/${collection.id}`);
  }
}
