import axios from 'axios';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// @ts-ignore
import { Hub } from 'aws-amplify';
import UserArtistProfile from '@/views/user/UserArtistProfile.vue';
import UserPurchaseHistory from '@/views/user/UserPurchaseHistory.vue';
import UserCollection from '@/views/user/UserCollection.vue';
import ArtistAnalytics from '@/views/artist/ArtistAnalytics.vue';
import layoutComponent from '../util/routes';
// @ts-ignore
import store from '../store';

import Top from '../views/Top.vue';
import UserAccount from '../views/user/UserAccount.vue';
import UserHome from '../views/user/UserHome.vue';
import UserArt from '../views/user/UserArt.vue';
import UserArtDetail from '../views/user/UserArtDetail.vue';
import UserArtProduct from '../views/user/UserArtProduct.vue';
import UserArtProductCart from '../views/user/UserArtProductCart.vue';
import UserArtProductPayment from '../views/user/UserArtProductPayment.vue';
import UserArtProductPaymentThankYou from '../views/user/UserArtProductPaymentThankYou.vue';
import UserLikePage from '../views/user/UserLikePage.vue';
import UserArtist from '../views/user/UserArtist.vue';
import UserArtistLikePage from '../views/user/UserArtistLikePage.vue';
import UserTermsOfService from '../views/user/UserTermsOfService.vue';
import UserArtistApprovalRequest from '../views/user/UserArtistApprovalRequest.vue';
import ArtistArt from '../views/artist/ArtistArt.vue';
import ArtistArtNew from '../views/artist/ArtistArtNew.vue';
import ArtistArtEdit from '../views/artist/ArtistArtEdit.vue';
import ArtistDashboard from '../views/artist/ArtistDashboard.vue';
import ArtistProfile from '../views/artist/ArtistProfile.vue';
import ArtistSetting from '../views/artist/ArtistSetting.vue';
import Authenticator from '../views/Authenticator.vue';
import Loading from '../views/public/Loading.vue';
import Logout from '../views/Logout.vue';
import Law from '../views/public/Law.vue';
import ArtistProduct from '../views/artist/ArtistProduct.vue';
import ArtistProductNew from '../views/artist/ArtistProductNew.vue';
import UserCollectionDetail from '../views/user/UserCollectionDetail.vue';
import UserFrame from '../views/user/UserFrame.vue';

Vue.use(VueRouter);

const defaultRedirectPath = '/user/home';
const authenticationPath = '/auth';
const termsOfServicePath = '/user/terms-of-service';

const redirectQuery = 'redirect_path';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Top',
    component: Top,
    meta: { requiresAuth: false },
  },
  layoutComponent('Public', [
    {
      path: '/law',
      name: 'law',
      component: Law,
      meta: { requiresAuth: false },
    },
  ]),
  layoutComponent('User', [
    {
      path: '/user/account',
      name: 'user-account',
      component: UserAccount,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/terms-of-service',
      name: 'user-term-of-service',
      component: UserTermsOfService,
      meta: { requiresAuth: true },
    },
    {
      path: '/user/artist-approval-request',
      name: 'user-artist-approval-request',
      component: UserArtistApprovalRequest,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/home',
      name: 'user-home',
      component: UserHome,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/art/:artId/product/:category',
      name: 'user-product-view',
      component: UserArtProduct,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/art',
      name: 'user-art',
      component: UserArt,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/art/list/:kind',
      name: 'user-art',
      component: UserArt,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/art/:id',
      name: 'user-art-detail',
      component: UserArtDetail,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/artist',
      name: 'user-artist',
      component: UserArtist,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/artist/:id',
      name: 'user-artist-profile',
      component: UserArtistProfile,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/cart',
      name: 'user-cart-view',
      component: UserArtProductCart,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/payment/thankyou',
      name: 'user-payment-thankyou-view',
      component: UserArtProductPaymentThankYou,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/payment',
      name: 'user-payment-view',
      component: UserArtProductPayment,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/like',
      name: 'user-like-page',
      component: UserLikePage,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: authenticationPath,
      name: 'authenticate',
      component: Authenticator,
    },
    {
      path: '/user/follow',
      name: 'user-follows-page',
      component: UserArtistLikePage,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/history',
      name: 'user-purchase-history-page',
      component: UserPurchaseHistory,
      meta: { requiresAuth: true, requireTermsOfService: true },
    },
    {
      path: '/user/collection',
      name: 'user-collection-page',
      component: UserCollection,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/collection/:id',
      name: 'user-collection-detail',
      component: UserCollectionDetail,
      meta: { requireTermsOfService: true },
    },
    {
      path: '/user/frame',
      name: 'user-frame-page',
      component: UserFrame,
      meta: { requireTermsOfService: true },
    },
  ]),
  layoutComponent('Artist', [
    {
      path: '/artist/analytics',
      name: 'ArtistAnalytics',
      component: ArtistAnalytics,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/dashboard',
      name: 'ArtistHome',
      component: ArtistDashboard,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/profile',
      name: 'ArtistProfile',
      component: ArtistProfile,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/art',
      name: 'ArtistArt',
      component: ArtistArt,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/art/new',
      name: 'ArtistArtNew',
      component: ArtistArtNew,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/art/:id/edit',
      name: 'artist-art-edit',
      component: ArtistArtEdit,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/product',
      name: 'artist-product',
      component: ArtistProduct,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/product/new',
      name: 'artist-product-new',
      component: ArtistProductNew,
      meta: { requiresAuth: true, requireArtist: true },
    },
    {
      path: '/artist/setting',
      name: 'ArtistSetting',
      component: ArtistSetting,
      meta: { requiresAuth: true, requireArtist: true },
    },
  ]),
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
  {
    path: '/loading',
    name: 'public-loading',
    component: Loading,
  },
  {
    path: '*',
    redirect: '/',
    name: 'not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      await router.push(authenticationPath);
    }

    return Promise.reject(error);
  },
);

// https://docs.amplify.aws/guides/authentication/listening-for-auth-events/q/platform/js
Hub.listen('auth', async (data: any) => {
  const redirectUrl = Object.prototype.hasOwnProperty.call(router.currentRoute.query, 'redirect')
    ? (router.currentRoute.query.redirect as string)
    : defaultRedirectPath;
  switch (data.payload.event) {
    case 'signIn':
      await store.dispatch('syncAuthenticatedUser');
      router.push(
        { path: redirectUrl },
        () => {
          /* nothing */
        },
        () => {
          /* nothing */
        },
      );
      break;
    case 'signOut':
      store.commit('setUser', null);
      router.push(
        { path: '/' },
        () => {
          /* nothing */
        },
        () => {
          /* nothing */
        },
      );
      break;
    default:
      break;
  }
});

router.beforeResolve(async (to, from, next) => {
  if (Object.prototype.hasOwnProperty.call(to.query, redirectQuery)) {
    const redirectPath = to.query[redirectQuery];

    if (typeof redirectPath === 'string') {
      return next({ path: redirectPath.replace('/index.html', '') });
    }
  }

  if (to.matched.some((record) => record.path === authenticationPath)) {
    const redirectUrl = Object.prototype.hasOwnProperty.call(to.query, 'redirect')
      ? (to.query.redirect as string)
      : defaultRedirectPath;
    let user = await store.getters.user;
    if (!user) {
      await store.dispatch('syncAuthenticatedUser');
      user = await store.getters.user;
    }

    if (user) {
      return next({
        path: redirectUrl,
      });
    }
  }

  const user = await store.getters.user;

  // 利用規約のためのルーティング
  if (to.matched.some((record) => record.meta.requireTermsOfService)) {
    if (user !== null && user.terms_of_service_approval_datetime === null) {
      return next({
        path: termsOfServicePath,
      });
    }
  }

  if (to.matched.some((record) => record.path === termsOfServicePath)) {
    if (user && user.terms_of_service_approval_datetime !== null) {
      return next({
        path: '/user/home',
      });
    }
  }

  // 認証不要ページのルーティング
  if (to.meta && !to.meta.requiresAuth) {
    return next();
  }

  // 認証必須ページのルーティング
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      return next({
        path: authenticationPath,
        query: {
          redirect: to.fullPath,
        },
      });
    }
    if (to.matched.some((record) => record.meta.requireArtist)) {
      // アーティスト画面にアーティスト権限がないユーザがアクセスした場合、Top画面に戻す
      if (!user.is_artist) {
        return next('/');
      }
    }
    return next();
  }
  return next();
});

export default router;
