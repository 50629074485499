




























































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import UserAdapter from '@/adapter/userAdapter';
import DigitalArtCustomProductGroupAdapter from '@/adapter/digitalArtCustomProductGroupAdapter';
import { HazeruArtDigitalArtCustomProductGroup, User } from '../../repository/artsub-backend-types';
import {
  deleteDigitalArtCustomProductGroup,
  getDigitalArtCustomProductGroupList,
} from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class ArtistProduct extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  header = [
    { text: '商品名', value: 'product_name' },
    { text: '画像', value: 'presigned_get_url' },
    { text: 'アクション', value: 'actions', sortable: false },
  ];

  customProducts: Array<DigitalArtCustomProductGroupAdapter> = [];

  deleteDialog = false;

  deleteTargetCustomProductGroup: DigitalArtCustomProductGroupAdapter | null = null;

  async created(): Promise<void> {
    this.customProducts = (
      await getDigitalArtCustomProductGroupList(this.jwtToken, null, this.user?.cognito_id)
    ).data;
  }

  async deleteCustomProductGroup(): Promise<void> {
    if (this.deleteTargetCustomProductGroup === null) return;

    await deleteDigitalArtCustomProductGroup(this.jwtToken, this.deleteTargetCustomProductGroup.id);
    await this.$router.go(0);
  }

  openDeleteDialog(customProductGroup: HazeruArtDigitalArtCustomProductGroup): void {
    console.log(customProductGroup);
    this.deleteDialog = true;
    this.deleteTargetCustomProductGroup = new DigitalArtCustomProductGroupAdapter(
      customProductGroup,
    );
  }
}
