import { HazeruArtDigitalArtProductCartItem } from "@/repository/artsub-backend-types";
import DigitalArtProductAdapter from "@/adapter/digitalArtProductAdapter";

export default class DigitalArtProductCartItemAdapter
  implements HazeruArtDigitalArtProductCartItem
{
  readonly amount: number;

  // eslint-disable-next-line camelcase
  readonly digital_art_product: DigitalArtProductAdapter;

  constructor(params: HazeruArtDigitalArtProductCartItem) {
    this.amount = params.amount;
    this.digital_art_product = new DigitalArtProductAdapter(params.digital_art_product);
  }
}
