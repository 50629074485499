





















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import UserAdapter from '@/adapter/userAdapter';
import { HazeruArtDigitalArt } from '@/repository/artsub-backend-types';
import { deleteDigitalArt, getDigitalArtList } from '../../repository/artsub-backend';

interface Options {
  groupBy: string[];
  groupDesc: string[];
  itemsPerPage: number;
  multiSort: boolean;
  mustSort: boolean;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
}

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class ArtistArt extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  header = [
    { text: '画像', value: 'originalThumbnailPresignedGetUrl', sortable: false },
    { text: 'タイトル / タグ', value: 'title', sortable: false },
    { text: 'アクション', value: 'actions', sortable: false },
  ];

  arts: Array<DigitalArtAdapter> = [];

  options: Options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
  };

  totalArts = 0;

  loading = false;

  deleteDialog = false;

  deleteTargetArt: DigitalArtAdapter | null = null;

  @Watch('options', { deep: true })
  async changeOptions(): Promise<void> {
    if (this.user === null) return;
    this.loading = true;
    const res = await getDigitalArtList(
      this.jwtToken,
      this.user.cognito_id,
      null,
      true,
      null,
      this.options.page,
      this.options.itemsPerPage,
    );
    this.loading = false;
    this.arts = res.data.results.map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art));
    this.totalArts = res.data.count;
  }

  editArt(art: DigitalArtAdapter): void {
    this.$router.push(`/artist/art/${art.id}/edit`);
  }

  openDeleteDialog(art: DigitalArtAdapter): void {
    this.deleteDialog = true;
    this.deleteTargetArt = art;
  }

  async deleteArt(): Promise<void> {
    this.deleteDialog = false;
    if (this.deleteTargetArt !== null) {
      await deleteDigitalArt(this.jwtToken, this.deleteTargetArt.id);
    }
    this.$router.go(0);
  }
}
