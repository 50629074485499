










































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  DigitalArtCollection,
  DigitalArtCollectionPagination,
  DigitalArtCollectionPostBody,
  DisplayFrame,
  User,
} from '@/repository/artsub-backend-types';
import {
  deleteDigitalArtCollection,
  getDigitalArtCollectionList,
  getDisplayFrameList,
  postDigitalArtCollection,
  postFrameChangeAction,
} from '@/repository/artsub-backend';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import UserAdapter from '@/adapter/userAdapter';
import ArtLikeBtn from '../../components/ArtLikeBtn.vue';
import ProductsList from '../../components/ProductsList.vue';
import ShareButtons from '../../components/ShareButtons.vue';
import DisplayFrameSelectionCard from '../../components/DisplayFrameSelectionCard.vue';

interface Options {
  groupBy: string[];
  groupDesc: string[];
  itemsPerPage: number;
  multiSort: boolean;
  mustSort: boolean;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
}

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    ArtLikeBtn,
    ProductsList,
    ShareButtons,
    DisplayFrameSelectionCard,
  },
})
export default class UserCollection extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  collections: Array<DigitalArtCollectionAdapter> = [];

  displayFrames: Array<DisplayFrame> = [];

  options: Options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
  };

  totalCollections = 10;

  loading = false;

  newCollectionName = '';

  dialog = false;

  targetTrashCollection: DigitalArtCollectionAdapter | null = null;

  deleteCollectionDialog = false;

  deleting = false;

  creating = false;

  frameDialogs: Array<boolean> = [];

  async created(): Promise<void> {
    if (this.jwtToken !== null) {
      this.displayFrames = (await getDisplayFrameList(this.jwtToken)).data.results;
      await this.setCollections();
    }
  }

  @Watch('options', { deep: true })
  async changeOptions(): Promise<void> {
    if (this.user === null) return;
    this.loading = true;
    await this.setCollections();
    this.loading = false;
  }

  async setCollections(): Promise<void> {
    this.collections = [];
    const res: DigitalArtCollectionPagination = (
      await getDigitalArtCollectionList(this.jwtToken, this.options.page, this.options.itemsPerPage)
    ).data;

    this.collections = res.results.map((collection) => new DigitalArtCollectionAdapter(collection));
    this.totalCollections = res.count;
    console.log(res.results);
    this.frameDialogs = [];

    for (let i = 0; i < this.totalCollections; i += 1) {
      this.frameDialogs.push(false);
    }
  }

  async castToDisplayFrame(
    data: [DisplayFrame, DigitalArtAdapter, DigitalArtCollectionAdapter | null],
  ): Promise<void> {
    const [displayFrame, art, collection] = data;
    if (collection !== null) {
      await postFrameChangeAction(this.jwtToken, {
        frameId: displayFrame.id,
        type: 'collection',
        collection: collection.id,
        digitalart: art.id,
      });
    }

    for (let i = 0; i < this.totalCollections; i += 1) {
      this.$set(this.frameDialogs, i, false);
    }
  }

  popUpTrashDialog(collection: DigitalArtCollectionAdapter): void {
    this.deleteCollectionDialog = true;
    this.targetTrashCollection = collection;
  }

  async deleteCollection(): Promise<void> {
    this.deleting = true;
    if (this.targetTrashCollection !== null) {
      await deleteDigitalArtCollection(this.jwtToken, this.targetTrashCollection.id);
    }
    await this.setCollections();
    this.targetTrashCollection = null;
    this.deleting = false;
    this.deleteCollectionDialog = false;
  }

  // eslint-disable-next-line class-methods-use-this
  toCollectionDetail(collection: DigitalArtCollectionAdapter): void {
    console.log(`/user/collection/${collection.id}`);
    this.$router.push(`/user/collection/${collection.id}`);
  }

  cancelDelete(): void {
    this.deleteCollectionDialog = false;
  }

  async createNewCollection(): Promise<void> {
    this.creating = true;
    const body: DigitalArtCollectionPostBody = {
      // eslint-disable-next-line no-restricted-globals
      name: this.newCollectionName,
    };
    await postDigitalArtCollection(this.jwtToken, body);
    await this.setCollections();
    this.newCollectionName = '';
    this.creating = false;
    this.dialog = false;
  }

  isSubmittable(): boolean {
    return this.newCollectionName !== '';
  }

  isCollectionCreatedBy(collection: DigitalArtCollectionAdapter): boolean {
    return collection.created_by.cognito_id === this.user?.cognito_id;
  }

  toArtistDetail(artistId: string): void {
    this.$router.push(`/user/artist/${artistId}`);
  }

  // eslint-disable-next-line class-methods-use-this
  toReadableDatetime(dt: string): string {
    return dt.slice(0, 10);
  }
}
