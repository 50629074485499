












































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ProductsList from '@/components/ProductsList.vue';
import { mapGetters } from 'vuex';
import { gaViewArtDetail } from '@/lib/gtag';
import {
  getUserAccount,
  getDigitalArt,
  getDigitalArtProductList,
  deleteDigitalArtLike,
  postArtistLike,
  deleteArtistLike,
} from '@/repository/artsub-backend';
import {
  HazeruArtDigitalArtProduct,
  OtherUser,
  User,
} from '@/repository/artsub-backend-types';
import ArtLikeBtn from '@/components/ArtLikeBtn.vue';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import DigitalArtProductAdapter from '@/adapter/digitalArtProductAdapter';
import OtherUserAdapter from '@/adapter/otherUserAdapter';
import ShareButtons from '../../components/ShareButtons.vue';

@Component({
  computed: mapGetters(['jwtToken']),
  components: {
    ArtLikeBtn,
    ProductsList,
    ShareButtons,
  },
})
export default class UserArtDetail extends Vue {
  jwtToken!: string | null;

  art: DigitalArtAdapter | null = null;

  artist: OtherUserAdapter | null = null;

  products: Array<DigitalArtProductAdapter> = [];

  digitalArtProductItemOrder: { [name: string]: number } = {
    '半袖Tシャツ/ホワイト/Sサイズ': 1,
    '半袖Tシャツ/ホワイト/Mサイズ': 2,
    '半袖Tシャツ/ホワイト/Lサイズ': 3,
    '半袖Tシャツ/ホワイト/XLサイズ': 4,
    '半袖Tシャツ/ブラック/Sサイズ': 5,
    '半袖Tシャツ/ブラック/Mサイズ': 6,
    '半袖Tシャツ/ブラック/Lサイズ': 7,
    '半袖Tシャツ/ブラック/XLサイズ': 8,
    '透明アクリル板/A4/穴あけ加工なし': 9,
    '透明アクリル板/A4/壁掛け': 10,
    '透明アクリル板/A4/スタンド付き': 11,
    '透明アクリル板/A3/穴あけ加工なし': 12,
    '透明アクリル板/A3/壁掛け': 13,
    '透明アクリル板/A3/スタンド付き': 14,
    'A2キャンバス生地ポスター': 15,  // eslint-disable-line
  };

  @Watch('art')
  // eslint-disable-next-line class-methods-use-this
  async chagneDigitalArt(): Promise<void> {
    if (this.art !== null) {
      await Promise.all([
        this.setDigitalArtProducts(this.jwtToken, this.art.id),
        this.setUserAccount(this.jwtToken, this.art.artist_id),
      ]);
    }
  }

  async created(): Promise<void> {
    try {
      const resDigitalArt = await getDigitalArt(this.jwtToken, this.$route.params.id);
      this.art = new DigitalArtAdapter(resDigitalArt.data);
      if (this.art !== null) {
        gaViewArtDetail(this.art);
      }
    } catch (e) {
      console.log(e);
      if (e.response.status === 404) {
        await this.$router.push('/user/home');
      }
    }
  }

  async setDigitalArtProducts(jwt: string | null, artId: string): Promise<void> {
    const resDigitalArtProduct = await getDigitalArtProductList(jwt, artId);
    this.products = resDigitalArtProduct
      .data
      .sort(this.digitalArtCompare)
      .map((digitalArtProduct: HazeruArtDigitalArtProduct) =>
        new DigitalArtProductAdapter(digitalArtProduct));
  }

  // eslint-disable-next-line class-methods-use-this
  async setUserAccount(jwt: string | null, artistId: string): Promise<void> {
    const resArtist = await getUserAccount(jwt, artistId);
    this.artist = new OtherUserAdapter(resArtist.data);
  }

  get artistUsername(): string {
    if (this.artist === null) {
      return '';
    }

    return this.artist.username;
  }

  async removeDigitalArtLike(likeId: string): Promise<void> {
    try {
      await deleteDigitalArtLike(this.jwtToken, likeId);
      if (this.art) {
        this.art.like_id = null;
        this.art.likes_count -= 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async addArtistLike(artistId: string): Promise<void> {
    try {
      const res = await postArtistLike(this.jwtToken, { artist: artistId });
      if (this.artist) {
        this.artist.like_id = res.data.id;
        this.artist.likes_count += 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeArtistLike(likeId: string): Promise<void> {
    try {
      await deleteArtistLike(this.jwtToken, likeId);
      if (this.artist) {
        this.artist.like_id = null;
        this.artist.likes_count -= 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  toArtistDetail(artistId: string): void {
    this.$router.push(`/user/artist/${artistId}`);
  }

  digitalArtCompare(a: HazeruArtDigitalArtProduct, b: HazeruArtDigitalArtProduct): number {
    const aOrderRank = this.digitalArtProductItemOrder[a.name] || 0;
    const bOrderRank = this.digitalArtProductItemOrder[b.name] || 0;

    if (aOrderRank < bOrderRank) {
      return -1;
    }
    if (aOrderRank > bOrderRank) {
      return 1;
    }

    return 0;
  }

  get isMobile(): boolean {
    return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
  }
}
