













































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import {
  HazeruArtDigitalArt,
  OtherUser,
  User,
} from '@/repository/artsub-backend-types';
import {
  putStripeCreditCard,
  putUserAddress,
  putUsername,
  putUserLanguage,
  putUserProfile,
  getDigitalArtLike,
  getDigitalArt, getLikeArtistList,
} from '@/repository/artsub-backend';
import { AxiosResponse } from 'axios';
import { mapGetters } from 'vuex';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import UserAdapter from '@/adapter/userAdapter';

@Component({
  computed: mapGetters(['user', 'jwtToken', 'isAuthenticatedUser', 'isArtistUser']),
  components: {
    StripeElementCard,
  },
})
export default class UserAccount extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  dialog = false;

  stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY

  editUsername = false;

  isAuthenticatedUser!: boolean;

  isArtistUser!: boolean;

  newUsername = '';

  usernameErrorMessage = '';

  editAddress = false;

  newPostalCode = '';

  newAddress1 = '';

  newAddress2 = '';

  newAddress3 = '';

  newAddress4 = '';

  newAddress = '';

  editLanguage = false;

  newLanguage = '';

  editCreditCard = false;

  notificationBar = false;

  availableLanguages = [
    { language: 'Japanese', abbr: 'ja' },
    { language: 'English', abbr: 'en' },
  ];

  isSendingData = false;

  likeArts: Array<DigitalArtAdapter> = [];

  likeArtists: Array<OtherUser> = [];

  async created(): Promise<void> {
    const resDigitalArtsLike = await getDigitalArtLike(this.jwtToken);
    const likeArtRequests = [];
    for (let idx = 0; idx < Math.min(resDigitalArtsLike.data.length, 5); idx += 1) {
      likeArtRequests.push(
        this.fetchLikeArtPromise(this.jwtToken, resDigitalArtsLike.data[idx].art),
      );
    }
    this.likeArts = (await Promise.all<HazeruArtDigitalArt>(likeArtRequests)).map((art) => new DigitalArtAdapter(art));

    const resArtistsLike = await getLikeArtistList(this.jwtToken);
    const displayArtistNum = Math.min(resArtistsLike.data.results.length, 5);
    this.likeArtists = resArtistsLike.data.results.slice(0, displayArtistNum);
    if (this.user !== null) {
      this.newUsername = this.user.username;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchLikeArtPromise(jwt: string | null, artId: string): Promise<HazeruArtDigitalArt> {
    const resDigitalArt = await getDigitalArt(jwt, artId);
    return resDigitalArt.data;
  }

  get isRegisteredCreditCard(): boolean {
    if (this.user === null) return false;
    return this.user.stripe_customer_id !== '';
  }

  generateToken(): void {
    (this.$refs as any).elementRef.submit();
  }

  async updateUserAccount(
    updateFunction: (jwt: string, userId: string, param: string) => Promise<AxiosResponse>,
    param: string,
  ): Promise<any> {
    if (this.jwtToken === null || this.user === null) {
      alert('エラーが発生しました。リロードし再度実行してください');
      return Promise.reject();
    }

    this.isSendingData = true;

    try {
      const res = await updateFunction(this.jwtToken, this.user.cognito_id, param);
      this.isSendingData = false;
      return Promise.resolve(res);
    } catch (e) {
      this.isSendingData = false;
      return Promise.reject(e);
    }
  }

  async updateUsername(): Promise<void> {
    if (this.user === null) return;
    try {
      await this.updateUserAccount(putUsername, this.newUsername);
    } catch (e) {
      if (Object.prototype.hasOwnProperty.call(e.response.data, 'username')) {
        [this.usernameErrorMessage] = e.response.data.username;
      }
      return;
    }

    this.editUsername = false;

    this.user.setUsername(this.newUsername);

    this.notificationBar = true;
    await this.$nextTick();
  }

  async updateUserAddress(): Promise<void> {
    if (this.jwtToken === null || this.user === null) {
      alert('エラーが発生しました。リロードし再度実行してください');
      return;
    }

    this.isSendingData = true;
    const newAddress = this.newAddress1 + this.newAddress2 + this.newAddress3 + this.newAddress4;

    try {
      await putUserProfile(
        this.jwtToken, this.user.cognito_id, this.user.username, this.newPostalCode, newAddress,
      );
      this.isSendingData = false;
    } catch (e) {
      this.isSendingData = false;
      this.editAddress = false;
      console.log(e.response);

      return;
    }

    this.editAddress = false;

    this.newPostalCode = '';
    this.newAddress1 = '';
    this.newAddress2 = '';
    this.newAddress3 = '';
    this.newAddress4 = '';

    this.notificationBar = true;
  }

  async updateUserLanguage(): Promise<void> {
    if (this.user === null) {
      this.editLanguage = false;
      return;
    }
    try {
      await this.updateUserAccount(putUserLanguage, this.newLanguage);
    } catch (e) {
      this.editLanguage = false;
      return;
    }

    this.user.setLanguage(this.newLanguage);

    this.editLanguage = false;
  }

  async registerStripeCreditCard(token: any): Promise<void> {
    if (this.user === null) {
      this.editCreditCard = false;
      return;
    }

    try {
      const res = await this.updateUserAccount(putStripeCreditCard, token.id);

      this.editCreditCard = false;
      this.user.stripe_customer_id = res.data.stripe_customer_id;
    } catch (e) {
      this.editCreditCard = false;
    }
  }

  searchAddressFromPostalCode(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
    const this_ = this;
    // eslint-disable-next-line no-undef,no-new
    new YubinBango.Core(this.newPostalCode, (addr: any) => {
      this_.newAddress1 = addr.region;
      this_.newAddress2 = addr.locality;
      this_.newAddress3 = addr.street;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ruleRequired(value: string): boolean | string {
    if (value === '') {
      return '必須項目です。';
    }

    return true;
  }

  toNextPathOrSignInPath(path: string): string {
    if (this.isAuthenticatedUser) {
      return path;
    }

    return `/auth?redirect=${path}`;
  }

  // eslint-disable-next-line class-methods-use-this
  ruleZipcode(zipcode: string): boolean | string {
    const re = /\d{7}/;
    return re.test(zipcode);
  }

  get newAddressSubmittable(): boolean {
    return this.newPostalCode !== ''
      && this.newAddress1 !== ''
      && this.newAddress2 !== ''
      && this.newAddress3 !== '';
  }

  get artistSigningUpAvailable(): boolean {
    if (!this.isAuthenticatedUser) {
      return false;
    }

    // artist_approval_requestがnullの場合は申請前のため申請可能状態となる
    return this.user !== null && this.user.artist_approval_request === null;
  }

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '45vw';
      case 'sm': return '45vw';
      case 'md': return '128px';
      case 'lg': return '128px';
      case 'xl': return '128px';
      default: return '128px';
    }
  }

  toSeeMoreLikeArt(): void {
    this.$router.push('/user/like');
  }

  toSeeMoreLikeArtist(): void {
    this.$router.push('/user/follow');
  }

  toArtistDetail(artistId: string): void {
    this.$router.push(`/user/artist/${artistId}`);
  }

  artistIcon = (artist: OtherUser): string => {
    if (artist === null || artist.icon === null) {
      // eslint-disable-next-line global-require
      return require('@/assets/defaults/default_icon.png');
    }

    return artist.icon.presigned_get_url;
  }
}
