import { OtherUser } from '@/repository/artsub-backend-types';
import IconAdapter from '@/adapter/iconAdapter';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class OtherUserAdapter implements OtherUser {
  // eslint-disable-next-line camelcase
  readonly cognito_id: string;

  readonly description: string;

  readonly icon: IconAdapter | null;

  readonly language: string;

  // eslint-disable-next-line camelcase
  like_id: string | null;

  likes: Array<string>;

  // eslint-disable-next-line camelcase
  likes_count: number;

  readonly username: string;

  constructor(params: OtherUser) {
    this.cognito_id = params.cognito_id;
    this.description = params.description;
    // eslint-disable-next-line no-underscore-dangle
    this.icon = params.icon !== null ? new IconAdapter(params.icon) : null;
    this.language = params.language;
    this.like_id = params.like_id;
    this.likes = params.likes;
    this.likes_count = params.likes_count;
    this.username = params.username;
  }

  // eslint-disable-next-line camelcase
  get icon_presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    if (this.icon === null) {
      // eslint-disable-next-line global-require
      return require('@/assets/defaults/default_icon.png');
    }

    // eslint-disable-next-line no-underscore-dangle
    return this.icon.presigned_get_url;
  }
}
