





















































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getArtistBalances,
  getPayoutEventList,
  getSoldDigitalArtProductList,
  postPayoutEvent,
} from '@/repository/artsub-backend';
import UserAdapter from '@/adapter/userAdapter';
import SoldDigitalArtProductAdapter from '@/adapter/soldDigitalArtProductAdapter';
import {
  HazeruArtArtistBalance,
  HazeruArtPayoutEvent,
  HazeruArtSoldDigitalArtProduct,
} from '../../repository/artsub-backend-types';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class ArtistDashboard extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  artistStripeAccountUrl = '';

  soldDigitalArtProducts: Array<SoldDigitalArtProductAdapter> = [];

  balances: Array<HazeruArtArtistBalance> = [];

  payouts: Array<HazeruArtPayoutEvent> = [];

  minimumPayoutAmount = 10000; // 最低出金金額

  isCreated = false;

  isSending = false;

  async created(): Promise<void> {
    if (this.user !== null) {
      this.soldDigitalArtProducts = (await getSoldDigitalArtProductList(this.jwtToken)).data.map(
        (sold: HazeruArtSoldDigitalArtProduct) => new SoldDigitalArtProductAdapter(sold),
      );
      this.balances = (await getArtistBalances(this.jwtToken, this.user.cognito_id)).data;
      this.payouts = (await getPayoutEventList(this.jwtToken)).data;
    }

    this.isCreated = true;
  }

  async payout(): Promise<void> {
    this.isSending = true;

    if (this.user !== null) {
      await postPayoutEvent(this.jwtToken);
      this.isSending = false;
      this.$router.go(0);
    }

    this.isSending = false;
  }

  get isPayoutable(): boolean {
    if (this.balances.length === 0) {
      return false;
    }

    // 現状は円のみ対応のためbalancesは1つのみ
    return this.balances[0].amount >= this.minimumPayoutAmount;
  }

  // eslint-disable-next-line class-methods-use-this
  toReadableDate(strDate: string): string {
    const timestamp: number = Date.parse(strDate);
    const date = new Date(timestamp);
    return `${date.getFullYear()}/${date.getMonth() +
      1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }
}
