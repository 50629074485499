import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";
import { getUserAccount } from "@/repository/artsub-backend";
import { gaSetUser } from "@/lib/gtag";
import UserAdapter from "@/adapter/userAdapter";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cognitoUser: null, // 認証情報を扱うために保存
    user: null,
  },
  mutations: {
    setCognitoUser(state, cognitoUser) {
      state.cognitoUser = cognitoUser;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    setUser({ state, commit }, { user }): void {
      commit("setUser", user);
    },
    async syncAuthenticatedUser({ state, commit, dispatch }): Promise<void> {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (cognitoUser && cognitoUser.getSignInUserSession()) {
          commit("setCognitoUser", cognitoUser);
        }

        const jwt = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();
        const userId = cognitoUser.attributes.sub; // Google認証の場合usernameとsubが同じではなかったためsubを使用
        const res = await getUserAccount(jwt, userId);
        dispatch("setUser", { user: res.data });
        if (state.user !== null) {
          gaSetUser(state.user as any);
        }
      } catch (e) {
        console.log(e);
        dispatch("setUser", { user: null });
      }
    },
  },
  getters: {
    cognitoUser: (state, getters) => state.cognitoUser,
    user: (state, getters): UserAdapter | null => {
      if (state.user !== null) {
        // @ts-ignore
        return new UserAdapter(state.user);
      }

      return null;
    },
    jwtToken: (state, getters): string | null => {
      if (state.cognitoUser !== null) {
        // @ts-ignore
        return state.cognitoUser.getSignInUserSession().getIdToken().getJwtToken();
      }
      return null;
    },
    isAuthenticatedUser: (state, getters): boolean => state.user !== null,
    // @ts-ignore
    isArtistUser: (state, getters): boolean => state.user !== null && state.user.is_artist,
  },
  modules: {},
});
