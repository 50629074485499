
































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import CartBody from '../../components/CartBody.vue';
import UserArtProductPaymentProfile from '../../components/UserArtProductPaymentProfile.vue';

@Component({
  computed: mapGetters(['cognitoUser']),
  components: {
    CartBody,
    UserArtProductPaymentProfile,
    StripeElementCard,
  },
})
export default class UserArtProductPaymentThankYou extends Vue {
  cognitoUser!: any;

  get email(): string {
    if (this.cognitoUser === null) {
      return '';
    }

    return this.cognitoUser.attributes.email;
  }
}
