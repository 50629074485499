import { HazeruArtDigitalArt, User } from "@/repository/artsub-backend-types";
import DigitalArtAdapter from "@/adapter/digitalArtAdapter";

function gaSetUser(user: User) {
  // @ts-ignore
  console.log(`show ${window.gtag} ${user.cognito_id}`);
  // @ts-ignore
  window.gtag("set", "user_id", user.cognito_id);
  if (user.is_patron) {
    // @ts-ignore
    window.gtag("set", "user_properties", {
      artist_or_patron: "patron",
    });
    return;
  }
  if (user.is_artist) {
    // @ts-ignore
    window.gtag("set", "user_properties", {
      artist_or_patron: "artist",
    });
    return;
  }
  // @ts-ignore
  window.gtag("set", "user_properties", {
    artist_or_patron: "patron",
  });
}

function gaViewArtDetail(art: DigitalArtAdapter) {
  // @ts-ignore
  window.gtag("event", "view_item", {
    currency: "JPY",
    value: 0,
    items: [
      {
        item_id: art.id,
        item_name: art.title,
        item_brand: art.artist_id,
      },
    ],
  });
}

function gaSearch(searchTerm: string) {
  // @ts-ignore
  window.gtag("event", "search", {
    search_term: searchTerm,
  });
}

function gaArtLike(art: HazeruArtDigitalArt) {
  // @ts-ignore
  window.gtag("event", "art_like", {
    currency: "JPY",
    value: 0,
    items: [
      {
        item_id: art.id,
        item_name: art.title,
        item_brand: art.artist_id,
      },
    ],
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  gaSetUser,
  // eslint-disable-next-line import/prefer-default-export
  gaViewArtDetail,
  // eslint-disable-next-line import/prefer-default-export
  gaSearch,
  // eslint-disable-next-line import/prefer-default-export
  gaArtLike,
};
