






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  user: any | null = null;

  async created(): Promise<void> {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUser') {
        this.user = state.user;
      }
    });
    await this.$store.dispatch('syncAuthenticatedUser');
  }

  get userEmail() {
    if (this.user) {
      return this.user.attributes.email;
    }
    return '';
  }
}
