


















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  footerItems = [
    { text: '利用規約', link: process.env.VUE_APP_TERMS_OF_SERVICE_URL, externalPage: true },
    { text: 'プライバシーポリシー', link: process.env.VUE_APP_SECURITY_POLICY_URL, externalPage: true },
    { text: '著作権について', link: process.env.VUE_APP_COPYRIGHT_URL, externalPage: true },
    { text: '特定商取引法に基づく表記', link: '/law', externalPage: false },
    { text: 'よくある質問', link: process.env.VUE_APP_FAQ_URL, externalPage: true },
  ]

  icons = [
    {
      icon: 'mdi-facebook',
      link: 'https://www.facebook.com/HAZERU-ART-109183934718106',
    },
    {
      icon: 'mdi-twitter',
      link: 'https://twitter.com/ArtHazeru',
    },
    {
      icon: 'mdi-instagram',
      link: 'https://www.instagram.com/hazeruart_kiconia/',
    },
  ];

  hazeruMagazineUrl = process.env.VUE_APP_HAZERU_MAGAZINE_URL;

  // eslint-disable-next-line class-methods-use-this
  get isMobile(): boolean {
    return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
  }
}
