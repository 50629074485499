import { DigitalArtCollectionItem } from "@/repository/artsub-backend-types";
import DigitalArtAdapter from "@/adapter/digitalArtAdapter";

export default class DigitalArtCollectionItemAdapter implements DigitalArtCollectionItem {
  readonly id: string;

  readonly art: DigitalArtAdapter;

  readonly order: string;

  constructor(params: DigitalArtCollectionItem) {
    this.id = params.id;
    this.art = new DigitalArtAdapter(params.art);
    this.order = params.order;
  }
}
