


























































































































import {
  Component,
  Vue,
  Emit,
  Prop, Watch,
} from 'vue-property-decorator';
import { ShippingAddress, ShippingAddressPostBody } from '@/repository/artsub-backend-types';

@Component
export default class UserArtProductPaymentProfile extends Vue {
  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: null })
  shippingAddress!: ShippingAddress | null;

  lastName = '';

  firstName = '';

  postalCode = '';

  address1 = '';

  address2 = '';

  address3 = '';

  address4 = '';

  tel = '';

  address1List = [
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ]

  defaultCountry = '日本';

  created(): void {
    if (this.shippingAddress !== null) {
      this.lastName = this.shippingAddress.last_name;
      this.firstName = this.shippingAddress.first_name;
      this.postalCode = this.shippingAddress.postal_code;
      this.address1 = this.shippingAddress.state;
      this.address2 = this.shippingAddress.city;
      this.address3 = this.shippingAddress.line1;
      this.address4 = this.shippingAddress.line2 || '';
      this.tel = this.shippingAddress.phone;
    }
  }

  @Emit('export')
  export(): ShippingAddressPostBody {
    return {
      country: this.defaultCountry, // 現状は日本のみ
      postal_code: this.postalCode,
      state: this.address1,
      city: this.address2,
      line1: this.address3,
      line2: this.address4,
      last_name: this.lastName,
      first_name: this.firstName,
      phone: this.tel,
    };
  }

  searchAddressFromPostalCode(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
    const this_ = this;
    // eslint-disable-next-line no-undef,no-new
    new YubinBango.Core(this.postalCode, (addr: any) => {
      this_.address1 = addr.region;
      this_.address2 = addr.locality;
      this_.address3 = addr.street;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ruleRequired(value: string): boolean | string {
    if (value === '') {
      return '必須項目です。';
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  ruleZipcode(zipcode: string): boolean | string {
    const re = /^\d{7}$/;
    return re.test(zipcode);
  }

  // eslint-disable-next-line class-methods-use-this
  ruleTel(tel: string): boolean | string {
    const re = /^\d+$/;
    return re.test(tel);
  }
}
