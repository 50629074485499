
























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ProductsList from '@/components/ProductsList.vue';
import {
  getDigitalArtProductList,
  getPaymentIntent,
  postPaymentIntent,
  putPaymentIntentProduct,
} from '@/repository/artsub-backend';
import {
  HazeruArtDigitalArtProduct,
  OtherUser,
  StripePaymentIntent,
  User,
} from '@/repository/artsub-backend-types';
import { addProductToCart } from '@/lib/costCalculator';
import DigitalArtProductAdapter from '@/adapter/digitalArtProductAdapter';

@Component({
  computed: mapGetters(['jwtToken', 'isAuthenticatedUser']),
  components: {
    ProductsList,
  },
})
export default class UserArtProduct extends Vue {
  jwtToken!: string | null;

  isAuthenticatedUser!: boolean;

  products: Array<DigitalArtProductAdapter> = [];

  artist: User | OtherUser | null = null;

  artId = '';

  product: DigitalArtProductAdapter = new DigitalArtProductAdapter({
    id: '',
    digital_art: null,
    name: '',
    price: 0,
    description: '',
    stripe_product_id: '',
    category: 't_shirt',
    category_info: {
      size: '',
      color: '',
      display_options: '',
      printable_area: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      physics: {
        width_milli: 0,
        height_milli: 0,
      },
    },
    presigned_get_url: require('@/assets/preview_dummy.png'), // eslint-disable-line global-require
    presigned_get_watermark_ichimatsu_url: require('@/assets/preview_dummy.png'), // eslint-disable-line global-require
  });

  targetProducts: Array<DigitalArtProductAdapter> = [];

  paymentIntent: StripePaymentIntent | null = null;

  numberOfProduct = 1;

  nonDisplayOtherProducts: Array<string> = [];

  category = '';

  header = [
    '身丈',
    '身幅',
    '肩幅',
    '袖丈',
  ]

  items = {
    t_shirt: [
      ['S', '65', '49', '42', '19'],
      ['M', '69', '52', '46', '20'],
      ['L', '73', '55', '50', '22'],
      ['XL', '77', '58', '54', '24'],
    ],
    long_t_shirt: [
      ['S', '65', '49', '42', '60'],
      ['M', '69', '52', '45', '62'],
      ['L', '73', '55', '48', '63'],
      ['XL', '77', '58', '52', '64'],
    ],
    parker: [
      ['S', '63', '52', '44', '56'],
      ['M', '67', '55', '48', '60'],
      ['L', '71', '58', '52', '61'],
      ['XL', '76', '63', '55', '62'],
    ],
  }

  colors = new Set<{
    value: string,
    text: string,
  }>();

  colorsChangeTracker = 1;

  selectedColors = '';

  sizes = new Set<string>();

  sizesChangeTracker = 1;

  selectedSizes = '';

  displayOptions = new Set<{
    value: string,
    text: string,
  }>();

  displayOptionsChangeTracker = 1;

  selectedDisplayOptions = '';

  colorsJP: { [key: string]: string } = {
    black: 'ブラック',
    gray: 'グレー',
    white: 'ホワイト',
    transparent: '透明',
  }

  displayOptionsJP: { [key: string]: string } = {
    none: '穴あけ加工なし',
    stand: 'スタンド付き',
    wallmount: '壁掛け',
  }

  sizeOrder: { [name: string]: number } = {
    S: 1,
    M: 2,
    L: 3,
    XL: 4,
  };

  async created(): Promise<void> {
    this.artId = this.$route.params.artId;
    this.category = this.$route.params.category;
    console.log(this.category);
    this.nonDisplayOtherProducts.push(this.category);
    try {
      const resDigitalArtProduct = await getDigitalArtProductList(this.jwtToken, this.artId);
      this.products = resDigitalArtProduct.data
        .map((product: HazeruArtDigitalArtProduct) => new DigitalArtProductAdapter(product));
      this.targetProducts = this.products.filter(
        (p) => p.category === this.category,
      );

      // 作成時初期値を設定
      // eslint-disable-next-line prefer-destructuring
      this.product = this.targetProducts[0];
      this.selectedColors = this.product.category_info.color;
      this.selectedSizes = this.product.category_info.size;
      this.selectedDisplayOptions = this.product.category_info.display_options;

      // 色・サイズ・オプションのセットを作成
      this.targetProducts.forEach(
        (product) => this.setInfo(product),
      );
      console.log(this.displayOptions);
    } catch (e) {
      console.log(e);
      if (e.response.status === 404) {
        await this.$router.push('/user/home');
      }
    }

    if (this.jwtToken !== null) {
      try {
        const resPaymentIntent = await getPaymentIntent(this.jwtToken);
        this.paymentIntent = resPaymentIntent.data;
        console.log(this.paymentIntent);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async addCart(): Promise<void> {
    if (this.product.id === '') {
      return;
    }

    if (this.paymentIntent === null) {
      const [amount, metadata] = addProductToCart(0, {}, this.product, this.numberOfProduct);
      await postPaymentIntent(this.jwtToken, amount, 'jpy', metadata);
    } else {
      const [amount, metadata] = addProductToCart(
        this.paymentIntent.amount, this.paymentIntent.metadata, this.product, this.numberOfProduct,
      );
      await putPaymentIntentProduct(this.jwtToken, this.paymentIntent.id, amount, metadata);
    }

    await this.$router.push('/user/cart');
  }

  // eslint-disable-next-line class-methods-use-this
  get amountList(): Array<number> {
    // 個数の選択肢のリストを生成
    return [...Array(99)].map((v, i) => i + 1);
  }

  setInfo(product: DigitalArtProductAdapter): void {
    if (typeof product.category_info.size !== 'undefined') {
      this.sizes.add(product.category_info.size);
      this.sizesChangeTracker += 1;
    }
    if (typeof product.category_info.color !== 'undefined') {
      this.colors.add({
        value: product.category_info.color,
        text: this.translateColor(product.category_info.color),
      });
      this.colorsChangeTracker += 1;
    }
    if (typeof product.category_info.display_options !== 'undefined') {
      this.displayOptions.add({
        value: product.category_info.display_options,
        text: this.translateDisplayOption(product.category_info.display_options),
      });
      this.displayOptionsChangeTracker += 1;
    }
  }

  setProduct(): void {
    const matchProducts = this.targetProducts.filter(
      (product) => this.checkProduct(product),
    );
    if (matchProducts.length !== 1) {
      console.log('match some products.');
      console.log(matchProducts);
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.product = matchProducts[0];
    }
  }

  checkProduct(product: HazeruArtDigitalArtProduct): boolean {
    return product.category_info.size === this.selectedSizes
      && product.category_info.color === this.selectedColors
      && product.category_info.display_options === this.selectedDisplayOptions;
  }

  get colorsAsList(): number | Array<{ value: string; text: string }> {
    return this.colorsChangeTracker && Array.from(this.colors);
  }

  get sizesAsList(): number | Array<string> {
    return this.sizesChangeTracker && Array.from(this.sizes).sort(this.sizeCompare);
  }

  get displayOptionAsList(): number | Array<{ value: string; text: string; }> {
    return this.displayOptionsChangeTracker && Array.from(this.displayOptions);
  }

  translateColor(color: string): string {
    return this.colorsJP[color];
  }

  translateDisplayOption(displayOption: string): string {
    return this.displayOptionsJP[displayOption];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sizeCompare(a: string, b: string) {
    const aOrderRank = this.sizeOrder[a] || 0;
    const bOrderRank = this.sizeOrder[b] || 0;
    if (aOrderRank < bOrderRank) {
      return -1;
    }
    if (aOrderRank > bOrderRank) {
      return 1;
    }
    return 0;
  }
}
