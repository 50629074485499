import {
  HazeruArtDigitalArtProductCatalogItem,
  HazeruArtDigitalArtProductCatalogItemCategory,
  HazeruArtDigitalArtProductCategoryInfo,
} from '@/repository/artsub-backend-types';

export default class DigitalArtProductCatalogItemAdapter
  implements HazeruArtDigitalArtProductCatalogItem {
  readonly id: string;

  readonly name: string;

  readonly category: HazeruArtDigitalArtProductCatalogItemCategory;

  // eslint-disable-next-line camelcase
  readonly category_info: HazeruArtDigitalArtProductCategoryInfo;

  readonly description: string;

  // eslint-disable-next-line camelcase
  private readonly _presigned_get_url: string;

  constructor(params: HazeruArtDigitalArtProductCatalogItem) {
    this.id = params.id;
    this.name = params.name;
    this.category = params.category;
    this.category_info = params.category_info;
    this.description = params.description;
    // eslint-disable-next-line no-underscore-dangle
    this._presigned_get_url = params.presigned_get_url;
  }

  // eslint-disable-next-line camelcase
  get presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._presigned_get_url;
  }
}
