import {
  HazeruArtDigitalArtProductCatalogItemCategory,
  HazeruArtDigitalArtProductCategoryInfo,
  HazeruArtDigitalArtProductDetail,
} from '@/repository/artsub-backend-types';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class DigitalArtProductDetailAdapter implements HazeruArtDigitalArtProductDetail {
  readonly id: string;

  readonly count: number;

  readonly digital_art: DigitalArtAdapter; // eslint-disable-line camelcase

  readonly name: string;

  readonly price: number;

  readonly description: string;

  readonly category: HazeruArtDigitalArtProductCatalogItemCategory;

  readonly category_info: HazeruArtDigitalArtProductCategoryInfo; // eslint-disable-line camelcase

  readonly stripe_product_id: string; // eslint-disable-line camelcase

  readonly _presigned_get_url: string; // eslint-disable-line camelcase

  readonly _presigned_get_watermark_ichimatsu_url: string; // eslint-disable-line camelcase

  constructor(params: HazeruArtDigitalArtProductDetail) {
    this.id = params.id;
    this.count = params.count;
    this.digital_art = new DigitalArtAdapter(params.digital_art);
    this.name = params.name;
    this.price = params.price;
    this.description = params.description;
    this.category = params.category;
    this.category_info = params.category_info;
    this.stripe_product_id = params.stripe_product_id;
    // eslint-disable-next-line no-underscore-dangle
    this._presigned_get_url = params.presigned_get_url;
    // eslint-disable-next-line no-underscore-dangle
    this._presigned_get_watermark_ichimatsu_url = params.presigned_get_watermark_ichimatsu_url;
  }

  // eslint-disable-next-line camelcase
  get presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    return convertPresignedUrlToImProjectUrl(this._presigned_get_url);
  }

  // eslint-disable-next-line camelcase
  get presigned_get_watermark_ichimatsu_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    return convertPresignedUrlToImProjectUrl(this._presigned_get_watermark_ichimatsu_url);
  }
}
