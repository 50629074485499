import { kebabCase } from 'lodash';
import { RouteConfig } from 'vue-router';

export default function layoutComponent(layout = 'Default', children: Array<RouteConfig>, path = ''): any {
  const dir = kebabCase(layout);

  return {
    children,
    component: () => import(
      /* webpackChunkName: "layout-[request]" */
      `@/layouts/${dir}/Index`
    ),
    path,
  };
}
