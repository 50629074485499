



















































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ArtList from '@/layouts/user/ArtList.vue';
import {
  deleteDigitalArtLike,
  getDigitalArt,
  getDigitalArtLike,
  postDigitalArtLike,
} from '@/repository/artsub-backend';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';

@Component({
  computed: mapGetters(['jwtToken']),
  components: { ArtList },
})
export default class UserLikePage extends Vue {
  jwtToken!: string | null;

  arts: Array<DigitalArtAdapter> = [];

  async created(): Promise<void> {
    const resDigitalArtsLike = await getDigitalArtLike(this.jwtToken);
    for (let idx = 0; idx < resDigitalArtsLike.data.length; idx += 1) {
      // eslint-disable-next-line no-await-in-loop
      const resDigitalArt = await getDigitalArt(this.jwtToken, resDigitalArtsLike.data[idx].art);
      this.arts.push(new DigitalArtAdapter(resDigitalArt.data));
    }
  }

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  // eslint-disable-next-line class-methods-use-this
  makeDigitalArtKey(prefix: string, art: DigitalArtAdapter): string {
    return `${prefix}-${art.id}`;
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '45vw';
      case 'sm': return '45vw';
      case 'md': return '210px';
      case 'lg': return '210px';
      case 'xl': return '210px';
      default: return '210px';
    }
  }

  async addDigitalArtLike(art: DigitalArtAdapter): Promise<void> {
    try {
      const res = await postDigitalArtLike(this.jwtToken, { art: art.id });
      if (art) {
        this.$set(art, 'like_id', res.data.id);
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeDigitalArtLike(art: DigitalArtAdapter): Promise<void> {
    if (art.like_id) {
      try {
        await deleteDigitalArtLike(this.jwtToken, art.like_id);
        if (art) {
          this.$set(art, 'like_id', null);
        }
      } catch (e) {
        console.log(e);
        console.log(e.response.status_code);
      }
    }
  }
}
