import {
  HazeruArtDigitalArt,
  HazeruArtSoldDigitalArtProduct,
} from "@/repository/artsub-backend-types";
import DigitalArtAdapter from "@/adapter/digitalArtAdapter";

export default class SoldDigitalArtProductAdapter implements HazeruArtSoldDigitalArtProduct {
  id: string;

  // eslint-disable-next-line camelcase
  digital_art: HazeruArtDigitalArt;

  currency: string;

  profit: number;

  // eslint-disable-next-line camelcase
  created_datetime: string;

  constructor(params: HazeruArtSoldDigitalArtProduct) {
    this.id = params.id;
    this.digital_art = new DigitalArtAdapter(params.digital_art);
    this.currency = params.currency;
    this.profit = params.profit;
    this.created_datetime = params.created_datetime;
  }
}
