








































import { Component, Vue } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import LoginButton from '@/components/LoginButton.vue';

@Component({
  components: {
    LoginButton,
  },
})
export default class Authenticator extends Vue {
  loading = false;

  providers: string[] = ['google', 'facebook'];
}
