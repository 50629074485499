import {
  StripePaymentIntentShipping,
  StripePaymentIntentSucceeded,
} from "@/repository/artsub-backend-types";
import DigitalArtProductDetailAdapter from "@/adapter/digitalArtProductDetailAdapter";

export default class StripePaymentIntentSucceededAdapter implements StripePaymentIntentSucceeded {
  amount: number;

  // eslint-disable-next-line camelcase
  created_datetime: string;

  currency: string;

  products: Array<DigitalArtProductDetailAdapter>;

  shipping: StripePaymentIntentShipping;

  constructor(params: StripePaymentIntentSucceeded) {
    this.amount = params.amount;
    this.created_datetime = params.created_datetime;
    this.currency = params.currency;
    this.products = params.products.map((product) => new DigitalArtProductDetailAdapter(product));
    this.shipping = params.shipping;
  }
}
