// eslint-disable-next-line class-methods-use-this
import {
  HazeruArtDigitalArtProduct,
  HazeruArtDigitalArtProductCartItem,
  StripePaymentIntentShipping,
} from "@/repository/artsub-backend-types";
import DigitalArtProductCartItemAdapter from "@/adapter/digitalArtProductCartItemAdapter";
import DigitalArtProductAdapter from "@/adapter/digitalArtProductAdapter";

const deliveryChargeDefault = 500;
const deliveryChargeHokkaido = 1200;
const deliveryChargeOkinawa = 1200;

function calcProductSubTotalCost(productCartItem: DigitalArtProductCartItemAdapter): number {
  return productCartItem.digital_art_product.price * productCartItem.amount;
}

function calcProductTotalCost(productCartItems: Array<DigitalArtProductCartItemAdapter>): number {
  return productCartItems.reduce(
    (subTotal, productCartItem): number => subTotal + calcProductSubTotalCost(productCartItem),
    0
  );
}

function selectDeliveryCharge(address: string): number {
  const prefectureRegex = /^(東京都|北海道|(?:京都|大阪)府|.{2,3}県)/;
  const matchResult = address.match(prefectureRegex);

  if (matchResult === null) {
    return -1;
  }

  const prefecture = matchResult[0];
  if (prefecture === "北海道") {
    return deliveryChargeHokkaido;
  }
  if (prefecture === "沖縄県") {
    return deliveryChargeOkinawa;
  }

  return deliveryChargeDefault;
}

function calcTotalCost(
  productCartItems: Array<DigitalArtProductCartItemAdapter>,
  address: string | null
): number {
  if (address === null) {
    return calcProductTotalCost(productCartItems);
  }

  const deliveryCharge = selectDeliveryCharge(address);
  return calcProductTotalCost(productCartItems) + deliveryCharge;
}

function addProductToCart(
  oldAmount: number,
  oldMetadata: { [key: string]: number },
  product: DigitalArtProductAdapter,
  numberOfProduct: number
): [number, { [key: string]: number }] {
  const newAmount = oldAmount + product.price * numberOfProduct;
  // eslint-disable-next-line prefer-object-spread
  const newMetadata = Object.assign({}, oldMetadata);

  if (Object.prototype.hasOwnProperty.call(newMetadata, product.id)) {
    // 実際のvalueがstringになることがあった
    newMetadata[product.id] = parseInt(String(newMetadata[product.id]), 10) + numberOfProduct;
  } else {
    newMetadata[product.id] = numberOfProduct;
  }

  return [newAmount, newMetadata];
}

// profileはUserArtProductPaymentProfileのexportデータ
function addShippingData(oldAmount: number, shipping: StripePaymentIntentShipping): number {
  const deliveryCharge = selectDeliveryCharge(shipping.address.state);

  return oldAmount + deliveryCharge;
}

export {
  selectDeliveryCharge,
  calcProductSubTotalCost,
  calcProductTotalCost,
  calcTotalCost,
  addProductToCart,
  addShippingData,
};
