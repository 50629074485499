

































































import { Component, Vue } from 'vue-property-decorator';
import {
  HazeruArtArtistPagination,
} from '@/repository/artsub-backend-types';
import {
  deleteArtistLike,
  getLikeArtistList,
  getLikeArtistListPagination, postArtistLike,
} from '@/repository/artsub-backend';
import { mapGetters } from 'vuex';
import OtherUserAdapter from '@/adapter/otherUserAdapter';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class UserArtistLikePage extends Vue {
  jwtToken!: string | null;

  artists: Array<OtherUserAdapter> = [];

  nextUrl: string | null = null;

  async created(): Promise<void> {
    this.$watch(
      () => this.$route.params,
      async (toParams, previousParams) => {
        await this.fetchFirstPage();
      },
    );
    await this.fetchFirstPage();
  }

  toArtistDetail(artistId: string): void {
    this.$router.push(`/user/artist/${artistId}`);
  }

  async fetchFirstPage(): Promise<void> {
    const res: HazeruArtArtistPagination = (
      await getLikeArtistList(this.jwtToken)).data;
    this.artists = res.results.map((artist) => new OtherUserAdapter(artist));
    this.nextUrl = res.next;
  }

  async fetchNextPage(): Promise<void> {
    if (this.nextUrl === null) {
      return;
    }

    const res: HazeruArtArtistPagination = (
      await getLikeArtistListPagination(this.jwtToken, this.nextUrl)).data;

    this.artists = this.artists.concat(res.results.map((artist) => new OtherUserAdapter(artist)));
    this.nextUrl = res.next;
  }

  async addArtistLike(cognitoId: string, artistId: string): Promise<void> {
    try {
      const res = await postArtistLike(this.jwtToken, { artist: artistId });
      if (this.artists) {
        const targetArtist = this.artists.filter((artist) => artist.cognito_id === cognitoId);
        targetArtist[0].like_id = res.data.id;
        targetArtist[0].likes_count += 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeArtistLike(cognitoId: string, likeId: string): Promise<void> {
    try {
      await deleteArtistLike(this.jwtToken, likeId);
      if (this.artists) {
        const targetArtist = this.artists.filter((artist) => artist.cognito_id === cognitoId);
        targetArtist[0].like_id = null;
        targetArtist[0].likes_count -= 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }
}
