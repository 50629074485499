import Vue from 'vue';
import Amplify, { Auth } from 'aws-amplify';
import '@aws-amplify/ui-vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

const redirectUrl = window.location.origin; // ログイン・ログアウト後はTopに戻るようにする
// referencing:
// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: process.env.VUE_APP_COGNITO_APP_IDENTITY_POOL_ID,
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: '',
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_COGNITO_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_COGNITO_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});
Auth.configure();

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      // @ts-ignore
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [`${process.env.VUE_APP_SENTRY_TRACING_ORIGIN}`, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
