

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { HazeruArtDigitalArt } from '@/repository/artsub-backend-types';
import { deleteDigitalArtLike, postDigitalArtLike } from '@/repository/artsub-backend';
import { gaArtLike } from '@/lib/gtag';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class ArtLikeBtn extends Vue {
  @Prop({ default: null })
  art!: HazeruArtDigitalArt | null;

  jwtToken!: string | null;

  async addDigitalArtLike(artId: string): Promise<void> {
    try {
      const res = await postDigitalArtLike(this.jwtToken, { art: artId });
      if (this.art) {
        gaArtLike(this.art);
        this.art.like_id = res.data.id;
        this.art.likes_count += 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  async removeDigitalArtLike(likeId: string): Promise<void> {
    try {
      await deleteDigitalArtLike(this.jwtToken, likeId);
      if (this.art) {
        this.art.like_id = null;
        this.art.likes_count -= 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }

  get btnSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '25px';
      case 'sm': return '25px';
      case 'md': return '40px';
      case 'lg': return '40px';
      case 'xl': return '40px';
      default: return '40px';
    }
  }
}
