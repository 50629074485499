
























































































































































































































import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import {
  calcProductSubTotalCost,
  calcProductTotalCost,
  calcTotalCost, selectDeliveryCharge,
} from '@/lib/costCalculator';
import DigitalArtProductCartItemAdapter from '@/adapter/digitalArtProductCartItemAdapter';

@Component
export default class CartBody extends Vue {
  @Prop({ default: [] })
  productCartItems!: Array<DigitalArtProductCartItemAdapter>;

  @Prop({ default: null })
  address!: string | null;

  @Prop({ default: false })
  editable!: boolean;

  deleteItemText = 'delete';

  @Emit('change-product-cart')
  changeProductAmount(
    productCartItemIndex: number,
    _changedAmount: number | string,
  ): Array<DigitalArtProductCartItemAdapter> {
    const changedProductCartItems: Array<DigitalArtProductCartItemAdapter> = [];

    this.productCartItemsWithoutEmpty.forEach((productCartItem, index) => {
      const changedAmount = _changedAmount === this.deleteItemText ? 0 : _changedAmount;
      const amount = index === productCartItemIndex
        ? changedAmount : productCartItem.amount;
      changedProductCartItems.push({
        amount: parseInt(amount.toString(), 10),
        digital_art_product: JSON.parse(JSON.stringify(productCartItem.digital_art_product)),
      });
    });

    return changedProductCartItems;
  }

  // eslint-disable-next-line class-methods-use-this
  productSubTotalCost(productCartItem: DigitalArtProductCartItemAdapter): number {
    return calcProductSubTotalCost(productCartItem);
  }

  // eslint-disable-next-line class-methods-use-this
  productTotalCost(productCartItems: Array<DigitalArtProductCartItemAdapter>): number {
    return calcProductTotalCost(productCartItems);
  }

  // eslint-disable-next-line class-methods-use-this
  totalCost(
    productCartItems: Array<DigitalArtProductCartItemAdapter>, address: string | null,
  ): number {
    return calcTotalCost(productCartItems, address);
  }

  // eslint-disable-next-line class-methods-use-this
  deliveryCharge(address: string): number {
    return selectDeliveryCharge(address);
  }

  // eslint-disable-next-line class-methods-use-this
  get amountList(): Array<string> {
    return [...Array(99)].map((v, i) => (i + 1).toString());
  }

  get productCartItemsWithoutEmpty(): Array<DigitalArtProductCartItemAdapter> {
    return this.productCartItems.filter((item) => item.amount.toString() !== '0');
  }
}
