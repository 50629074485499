




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Component
export default class LoginButton extends Vue {
  @Prop({ default: null })
  provider!: string;

  loading = false;

  get title(): string {
    console.log(this.provider);
    switch (this.provider) {
      case 'google':
        return 'Googleでログインする';
      case 'facebook':
        return 'Facebookでログインする';
      default:
        return '';
    }
  }

  async signIn(provider: string): Promise<void> {
    this.loading = true;
    let res;
    console.log('signIn');
    try {
      switch (this.provider) {
        case 'google':
          res = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
          break;
        case 'facebook':
          res = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
          break;
        default:
          return;
      }
    } catch (e) {
      console.log(e);
    }
    console.log(res);
    this.loading = false;
  }
}
