import {
  HazeruArtDigitalArtCustomProductGroup,
  HazeruArtDigitalArtProductCategoryInfo,
} from '@/repository/artsub-backend-types';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class DigitalArtCustomProductGroupAdapter
  implements HazeruArtDigitalArtCustomProductGroup {
  readonly id: string;

  // eslint-disable-next-line camelcase
  readonly digital_art_id: string;

  // eslint-disable-next-line camelcase
  readonly group_id: string;

  // eslint-disable-next-line camelcase
  readonly product_name: string;

  // eslint-disable-next-line camelcase
  readonly art_left: number;

  // eslint-disable-next-line camelcase
  readonly art_top: number;

  // eslint-disable-next-line camelcase
  readonly art_right: number;

  // eslint-disable-next-line camelcase
  readonly art_bottom: number;

  readonly price: number;

  readonly category: string;

  // eslint-disable-next-line camelcase
  readonly category_info: HazeruArtDigitalArtProductCategoryInfo;

  readonly description: string;

  // eslint-disable-next-line camelcase
  private readonly _presigned_get_url: string;

  constructor(params: HazeruArtDigitalArtCustomProductGroup) {
    this.id = params.id;
    this.digital_art_id = params.digital_art_id;
    this.group_id = params.group_id;
    this.product_name = params.product_name;
    this.art_left = params.art_left;
    this.art_top = params.art_top;
    this.art_right = params.art_right;
    this.art_bottom = params.art_bottom;
    this.price = params.price;
    this.category = params.category;
    this.category_info = params.category_info;
    this.description = params.description;
    // eslint-disable-next-line no-underscore-dangle
    this._presigned_get_url = params.presigned_get_url;
  }

  // eslint-disable-next-line camelcase
  get presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._presigned_get_url;
  }
}
