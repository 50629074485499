










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HazeruMagazineButtonLarge extends Vue {
  @Prop({ default: '10vw' })
  width!: string;

  hazeruMagazineUrl = process.env.VUE_APP_HAZERU_MAGAZINE_URL;
}
