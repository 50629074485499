






























































































import { AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getArtistBankAccount,
  postArtistBankAccount,
  putArtistBankAccount,
  deleteArtistBankAccount,
} from '@/repository/artsub-backend';
import { generateArtistBankInformationDefaults, User, ArtistBankInformation } from '@/repository/artsub-backend-types';
import UserAdapter from '@/adapter/userAdapter';

interface artistBankInformationRequest {
  (jwt: string | null, bankInfo: ArtistBankInformation): Promise<AxiosResponse>
}

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class Home extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  bankInformation: ArtistBankInformation = generateArtistBankInformationDefaults();

  bankInformationReadOnly = false;

  notificationMessage = '';

  notificationBar = false;

  overlay = false;

  bankCodeString: string | null = null;

  branchCodeString: string | null = null;

  accountNumberString: string | null = null;

  get bankCode(): string | null {
    return this.bankCodeString;
  }

  set bankCode(value: string | null) {
    this.bankCodeString = value;
    this.bankInformation.bank_code = this.bankCodeString ? parseInt(this.bankCodeString, 10) : null;
  }

  get branchCode(): string | null {
    return this.branchCodeString;
  }

  set branchCode(value: string | null) {
    this.branchCodeString = value;
    this.bankInformation.branch_code = this.branchCodeString
      ? parseInt(this.branchCodeString, 10)
      : null;
  }

  get accountNumber(): string | null {
    return this.accountNumberString;
  }

  set accountNumber(value: string | null) {
    this.accountNumberString = value;
    this.bankInformation.account_number = this.accountNumberString
      ? parseInt(this.accountNumberString, 10)
      : null;
  }

  async updateBankInformationDisplay(): Promise<void> {
    const pad = function (v: number | null, n: number) {
      return v ? v.toString().padStart(n, '0') : null;
    };
    this.bankCode = pad(this.bankInformation.bank_code, 4);
    this.branchCode = pad(this.bankInformation.branch_code, 3);
    this.accountNumber = pad(this.bankInformation.account_number, 7);
  }

  async created(): Promise<void> {
    // get ArtistBankInformation
    const res = await getArtistBankAccount(this.jwtToken);
    if (res.status === 200 && res.data.count === 1) {
      [this.bankInformation] = res.data.results;
      await this.updateBankInformationDisplay();
      this.bankInformationReadOnly = true;
    } else {
      console.log(res);
    }
  }

  async createUpdateArtistBankAccount(
    req: artistBankInformationRequest,
    successStatusCode: number,
  ): Promise<void> {
    this.overlay = true;
    console.log(this.bankInformation);
    try {
      const res = await req(this.jwtToken, this.bankInformation);
      console.log(res);
      if (res.status === successStatusCode) {
        await this.updateBankInformationDisplay();
        this.notificationMessage = '登録しました';
        this.notificationBar = true;
      }
    } catch (error) {
      console.log(error);
      const { response } = error;
      if (response.data.non_field_errors) {
        if (response.data.non_field_errors.find((e: Array<string>) => e.includes('BankBranch.DoesNotExist'))) {
          this.notificationMessage = `無効な銀行コードまたは、支店コード: ${this.bankCodeString}-${this.branchCodeString}`;
          this.notificationBar = true;
        } else {
          console.log(response.data.non_field_errors);
        }
      } else if ('account_yomi' in response.data) {
        this.notificationMessage = '口座名義（ヨミ）: 全角カタカナ、ー（長音）、全角半角スペースのみを使用して下さい';
        this.notificationBar = true;
      }
    }
    this.overlay = false;
  }

  async createArtistBankAccount(): Promise<void> {
    await this.createUpdateArtistBankAccount(postArtistBankAccount, 201);
  }

  async updateArtistBankAccount(): Promise<void> {
    await this.createUpdateArtistBankAccount(putArtistBankAccount, 200);
  }

  async deleteArtistBankAccount(): Promise<void> {
    this.overlay = true;
    const res = await deleteArtistBankAccount(this.jwtToken, this.bankInformation);
    this.overlay = false;
    if (res.status === 204) {
      this.bankInformation = generateArtistBankInformationDefaults();
    } else {
      console.log(res);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  codeValidationRule(
    value: string, length: number, max: number, errMessage: string,
  ): boolean | string {
    if (value.length !== length) {
      return `${length}桁の数字を入力して下さい`;
    }

    const num = parseInt(value, 10);
    if (Number.isNaN(num)) {
      return `${length}桁の数字を入力して下さい`;
    }

    if (num >= 0 && num <= max) {
      return true;
    }
    return errMessage;
  }

  bankCodeValidationRule(value: string): boolean | string {
    return this.codeValidationRule(value, 4, 9999, '無効な銀行コード');
  }

  branchCodeValidationRule(value: string): boolean | string {
    return this.codeValidationRule(value, 3, 999, '無効な支店コード');
  }

  accountNumberValidationRule(value: string): boolean | string {
    return this.codeValidationRule(value, 7, 9999999, '無効な口座番号');
  }

  // eslint-disable-next-line class-methods-use-this
  accountYomiValidationRule(value: string): boolean | string {
    const exp = new RegExp('^[ァ-ヴ| |　|ー]+$');
    const ok = exp.exec(value);
    if (ok) {
      return true;
    }
    return '口座名義（ヨミ）: 全角カタカナ、ー（長音）、全角半角スペースのみを使用して下さい';
  }
}
