












































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { fabric } from 'fabric';
import {
  HazeruArtDigitalArtPagination,
  HazeruArtDigitalArtProductCatalogItem,
} from '@/repository/artsub-backend-types';
import {
  getDigitalArtList,
  getDigitalArtListPagination,
  getDigitalArtProductCatalogItems,
  postDigitalArtCustomProductGroup,
} from '@/repository/artsub-backend';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import UserAdapter from '@/adapter/userAdapter';
import DigitalArtProductCatalogItemAdapter from '@/adapter/digitalArtProductCatalogItemAdapter';

type updateType = 'updateX' | 'updateY' | 'updateWidth' | 'updateHeight';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class ArtistProductNew extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  canvasWidth = 500;

  scale = 1;

  catalogItemHeader = [
    { text: '商品名', value: 'name' },
    { text: '価格', value: 'price' },
    { text: '画像', value: 'presigned_get_url' },
    { text: '', value: 'button' },
  ];

  artHeader = [
    { text: 'タイトル', value: 'title' },
    { text: '画像', value: 'presigned_get_url' },
    { text: '', value: 'button' },
  ];

  phase: 'catalog_item' | 'art' | 'printing' = 'art';

  isLoading = false;

  arts: Array<DigitalArtAdapter> = [];

  catalogItems: Array<DigitalArtProductCatalogItemAdapter> = [];

  selectedCatalogItem: DigitalArtProductCatalogItemAdapter | null = null;

  selectedArt: DigitalArtAdapter | null = null;

  fabricArtImg: fabric.Image | null = null;

  canvas: fabric.Canvas | null = null;

  artXMilli = 0.0;

  artYMilli = 0.0;

  artWidthMilli = 0.0;

  artHeightMilli = 0.0;

  error = '';

  async created(): Promise<void> {
    const res = await getDigitalArtProductCatalogItems(this.jwtToken);
    const catalogItems: Array<DigitalArtProductCatalogItemAdapter> = res.data.map(
      (c: HazeruArtDigitalArtProductCatalogItem) => new DigitalArtProductCatalogItemAdapter(c),
    );
    this.catalogItems = catalogItems.filter(
      (catalogItem) =>
        catalogItem.category_info.size === 'S' &&
        catalogItem.category_info.color === 'white' &&
        (catalogItem.category === 't_shirt' ||
          catalogItem.category === 'long_t_shirt' ||
          catalogItem.category === 'parker'),
    );
    console.log(this.catalogItems);
  }

  async mounted(): Promise<void> {
    let artPagination: HazeruArtDigitalArtPagination = (
      await getDigitalArtList(this.jwtToken, this.user?.cognito_id, null, true)
    ).data;
    this.arts = artPagination.results.map((art) => new DigitalArtAdapter(art));

    while (artPagination.next !== null) {
      // eslint-disable-next-line no-await-in-loop
      artPagination = (await getDigitalArtListPagination(this.jwtToken, artPagination.next)).data;
      // eslint-disable-next-line no-restricted-syntax
      for (const art of artPagination.results) {
        this.arts.push(new DigitalArtAdapter(art));
      }
    }
  }

  @Watch('phase')
  setupPrinting(): void {
    if (this.phase !== 'printing' || this.selectedCatalogItem === null || this.selectedArt === null)
      return;

    const catalogItem = this.selectedCatalogItem;
    const art = this.selectedArt;

    fabric.Image.fromURL(catalogItem.presigned_get_url, (catalogItemImg: fabric.Image) => {
      if (catalogItemImg.width === undefined) return;

      catalogItemImg.scale(this.canvasWidth / catalogItemImg.width);
      this.canvas = new fabric.Canvas('canvas', { backgroundImage: catalogItemImg });
      this.scale = this.canvasWidth / catalogItemImg.width;
      const strokeWidth = 5;

      if (this.selectedCatalogItem !== null) {
        const areaRect = new fabric.Rect({
          left: catalogItem.category_info.printable_area.x * this.scale - strokeWidth,
          top: catalogItem.category_info.printable_area.y * this.scale - strokeWidth,
          width: catalogItem.category_info.printable_area.width * this.scale + strokeWidth,
          height: catalogItem.category_info.printable_area.height * this.scale + strokeWidth,
          strokeWidth,
          stroke: 'yellow',
          fill: 'transparent',
          selectable: false,
        });
        this.canvas.add(areaRect);
      }

      fabric.Image.fromURL(
        art.presigned_get_url,
        (artImg: fabric.Image) => {
          if (
            artImg.scaleX === undefined ||
            artImg.scaleY === undefined ||
            artImg.width === undefined ||
            artImg.height === undefined ||
            artImg.left === undefined ||
            artImg.top === undefined ||
            this.canvas === null
          )
            return;
          console.log(artImg);
          this.fabricArtImg = artImg;

          artImg.scale(this.canvasWidth / artImg.width / 3);
          artImg.setControlsVisibility({
            mb: false,
            mr: false,
            ml: false,
            mt: false,
            mtr: false,
          });
          this.canvas.add(artImg);
          this.artXMilli = Math.round(
            this.convertPixelToMilli(
              artImg.left / this.scale - catalogItem.category_info.printable_area.x,
            ),
          );
          this.artYMilli = Math.round(
            this.convertPixelToMilli(
              artImg.top / this.scale - catalogItem.category_info.printable_area.y,
            ),
          );

          this.artWidthMilli = Math.round(
            // eslint-disable-next-line no-mixed-operators
            this.convertPixelToMilli((artImg.width * artImg.scaleX) / this.scale),
          );
          this.artHeightMilli = Math.round(
            // eslint-disable-next-line no-mixed-operators
            this.convertPixelToMilli((artImg.height * artImg.scaleY) / this.scale),
          );

          this.canvas.on('object:moving', (e) => {
            if (
              artImg.scaleX === undefined ||
              artImg.scaleY === undefined ||
              artImg.left === undefined ||
              artImg.top === undefined ||
              artImg.width === undefined ||
              artImg.height === undefined
            )
              return;
            // eslint-disable-next-line no-mixed-operators
            const artXPixel = artImg.left / this.scale - catalogItem.category_info.printable_area.x;
            // eslint-disable-next-line no-mixed-operators
            const artYPixel = artImg.top / this.scale - catalogItem.category_info.printable_area.y;

            this.artXMilli = Math.round(this.convertPixelToMilli(artXPixel));
            this.artYMilli = Math.round(this.convertPixelToMilli(artYPixel));
          });
          this.canvas.on('object:scaling', (e) => {
            if (
              artImg.scaleX === undefined ||
              artImg.scaleY === undefined ||
              artImg.width === undefined ||
              artImg.height === undefined
            )
              return;
            // eslint-disable-next-line no-mixed-operators
            const artWidth = (artImg.width * artImg.scaleX) / this.scale;
            // eslint-disable-next-line no-mixed-operators
            const artHeight = (artImg.height * artImg.scaleY) / this.scale;

            this.artWidthMilli = Math.round(this.convertPixelToMilli(artWidth));
            this.artHeightMilli = Math.round(this.convertPixelToMilli(artHeight));
          });
        },
        { lockScalingFlip: true },
      );
    });
  }

  backPhase(): void {
    if (this.phase === 'printing') {
      this.phase = 'catalog_item';
    } else if (this.phase === 'catalog_item') {
      this.phase = 'art';
    } else if (this.phase === 'art') {
      this.$router.push({ path: '/artist/product' });
    }
  }

  selectArt(art: DigitalArtAdapter): void {
    this.selectedArt = art;
    this.phase = 'catalog_item';
  }

  selectCatalogItem(catalogItem: DigitalArtProductCatalogItemAdapter): void {
    this.selectedCatalogItem = catalogItem;
    this.phase = 'printing';
  }

  convertPixelToMilli(pixel: number): number {
    if (this.selectedCatalogItem === null) return pixel;
    return (
      (pixel *
        // eslint-disable-next-line no-mixed-operators
        this.selectedCatalogItem.category_info.physics.width_milli) /
      // eslint-disable-next-line no-mixed-operators
      this.selectedCatalogItem.category_info.printable_area.width
    );
  }

  convertMilliToPixel(milli: number): number {
    if (this.selectedCatalogItem === null) return milli;
    // eslint-disable-next-line no-mixed-operators
    return (
      (milli *
        // eslint-disable-next-line no-mixed-operators
        this.selectedCatalogItem.category_info.printable_area.width) /
      // eslint-disable-next-line no-mixed-operators
      this.selectedCatalogItem.category_info.physics.width_milli
    );
  }

  async updatePrintArea(type: updateType): Promise<void> {
    if (
      this.fabricArtImg === null ||
      this.selectedCatalogItem === null ||
      this.canvas === null ||
      this.fabricArtImg.width === undefined ||
      this.fabricArtImg.height === undefined
    )
      return;

    const aspectRatio = this.fabricArtImg.width / this.fabricArtImg.height;
    if (type === 'updateWidth') {
      this.artHeightMilli = Math.round(this.artWidthMilli * aspectRatio);
    }
    if (type === 'updateHeight') {
      this.artWidthMilli = Math.round(this.artHeightMilli / aspectRatio);
    }

    const artXPixel = this.convertMilliToPixel(this.artXMilli);
    const artYPixel = this.convertMilliToPixel(this.artYMilli);
    const artWidthPixel = this.convertMilliToPixel(this.artWidthMilli);
    const artHeightPixel = this.convertMilliToPixel(this.artHeightMilli);

    this.fabricArtImg.set({
      left: (artXPixel + this.selectedCatalogItem.category_info.printable_area.x) * this.scale,
      top: (artYPixel + this.selectedCatalogItem.category_info.printable_area.y) * this.scale,
      // eslint-disable-next-line no-mixed-operators
      scaleX: (artWidthPixel * this.scale) / this.fabricArtImg.width,
      // eslint-disable-next-line no-mixed-operators
      scaleY: (artHeightPixel * this.scale) / this.fabricArtImg.height,
    });
    this.fabricArtImg.setCoords();
    this.canvas.renderAll();
  }

  async createCustomProduct(): Promise<void> {
    if (this.selectedArt === null || this.selectedCatalogItem === null) return;
    this.isLoading = true;

    try {
      await postDigitalArtCustomProductGroup(
        this.jwtToken,
        this.selectedArt.id,
        this.selectedCatalogItem.id,
        this.selectedCatalogItem.name,
        this.artXMilli,
        this.artYMilli,
        this.artXMilli + this.artWidthMilli,
        this.artYMilli + this.artHeightMilli,
      );
    } catch (e) {
      this.error = e.response.data;
      this.isLoading = false;
      return;
    }

    await this.$router.push({ path: '/artist/product' });
  }

  get validatePrintArea(): boolean {
    if (this.selectedCatalogItem === null) return false;

    const artX1 = this.artXMilli;
    const artY1 = this.artYMilli;
    const artX2 = this.artXMilli + this.artWidthMilli;
    const artY2 = this.artYMilli + this.artHeightMilli;

    return (
      artX1 >= 0 &&
      this.selectedCatalogItem.category_info.physics.width_milli >= artX2 &&
      artY1 >= 0 &&
      this.selectedCatalogItem.category_info.physics.height_milli >= artY2
    );
  }
}
