
































































import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
// @ts-ignore
import VueCropper from 'vue-cropperjs/VueCropper';
import 'cropperjs/dist/cropper.css';

@Component({
  components: {
    VueCropper,
  },
})
export default class DialogImageCropper extends Vue {
  @Prop({ default: false })
  dialog!: boolean ;

  imgSrc = '';

  cropImg = '';

  fileName = '';

  fileContentType = '';

  async save(): Promise<void> {
    // DialogImageCropperでアス比1を指定しているので正方形
    // 256x256にリサイズして保存する
    const LENGTH = 256;

    console.log('DialogImageCropper save');
    const canvas: HTMLCanvasElement = (this.$refs as any).cropper.getCroppedCanvas();
    const context = canvas.getContext('2d');
    if (context !== null) {
      console.log('resize profile image');
      const cv = document.createElement('canvas');
      cv.width = LENGTH;
      cv.height = LENGTH;
      const ctx = cv.getContext('2d');
      if (ctx !== null) {
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, LENGTH, LENGTH);
        const resizedImageData = ctx.getImageData(0, 0, cv.width, cv.height);

        const imageBuffer = resizedImageData.data.buffer;
        const digest = await crypto.subtle.digest('sha-1', imageBuffer);
        const imageSha1 = Buffer.from(new Uint8Array(digest)).toString('hex');

        this.$emit('save', {
          canvas: cv,
          sha1digest: imageSha1,
          fileName: this.fileName,
          contentType: this.fileContentType,
        });
      }
    }
  }

  @Emit('cancel')
  // eslint-disable-next-line class-methods-use-this
  cancel() {
    console.log('DialogImageCropper cancel');
  }

  get containerStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return { width: '292px', height: '174px' };
      case 'sm': return { width: '292px', height: '174px' };
      case 'md': return { width: '452px', height: '324px' };
      case 'lg': return { width: '452px', height: '324px' };
      case 'xl': return { width: '452px', height: '324px' };
      default: return { width: '452x', height: '324px' };
    }
  }

  showFileChooser(): void {
    (this.$refs as any).input.click();
  }

  setImage(e: any): void {
    if (e != null) {
      const file = e.target.files[0];
      this.fileName = file.name;
      this.fileContentType = file.type;

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          if (event == null) {
            return;
          }
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          (this.$refs as any).cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    }
  }
}
