import axios, { AxiosResponse } from 'axios';

import {
  ArtistBankInformation,
  ArtistLikePostBody,
  HazeruArtArtistApprovalRequestDigitalArtPostBody,
  HazeruArtArtistApprovalRequestPostBody,
  HazeruArtDigitalArtCreate,
  HazeruArtDigitalArtTag,
  IconPostBody,
  StripePaymentIntentShipping,
  DigitalArtLikePostBody,
  ShippingAddressPostBody,
  HazeruArtDigitalArtCategoryType,
  DigitalArtCollectionPostBody,
  DigitalArtCollectionItemPostBody,
  DisplayFrameChangeAction, Survey, SurveyAnswer,
} from './artsub-backend-types';

function generateHeaderFromJwt(jwt: string | null) {
  return jwt !== null ? { Authorization: `Bearer ${jwt}` } : {};
}

async function getUserAccount(jwt: string | null, userId: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function getUserViewsTimeline(
  jwt: string | null,
  userId: string,
  days: number,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/views_timeline/`;
  const headers = generateHeaderFromJwt(jwt);
  const params = { timeline_days: days };
  return axios.get(url, { headers, params });
}

async function getUserLikesTimeline(
  jwt: string | null,
  userId: string,
  days: number,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/likes_timeline/`;
  const headers = generateHeaderFromJwt(jwt);
  const params = { timeline_days: days };
  return axios.get(url, { headers, params });
}

async function getArtistBalances(jwt: string | null, userId: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/balances/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postUserShippingAddress(
  jwt: string | null,
  userId: string,
  shippingAddress: ShippingAddressPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/shippingaddresses/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, shippingAddress, { headers });
}

async function deleteUserShippingAddress(
  jwt: string | null,
  userId: string,
  shippingAddressId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/shippingaddresses/${shippingAddressId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function putUserAccount(
  jwt: string | null,
  userId: string,
  params: any,
): Promise<AxiosResponse> {
  // 引数の型をObjectにするとエラーが起きるため以下の方法でObjectかどうかを判定
  if (Object.prototype.toString.call(params).slice(8, -1).toLowerCase() !== 'object') {
    throw new Error('型が間違っています');
  }
  if (Object.keys(params).length === 0) {
    throw new Error('更新項目が入っていません');
  }

  const updatableFields = new Set([
    'username',
    'postal_code',
    'address',
    'language',
    'description',
  ]);
  const differentFields = new Set(Object.keys(params).filter((e) => !updatableFields.has(e)));

  if (differentFields.size !== 0) {
    throw new Error('更新可能ではない項目が入っています');
  }

  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.put(url, params, { headers });
}

async function putUsername(
  jwt: string | null,
  userId: string,
  username: string,
): Promise<AxiosResponse> {
  return putUserAccount(jwt, userId, { username });
}

async function putUserAddress(
  jwt: string | null,
  userId: string,
  address: string,
  postalCode: string,
): Promise<AxiosResponse> {
  return putUserAccount(jwt, userId, { address, postal_code: postalCode });
}

async function putUserLanguage(
  jwt: string | null,
  userId: string,
  language: string,
): Promise<AxiosResponse> {
  return putUserAccount(jwt, userId, { language });
}

async function putUserProfile(
  jwt: string | null,
  userId: string,
  username: string,
  postalCode: string,
  address: string,
): Promise<AxiosResponse> {
  return putUserAccount(jwt, userId, { username, postal_code: postalCode, address });
}

async function approveTermsOfService(jwt: string | null, userId: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/approve_terms_of_service/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.put(url, {}, { headers });
}

async function putArtistProfile(
  jwt: string | null,
  userId: string,
  username: string,
  description: string,
): Promise<AxiosResponse> {
  return putUserAccount(jwt, userId, { username, description });
}

async function putStripeCreditCard(
  jwt: string | null,
  userId: string,
  source: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/update_credit_card/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.put(url, { source }, { headers });
}

async function registerStripeId(jwt: string | null, code: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/stripe/token/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, { code }, { headers });
}

async function postUserIcon(
  jwt: string | null,
  userId: string,
  userIcon: IconPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/icons/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, userIcon, { headers });
}

async function getUserIconPresignedPutUrl(
  jwt: string | null,
  userId: string,
  userIconId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/${userId}/icons/${userIconId}/presigned_put_url/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postDigitalArt(
  jwt: string | null,
  digitalArt: HazeruArtDigitalArtCreate,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, digitalArt, { headers });
}

async function getDigitalArt(
  jwt: string | null,
  digitalArtId: string | null = null,
  isOriginal = false,
  artistId: string | null = null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url =
    digitalArtId !== null
      ? `${endpoint}/api/products/digitalarts/${digitalArtId}/`
      : `${endpoint}/api/products/digitalarts/`;
  const params: any = {};
  const headers = generateHeaderFromJwt(jwt);

  // backend側のバグで、is_originalがfalseでもtrueと同じ挙動になってしまう
  if (isOriginal) {
    params.is_original = true;
  }

  if (artistId !== null) {
    params.artist_id = artistId;
  }

  return axios.get(url, { headers, params });
}

async function putDigitalArt(
  jwt: string | null,
  artId: string,
  title: string,
  tags: Array<HazeruArtDigitalArtTag>,
  description: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/${artId}/`;
  const updateParams = {
    title,
    tags,
    description,
  };
  const headers = generateHeaderFromJwt(jwt);
  return axios.put(url, updateParams, { headers });
}

async function deleteDigitalArt(jwt: string | null, artId: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/${artId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function getArtistList(
  jwt: string | null,
  query: string | null = null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  console.log(query);
  const url = `${endpoint}/api/accounts/artists?query=${query}`;
  console.log(url);
  const headers = generateHeaderFromJwt(jwt);
  const params: any = {};
  if (query !== null) {
    params.query = query;
  }
  return axios.get(url, { headers, params });
}

async function getLikeArtistList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artists/likes/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function getLikeArtistListPagination(
  jwt: string | null,
  nextUrl: string,
): Promise<AxiosResponse> {
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(nextUrl, { headers });
}

async function getDigitalArtList(
  jwt: string | null,
  artistId: string | null = null,
  query: string | null = null,
  isOriginal: boolean | null = null,
  categories: Array<HazeruArtDigitalArtCategoryType> | null = null,
  page: number | null = null,
  pageSize: number | null = null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/`;
  const params: any = {};
  const headers = generateHeaderFromJwt(jwt);

  if (artistId !== null) {
    params.artist_id = artistId;
  }
  if (query !== null) {
    params.query = query;
  }
  if (categories !== null) {
    params.category = categories.join(',');
  }
  if (isOriginal !== null) {
    params.is_original = true;
  }
  if (page !== null) {
    params.page = page;
  }
  if (pageSize !== null) {
    params.page_size = pageSize > 0 ? pageSize : 1000;
  }
  return axios.get(url, { headers, params });
}

async function getDigitalArtListPagination(
  jwt: string | null,
  nextUrl: string,
): Promise<AxiosResponse> {
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(nextUrl, { headers });
}

async function getNewestDigitalArtList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/newest_list/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getFeaturedDigitalArtList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/featured_list/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getFeaturedDigitalArtListPage(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/featured_list_page/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getRecommendedDigitalArtList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/recommended_list/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getRecommendedDigitalArtListPage(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/recommended_list_page/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getTopViewsDigitalArtList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/topviews_list/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getDigitalArtPresignedPutUrl(
  jwt: string | null,
  digitalArtId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalarts/${digitalArtId}/presigned_put_url/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postDigitalArtCustomProductGroup(
  jwt: string | null,
  digitalArtId: string,
  catalogItemId: string,
  name: string,
  artLeft: number,
  artTop: number,
  artRight: number,
  artBottom: number,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcustomproductgroups/`;
  const headers = generateHeaderFromJwt(jwt);

  const params: any = {
    name,
    digital_art_id: digitalArtId,
    catalog_item_id: catalogItemId,
    sha1digest: '',
    filename: 'custom-product',
    contenttype: 'image/jpeg',
    art_left: artLeft,
    art_top: artTop,
    art_right: artRight,
    art_bottom: artBottom,
  };

  return axios.post(url, params, { headers });
}

async function getDigitalArtCustomProductGroupList(
  jwt: string | null,
  digitalArtId: string | null = null,
  artistId: string | null = null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcustomproductgroups/`;
  const headers = generateHeaderFromJwt(jwt);

  const params: any = {};
  if (digitalArtId !== null) {
    params.digital_art_id = digitalArtId;
  }

  if (artistId !== null) {
    params.artist_id = artistId;
  }

  return axios.get(url, { headers, params });
}

async function getDigitalArtCustomProductGroup(
  jwt: string | null,
  digitalCustomProductId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcustomproductgroups/${digitalCustomProductId}/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function deleteDigitalArtCustomProductGroup(
  jwt: string | null,
  digitalCustomProductId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcustomproductgroups/${digitalCustomProductId}/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.delete(url, { headers });
}

async function getDigitalArtProductCatalogItems(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartproductcatalogitems/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function postArtistApprovalRequest(
  jwt: string | null,
  body: HazeruArtArtistApprovalRequestPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artistapprovalrequests/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, body, { headers });
}

async function postArtistApprovalRequestDigitalArt(
  jwt: string | null,
  art: HazeruArtArtistApprovalRequestDigitalArtPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/artistapprovalrequestdigitalarts/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, art, { headers });
}

async function getArtistApprovalRequestDigitalArtPresignedPutUrl(
  jwt: string | null,
  artistApprovalRequestDigitalArtId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/artistapprovalrequestdigitalarts/${artistApprovalRequestDigitalArtId}/presigned_put_url/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getChargeEventList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/chargeevents/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function getDigitalArtProduct(
  jwt: string | null,
  digitalArtProductId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartproducts/${digitalArtProductId}/`;
  const params: any = {};
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers, params });
}

async function getDigitalArtProductList(
  jwt: string | null,
  digitalArtId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartproducts/`;
  const params: any = {};
  const headers = generateHeaderFromJwt(jwt);

  params.digital_art_id = digitalArtId;

  return axios.get(url, { headers, params });
}

async function getSoldDigitalArtProductList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/solddigitalartproducts/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getPaymentIntent(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/stripe/paymentintents/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function postPaymentIntent(
  jwt: string | null,
  amount: number,
  currency: string,
  metadata: { [key: string]: number },
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/stripe/paymentintents/`;
  const params = {
    amount,
    currency,
    metadata,
  };
  const headers = generateHeaderFromJwt(jwt);

  return axios.post(url, params, { headers });
}

async function putPaymentIntentProduct(
  jwt: string | null,
  paymentIntentId: string,
  amount: number,
  metadata: { [key: string]: number },
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/stripe/paymentintents/${paymentIntentId}/`;
  const params = {
    amount,
    metadata,
    receive_shipping: null,
  };
  const headers = generateHeaderFromJwt(jwt);

  return axios.put(url, params, { headers });
}

async function putPaymentIntentShipping(
  jwt: string | null,
  paymentIntentId: string,
  amount: number,
  purchaseShipping: StripePaymentIntentShipping,
  receiveShipping: StripePaymentIntentShipping,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/stripe/paymentintents/${paymentIntentId}/`;
  const params = {
    amount,
    purchase_shipping: purchaseShipping,
    receive_shipping: receiveShipping,
  };
  const headers = generateHeaderFromJwt(jwt);

  return axios.put(url, params, { headers });
}

async function getCartHistory(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/carthistory/`;

  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getPayoutEventList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/payoutevents/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postPayoutEvent(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/payoutevents/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, {}, { headers });
}

async function getArtistBankAccount(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artists/payments/details/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postArtistBankAccount(
  jwt: string | null,
  bankInfo: ArtistBankInformation,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artists/payments/details/`;
  const headers = generateHeaderFromJwt(jwt);

  // remove "id" field, not requried/available on initial POST
  const { id, ...bankInfoWithoutId } = bankInfo;
  return axios.post(url, bankInfoWithoutId, { headers });
}

async function putArtistBankAccount(
  jwt: string | null,
  bankInfo: ArtistBankInformation,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artists/payments/details/${bankInfo.id}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.patch(url, bankInfo, { headers });
}

async function deleteArtistBankAccount(
  jwt: string | null,
  bankInfo: ArtistBankInformation,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artists/payments/details/${bankInfo.id}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function getDigitalArtLike(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartlikes/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postDigitalArtLike(
  jwt: string | null,
  digitalArtLike: DigitalArtLikePostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartlikes/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, digitalArtLike, { headers });
}

async function deleteDigitalArtLike(
  jwt: string | null,
  digitalArtLikeId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartlikes/${digitalArtLikeId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function getArtistLike(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artistuserlikes/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postArtistLike(
  jwt: string | null,
  artistLike: ArtistLikePostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artistuserlikes/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, artistLike, { headers });
}

async function deleteArtistLike(jwt: string | null, artistLikeId: string): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/artistuserlikes/${artistLikeId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function getNotifications(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/news/notifications/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postNotificationsRead(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/news/notifications/read/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, {}, { headers });
}

async function getStripePaymentMethods(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/accounts/users/get_stripe_payment_methods/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function getDigitalArtCollectionList(
  jwt: string | null,
  page: number | null = null,
  pageSize: number | null = null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollections/`;
  const headers = generateHeaderFromJwt(jwt);
  const params: any = {};

  if (page) {
    params.page = page;
  }
  if (pageSize !== null) {
    params.page_size = pageSize > 0 ? pageSize : 1000;
  }
  return axios.get(url, { headers, params });
}

async function getDigitalArtCollection(
  jwt: string | null,
  artCollectionId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollections/${artCollectionId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postDigitalArtCollection(
  jwt: string | null,
  artCollection: DigitalArtCollectionPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollections/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, artCollection, { headers });
}

async function deleteDigitalArtCollection(
  jwt: string | null,
  artCollectionId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollections/${artCollectionId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function postDigitalArtCollectionItem(
  jwt: string | null,
  artCollectionItem: DigitalArtCollectionItemPostBody,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollectionitems/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.post(url, artCollectionItem, { headers });
}

async function deleteDigitalArtCollectionItem(
  jwt: string | null,
  artCollectionItemId: string,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/products/digitalartcollectionitems/${artCollectionItemId}/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.delete(url, { headers });
}

async function getDisplayFrameList(jwt: string | null): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/displays/frames/`;
  const headers = generateHeaderFromJwt(jwt);
  return axios.get(url, { headers });
}

async function postFrameChangeAction(
  jwt: string | null,
  displayFrameChangeAction: DisplayFrameChangeAction,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/displays/frames/${displayFrameChangeAction.frameId}/actions/`;
  const headers = generateHeaderFromJwt(jwt);
  const data: any = {
    frame: displayFrameChangeAction.frameId,
    type: displayFrameChangeAction.type,
  };

  // backend側のバグで、is_originalがfalseでもtrueと同じ挙動になってしまう
  if (displayFrameChangeAction.digitalart !== null) {
    data.digitalart = displayFrameChangeAction.digitalart;
  }

  if (displayFrameChangeAction.collection !== null) {
    data.collection = displayFrameChangeAction.collection;
  }

  if (data.digitalart === undefined && data.collection === undefined) {
    throw new Error('digitalartとcollectionはいずれか1つ必要です');
  }

  return axios.post(url, data, { headers });
}

async function getSurveyList(
  jwt: string | null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/surveys/multiplechoicesurveys/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function getSurveyResult(
  jwt: string | null,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/surveys/multiplechoicesurveyresult/`;
  const headers = generateHeaderFromJwt(jwt);

  return axios.get(url, { headers });
}

async function postSurveyResult(
  jwt: string | null,
  survey: Survey, answer: SurveyAnswer,
): Promise<AxiosResponse> {
  const endpoint = process.env.VUE_APP_ARTSUB_BACKEND_ENDPOINT;
  const url = `${endpoint}/api/surveys/multiplechoicesurveyresult/`;
  const headers = generateHeaderFromJwt(jwt);
  const data = {
    survey: survey.id,
    answer: answer.id,
  };

  return axios.post(url, data, { headers });
}

export {
  getUserAccount,
  getUserViewsTimeline,
  getUserLikesTimeline,
  getArtistBalances,
  putUsername,
  putUserAddress,
  putUserLanguage,
  postUserShippingAddress,
  deleteUserShippingAddress,
  putUserProfile,
  putArtistProfile,
  approveTermsOfService,
  putStripeCreditCard,
  registerStripeId,
  postUserIcon,
  getUserIconPresignedPutUrl,
  postDigitalArt,
  getDigitalArt,
  putDigitalArt,
  deleteDigitalArt,
  getDigitalArtLike,
  postDigitalArtLike,
  deleteDigitalArtLike,
  getArtistLike,
  postArtistLike,
  deleteArtistLike,
  getArtistList,
  getLikeArtistList,
  getLikeArtistListPagination,
  getDigitalArtList,
  getDigitalArtListPagination,
  getNewestDigitalArtList,
  getFeaturedDigitalArtList,
  getFeaturedDigitalArtListPage,
  getRecommendedDigitalArtList,
  getTopViewsDigitalArtList,
  getRecommendedDigitalArtListPage,
  getDigitalArtPresignedPutUrl,
  postDigitalArtCustomProductGroup,
  getDigitalArtCustomProductGroupList,
  getDigitalArtCustomProductGroup,
  deleteDigitalArtCustomProductGroup,
  getDigitalArtProductCatalogItems,
  postArtistApprovalRequest,
  postArtistApprovalRequestDigitalArt,
  getArtistApprovalRequestDigitalArtPresignedPutUrl,
  getChargeEventList,
  getDigitalArtProduct,
  getDigitalArtProductList,
  getSoldDigitalArtProductList,
  getPaymentIntent,
  postPaymentIntent,
  putPaymentIntentProduct,
  putPaymentIntentShipping,
  getPayoutEventList,
  postPayoutEvent,
  getCartHistory,
  getArtistBankAccount,
  postArtistBankAccount,
  putArtistBankAccount,
  deleteArtistBankAccount,
  getNotifications,
  postNotificationsRead,
  getStripePaymentMethods,
  getDigitalArtCollectionList,
  getDigitalArtCollection,
  postDigitalArtCollection,
  deleteDigitalArtCollection,
  postDigitalArtCollectionItem,
  deleteDigitalArtCollectionItem,
  getDisplayFrameList,
  postFrameChangeAction,
  getSurveyList,
  getSurveyResult,
  postSurveyResult,
};
