import { HazeruArtDigitalArtThumbnail } from "@/repository/artsub-backend-types";

export default class DigitalArtThumbnailAdapter implements HazeruArtDigitalArtThumbnail {
  readonly id: string;

  // eslint-disable-next-line camelcase
  readonly resolution_type: string;

  readonly filename: string;

  readonly sha1digest: string;

  readonly contenttype: string;

  // eslint-disable-next-line camelcase
  readonly presigned_get_url: string;

  // eslint-disable-next-line camelcase
  readonly updated_datetime: string;

  constructor(params: HazeruArtDigitalArtThumbnail) {
    this.id = params.id;
    this.resolution_type = params.resolution_type;
    this.filename = params.filename;
    this.sha1digest = params.sha1digest;
    this.contenttype = params.contenttype;
    this.presigned_get_url = params.presigned_get_url;
    this.updated_datetime = params.updated_datetime;
  }
}
