




































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import DigitalArtProductCartItemAdapter from '@/adapter/digitalArtProductCartItemAdapter';
import OtherUserAdapter from '@/adapter/otherUserAdapter';
import UserAdapter from '@/adapter/userAdapter';
import {
  HazeruArtDigitalArt,
  HazeruArtDigitalArtProductCartItem,
  OtherUser,
  User,
  StripePaymentIntent,
  StripePaymentMethod,
  StripePaymentMethodListApi,
  ShippingAddressPostBody,
  convertToStripePaymentIntentShipping,
  ShippingAddress,
} from '../../repository/artsub-backend-types';
import CartBody from '../../components/CartBody.vue';
import UserArtProductPaymentProfileList from '../../components/UserArtProductPaymentProfileList.vue';
import {
  getDigitalArtProduct,
  getPaymentIntent,
  getStripePaymentMethods,
  putPaymentIntentShipping, postUserShippingAddress, deleteUserShippingAddress, deleteDigitalArt,
} from '../../repository/artsub-backend';
import { addShippingData, calcProductTotalCost } from '../../lib/costCalculator';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    CartBody,
    UserArtProductPaymentProfileList,
    StripeElementCard,
  },
})
export default class UserArtProductCart extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  flagDifferentProfile = false;

  stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

  productCartItems: Array<DigitalArtProductCartItemAdapter> = [];

  paymentIntent: StripePaymentIntent | null = null;

  isConfirmPhase = false;

  isSending = false;

  token: any | null = null;

  deliveryCharge: number | null = null;

  purchaserShipping: [ShippingAddressPostBody | null, boolean] = [null, false];

  receiverShipping: [ShippingAddressPostBody | null, boolean] = [null, false];

  stripeErrorMessage = '';

  formErrorMessage = '';

  paymentMethods: Array<StripePaymentMethod> = [];

  usingCardIndex = -1;

  deleteTargetShippingAddress: ShippingAddress | null = null;

  deleteDialog = false;

  async created(): Promise<void> {
    try {
      const res = await getPaymentIntent(this.jwtToken);
      this.paymentIntent = res.data;
      console.log(this.paymentIntent);
    } catch (e) {
      console.log(e);
    }

    if (this.paymentIntent !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for await (
        const [digitalArtProductId, amount] of Object.entries(this.paymentIntent.metadata)
      ) {
        const res = await getDigitalArtProduct(this.jwtToken, digitalArtProductId);
        this.productCartItems.push(new DigitalArtProductCartItemAdapter(
          { digital_art_product: res.data, amount }
        ));
      }
    }
  }

  async mounted(): Promise<void> {
    try {
      const res: StripePaymentMethodListApi = (await getStripePaymentMethods(this.jwtToken)).data;
      this.paymentMethods = res.data;
      console.log(this.paymentMethods);

      if (this.paymentMethods.length > 0) {
        this.usingCardIndex = 0;
      }
    } catch (e) {
      console.log(e);
    }
  }

  generateToken(token: any): void {
    console.log(this.isConfirmPhase);
    console.log(token);
    this.token = token;
  }

  async confirmData(): Promise<any> {
    if (!(this.$refs as any).form.validate()) {
      console.log('validate error');
      return this.$vuetify.goTo(0);
    }

    if (this.paymentIntent === null) {
      return this.$vuetify.goTo(0);
    }
    this.isConfirmPhase = true;

    await this.$nextTick();

    this.stripeErrorMessage = '';
    this.formErrorMessage = '';

    await this.$vuetify.goTo(0, { duration: 0 });

    if (this.flagDifferentProfile) {
      (this.$refs as any).receiver.export();
    }
    (this.$refs as any).purchaser.export();

    if (!this.flagDifferentProfile) {
      this.receiverShipping = this.purchaserShipping;
    }
    if (this.purchaserShipping[0] === null || this.receiverShipping[0] === null) {
      this.isConfirmPhase = false;
      return this.$vuetify.goTo(0);
    }
    console.log(this.receiverShipping);
    const amount = addShippingData(
      calcProductTotalCost(this.productCartItems),
      convertToStripePaymentIntentShipping(this.receiverShipping[0]),
    );

    if (this.usingCardIndex === -1) {
      (this.$refs as any).stripeRef.submit();
    }

    try {
      await putPaymentIntentShipping(
        this.jwtToken, this.paymentIntent.id, amount,
        convertToStripePaymentIntentShipping(this.purchaserShipping[0]),
        convertToStripePaymentIntentShipping(this.receiverShipping[0]),
      );
    } catch (e) {
      console.log(e);
      this.isConfirmPhase = false;
      this.formErrorMessage = 'お客様情報に不備があります';
      return this.$vuetify.goTo(0, { duration: 0 });
    }

    await this.$nextTick();

    return this.$vuetify.goTo(0, { duration: 0 });
  }

  async rewriteData(): Promise<any> {
    this.isConfirmPhase = false;

    this.purchaserShipping[0] = null;
    this.receiverShipping[0] = null;

    await this.$nextTick();

    return this.$vuetify.goTo(0, { duration: 0 });
  }

  async purchaseProduct(): Promise<void> {
    if (this.paymentIntent === null) {
      return;
    }
    if (this.usingCardIndex === -1 && this.token === null) {
      return;
    }

    if (this.stripePublishableKey !== undefined) {
      this.isSending = true;
      const stripe = await loadStripe(this.stripePublishableKey);

      if (stripe !== null) {
        const result = await this.confirmCardPayment(stripe, this.paymentIntent);
        await this.purchaseProductPostProcess(result);
      }
      this.isSending = false;
    }
  }

  confirmCardPayment(
    stripe: any,
    paymentIntent: StripePaymentIntent,
  ): Promise<PaymentIntentResult> {
    if (this.usingCardIndex === -1) {
      return stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: {
            token: this.token.id,
          },
        },
        setup_future_usage: 'off_session',
      });
    }

    return stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: this.paymentMethods[this.usingCardIndex].id,
    });
  }

  async purchaseProductPostProcess(result: PaymentIntentResult): Promise<void> {
    if (result.error && result.error.message) {
      console.log(result.error);
      this.stripeErrorMessage = result.error.message;
      this.isConfirmPhase = false;
      await this.$vuetify.goTo('#payment-information', { duration: 0 });
    } else {
      if (this.user !== null && this.purchaserShipping[0] !== null && this.purchaserShipping[1]) {
        await postUserShippingAddress(
          this.jwtToken, this.user.cognito_id, this.purchaserShipping[0],
        );
      }
      if (
        this.user !== null
        && this.flagDifferentProfile
        && this.receiverShipping[0] !== null
        && this.receiverShipping[1]
      ) {
        await postUserShippingAddress(
          this.jwtToken, this.user.cognito_id, this.receiverShipping[0],
        );
      }

      await this.$router.push('/user/payment/thankyou');
      await this.$router.go(0);
    }
  }

  exportPurchaserProfile(data: [ShippingAddressPostBody, boolean]): void {
    this.purchaserShipping = data;
  }

  exportReceiverProfile(data: [ShippingAddressPostBody, boolean]): void {
    this.receiverShipping = data;
  }

  openDeleteDialog(address: ShippingAddress): void {
    this.deleteTargetShippingAddress = address;
    this.deleteDialog = true;
  }

  async deleteShippingAddress(): Promise<void> {
    if (this.deleteTargetShippingAddress !== null && this.user !== null) {
      await deleteUserShippingAddress(
        this.jwtToken, this.user.cognito_id, this.deleteTargetShippingAddress.id,
      );
    }
    this.$router.go(0);
  }

  // eslint-disable-next-line class-methods-use-this
  makePaymentMethodLabel(paymentMethod: StripePaymentMethod): string {
    const { card } = paymentMethod;
    const brand = card.brand.toUpperCase();
    const { last4 } = card;
    const expire = `${card.exp_month.toString().padStart(2, '0')}/${card.exp_year}`;
    return `${brand}  **** **** **** ${last4}  有効期限 ${expire}`;
  }

  get address(): string | null {
    if (this.flagDifferentProfile) {
      if (this.receiverShipping[0] === null) {
        return null;
      }

      return this.receiverShipping[0].state;
    }

    if (this.purchaserShipping[0] === null) {
      return null;
    }

    return this.purchaserShipping[0].state;
  }
}
