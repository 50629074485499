




























































import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import {
  DisplayFrame,
} from '@/repository/artsub-backend-types';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';

@Component
export default class DisplayFrameSelectionCard extends Vue {
  @Prop()
  art!: DigitalArtAdapter;

  @Prop({ default: () => null })
  collection!: DigitalArtCollectionAdapter | null;

  @Prop({ default: () => [] })
  displayFrames!: Array<DisplayFrame>;

  @Emit('selectDisplayFrame')
  // eslint-disable-next-line class-methods-use-this
  selectDisplayFrame(
    displayFrame: DisplayFrame,
    art: DigitalArtAdapter,
    collection: DigitalArtCollectionAdapter | null,
  ): [DisplayFrame, DigitalArtAdapter, DigitalArtCollectionAdapter| null] {
    console.log(displayFrame);
    return [
      displayFrame,
      art,
      collection,
    ];
  }
}
