import { HazeruArtDigitalArt } from '@/repository/artsub-backend-types';
import DigitalArtTagAdapter from '@/adapter/digitalArtTagAdapter';
import DigitalArtThumbnailAdapter from '@/adapter/digitalArtThumbnailAdapter';
import { convertPresignedUrlToImProjectUrl } from '@/lib/im-project-client';

export default class DigitalArtAdapter implements HazeruArtDigitalArt {
  readonly id: string;

  // eslint-disable-next-line camelcase
  readonly artist_id: string;

  readonly title: string;

  readonly tags: Array<DigitalArtTagAdapter>;

  readonly likes: Array<string>;

  // eslint-disable-next-line camelcase
  likes_count: number;

  // eslint-disable-next-line camelcase
  like_id: string | null;

  readonly views?: number;

  readonly filename: string;

  readonly sha1digest: string;

  readonly contenttype: string;

  readonly description: string;

  // eslint-disable-next-line camelcase
  private readonly _presigned_get_url: string;

  // eslint-disable-next-line camelcase
  readonly digital_art_thumbnails: Array<DigitalArtThumbnailAdapter>;

  constructor(params: HazeruArtDigitalArt) {
    this.id = params.id;
    this.artist_id = params.artist_id;
    this.title = params.title;
    this.tags = params.tags;
    this.likes = params.likes;
    this.likes_count = params.likes_count;
    this.like_id = params.like_id;
    this.views = params.views;
    this.filename = params.filename;
    this.sha1digest = params.sha1digest;
    this.contenttype = params.contenttype;
    this.description = params.description;
    // eslint-disable-next-line no-underscore-dangle
    this._presigned_get_url = params.presigned_get_url;
    this.digital_art_thumbnails = params.digital_art_thumbnails.map(
      (thumbnail) => new DigitalArtThumbnailAdapter(thumbnail),
    );
  }

  // eslint-disable-next-line camelcase
  get presigned_get_url(): string {
    // eslint-disable-next-line no-underscore-dangle
    const url = convertPresignedUrlToImProjectUrl(this._presigned_get_url);
    return `${url}?resize_short=700`;
  }

  // eslint-disable-next-line camelcase
  get originalPresignedGetUrl(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._presigned_get_url;
  }

  get thumbnailPresignedGetUrl(): string {
    // eslint-disable-next-line no-underscore-dangle
    const url = convertPresignedUrlToImProjectUrl(this._presigned_get_url);
    return `${url}?resize_short=200`;
  }

  get originalThumbnailPresignedGetUrl(): string {
    if (this.digital_art_thumbnails.length === 0) {
      return this.originalPresignedGetUrl;
    }

    return this.digital_art_thumbnails[0].presigned_get_url;
  }

  setLikeId = (likeId: string): void => {
    this.like_id = likeId;
  };

  setLikesCount = (likesCount: number): void => {
    this.likes_count = likesCount;
  };
}
