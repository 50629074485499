













































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import axios from 'axios';
import {
  getArtistApprovalRequestDigitalArtPresignedPutUrl,
  postArtistApprovalRequest,
  postArtistApprovalRequestDigitalArt,
} from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['jwtToken']),
  components: {
    StripeElementCard,
  },
})
export default class UserArtistApprovalRequest extends Vue {
  jwtToken!: string | null;

  comment = '';

  defaultImage = require('@/assets/preview_dummy.png') // eslint-disable-line global-require

  arts: Array<{ image: File | null, imageUrl: string }> = [
    {
      image: null, imageUrl: '',
    },
    {
      image: null, imageUrl: '',
    },
  ];

  isSubmitting = false;

  // eslint-disable-next-line class-methods-use-this
  get isSubmittable(): boolean {
    return this.arts.every((art) => art.image !== null);
  }

  get imageWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '70vw';
      case 'sm': return '70vw';
      case 'md': return '40vw';
      case 'lg': return '40vw';
      case 'xl': return '25vw';
      default: return '25vw';
    }
  }

  updateArtImage(index: number, event: any): void {
    if (event !== null) {
      [this.arts[index].image] = event.target.files;
      this.arts[index].imageUrl = URL.createObjectURL(event.target.files[0]);
    }
  }

  showFileChooser(refKey: string): void {
    (this.$refs as any)[refKey][0].click();
  }

  async submit(): Promise<void> {
    this.isSubmitting = true;

    await postArtistApprovalRequest(this.jwtToken, { comment: this.comment });

    // eslint-disable-next-line no-restricted-syntax
    for await (const art of this.arts) {
      if (art.image !== null) {
        const sha1digest = await this.toSha1FromImage(art.image);
        const contentType = art.image.type;
        const artistApprovalRequestDigitalArt = (
          await postArtistApprovalRequestDigitalArt(this.jwtToken, {
            filename: art.image.name,
            contenttype: contentType,
            sha1digest,
          })
        ).data;

        const presignedPutUrl = (
          await getArtistApprovalRequestDigitalArtPresignedPutUrl(
            this.jwtToken, artistApprovalRequestDigitalArt.id,
          )).data.presigned_put_url;

        const headers = { 'content-type': 'application/octet-stream' };
        await axios.put(presignedPutUrl, art.image, { headers });
      }
    }

    this.isSubmitting = false;
    await this.$router.push('/user/home');
    await this.$router.go(0);
  }

  // eslint-disable-next-line class-methods-use-this
  async toSha1FromImage(image: File): Promise<string> {
    const imageBuffer = await image.arrayBuffer();
    const digest = await crypto.subtle.digest('sha-1', imageBuffer);

    return Buffer.from(new Uint8Array(digest)).toString('hex');
  }
}
