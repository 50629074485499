































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ShareButtons extends Vue {
  facebookSdkId = 'facebook-jssdk';

  twitterSdkId = 'twitter-wjs';

  created(): void {
    this.loadJs(document, 'script', this.facebookSdkId,
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0');
    this.loadJs(document, 'script', this.twitterSdkId,
      'https://platform.twitter.com/widgets.js');
  }

  // eslint-disable-next-line class-methods-use-this
  loadJs(doc: Document, tagName: string, id: string, src: string): void {
    const fjs = doc.getElementsByTagName(tagName)[0];
    const element = doc.getElementById(id);
    if (element && fjs.parentNode !== null) {
      fjs.parentNode.removeChild(element);
    }

    const fjs2 = doc.getElementsByTagName(tagName)[0];
    const js = doc.createElement(tagName) as HTMLInputElement;
    js.id = id;
    js.src = new URL(src).href;
    if (fjs2.parentNode !== null) {
      fjs2.parentNode.insertBefore(js, fjs2);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get digitalArtWatermarkId(): string {
    // eslint-disable-next-line no-restricted-globals
    const splitCurrentHref = location.href.split('/');
    return splitCurrentHref[splitCurrentHref.length - 1];
  }

  get ogpUrl(): string {
    return encodeURIComponent(
      `${process.env.VUE_APP_HAZERU_OGP_URL_PREFIX}/products/digitalarts/${this.digitalArtWatermarkId}`,
    );
  }

  twitterHref(): void {
    window.open(`https://twitter.com/intent/tweet?url=${this.ogpUrl}`);
  }

  fbHref(): void {
    window.open(`https://www.facebook.com/sharer.php?u=${this.ogpUrl}&layout=button&size=small&width=69&height=20&appId`);
  }

  get isMobile(): boolean {
    return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
  }
}
