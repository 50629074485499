

















































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ArtList from '@/layouts/user/ArtList.vue';
import { gaSearch } from '@/lib/gtag';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import {
  getDigitalArtList,
  getDigitalArtListPagination,
  getFeaturedDigitalArtListPage,
  getRecommendedDigitalArtListPage,
  getDigitalArtCollectionList,
} from '../../repository/artsub-backend';
import {
  DigitalArtCollection,
  HazeruArtDigitalArt, HazeruArtDigitalArtCategoryType,
  HazeruArtDigitalArtPagination, HazeruArtDigitalArtTag, User,
} from '../../repository/artsub-backend-types';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: { ArtList },
})
export default class UserArt extends Vue {
  user!: User | null;

  jwtToken!: string | null;

  name: string | null = null;

  query: string | null = null;

  categories: Array<HazeruArtDigitalArtCategoryType> | null = null;

  subtitle: string | null = null;

  iconUrl: string | null = null;

  arts: Array<DigitalArtAdapter> = [];

  nextUrl: string | null = null;

  notificationMissingArt = false;

  digitalArtCollections: Array<DigitalArtCollection> = [];

  async created(): Promise<void> {
    this.$watch(
      () => this.$route.params,
      async (toParams, previousParams) => {
        await this.fetchFirstPage();
      },
    );
    await this.fetchFirstPage();
    const res = await getDigitalArtCollectionList(this.jwtToken);
    const collections: Array<DigitalArtCollection> = res.data.results;
    this.digitalArtCollections = collections.filter(
      (collection) => collection.created_by.cognito_id === this.user?.cognito_id,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  makeDigitalArtKey(prefix: string, art: HazeruArtDigitalArt): string {
    return `${prefix}-${art.id}`;
  }

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  async fetchFirstPage(): Promise<void> {
    this.query = Object.prototype.hasOwnProperty.call(this.$route.query, 'query')
      ? this.$route.query.query as string : null;
    const csvCategories = Object.prototype.hasOwnProperty.call(this.$route.query, 'categories')
      ? this.$route.query.categories as string : null;
    if (csvCategories !== null) {
      this.categories = csvCategories.split(',') as Array<HazeruArtDigitalArtCategoryType>;
    }

    let res : HazeruArtDigitalArtPagination;
    if (this.$route.params.kind === 'feature') {
      res = (await getFeaturedDigitalArtListPage(this.jwtToken)).data;
      this.name = 'いま注目のアート作品';
      this.subtitle = '人気上昇中!';
      // eslint-disable-next-line global-require
      this.iconUrl = require('../../assets/icon/featured.png');
    } else if (this.$route.params.kind === 'newest') {
      res = (await getDigitalArtList(this.jwtToken, null, null)).data;
      this.name = '新着アート作品';
      this.subtitle = '最新作をCHECK';
      // eslint-disable-next-line global-require
      this.iconUrl = require('@/assets/icon/new_arrival.png');
    } else if (this.$route.params.kind === 'recommended') {
      res = (await getRecommendedDigitalArtListPage(this.jwtToken)).data;
      this.name = 'おすすめアート作品';
      this.subtitle = 'HAZERU ARTからあなたへのレコメンド';
      // eslint-disable-next-line global-require
      this.iconUrl = require('@/assets/icon/recommend.png');
    } else {
      if (this.query !== null) {
        gaSearch(this.query);
      } else if (this.categories !== null) {
        gaSearch(this.categories.join(','));
      }
      res = (await getDigitalArtList(
        this.jwtToken, null, this.query, null, this.categories,
      )).data;
      this.name = 'アート検索結果';
      this.iconUrl = null;
    }
    this.arts = res.results.map((art) => new DigitalArtAdapter(art));
    this.nextUrl = res.next;
  }

  async fetchNextPage(): Promise<void> {
    if (this.nextUrl === null) {
      this.notificationMissingArt = true;
      return;
    }

    const res: HazeruArtDigitalArtPagination = (
      await getDigitalArtListPagination(this.jwtToken, this.nextUrl)).data;

    const nextArt = res.results.map((art) => new DigitalArtAdapter(art))
    this.arts = this.arts.concat(nextArt);
    this.nextUrl = res.next;
  }
}
