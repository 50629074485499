








































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  deleteDigitalArtCollectionItem,
  getDigitalArtCollection, getDisplayFrameList,
  postFrameChangeAction,
} from '@/repository/artsub-backend';
import {
  DigitalArtCollection,
  DisplayFrame,
  HazeruArtDigitalArt,
} from '@/repository/artsub-backend-types';
import DisplayFrameSelectionCard from '@/components/DisplayFrameSelectionCard.vue';
import UserAdapter from '@/adapter/userAdapter';
import DigitalArtCollectionAdapter from '@/adapter/digitalArtCollectionAdapter';
import DigitalArtCollectionItemAdapter from '@/adapter/digitalArtCollectionItemAdapter';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    DisplayFrameSelectionCard,
  },
})
export default class UserCollectionDetail extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  collection: DigitalArtCollectionAdapter | null = null;

  isCollectionCreatedBy = false;

  deleteItemDialog = false;

  targetTrashCollectionItem: DigitalArtCollectionItemAdapter | null = null;

  displayFrames: Array<DisplayFrame> = [];

  frameDialog = false;

  deleting = false;

  async created(): Promise<void> {
    try {
      await this.setCollection();
    } catch (e) {
      if (e.response.status === 404) {
        await this.$router.push('/user/home');
      }
    }
    this.isCollectionCreatedBy = this.collection?.created_by.cognito_id === this.user?.cognito_id;

    if (this.jwtToken !== null) {
      this.displayFrames = (await getDisplayFrameList(this.jwtToken)).data.results;
    }
  }

  toArtistDetail(artistId: string): void {
    this.$router.push(`/user/artist/${artistId}`);
  }

  async castToDisplayFrame(
    data: [DisplayFrame, HazeruArtDigitalArt, DigitalArtCollection | null],
  ): Promise<void> {
    const [displayFrame, art, collection] = data;
    if (collection !== null) {
      await postFrameChangeAction(this.jwtToken, {
        frameId: displayFrame.id,
        type: 'collection',
        collection: collection.id,
        digitalart: art.id,
      });
    }
    this.frameDialog = false;
  }

  toArtDetail(artId: string): void {
    console.log(`/user/art/${artId}`);
    this.$router.push(`/user/art/${artId}`);
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '30vw';
      case 'sm': return '30vw';
      case 'md': return '140px';
      case 'lg': return '140px';
      case 'xl': return '140px';
      default: return '140px';
    }
  }

  async deleteItem(): Promise<void> {
    this.deleting = true;
    if (this.targetTrashCollectionItem != null) {
      await deleteDigitalArtCollectionItem(this.jwtToken, this.targetTrashCollectionItem.id);
    }
    await this.setCollection();
    this.deleteItemDialog = false;
    this.targetTrashCollectionItem = null;
    this.deleting = false;
  }

  async setCollection(): Promise<void> {
    const res: DigitalArtCollection = (
      await getDigitalArtCollection(this.jwtToken, this.$route.params.id)
    ).data;
    this.collection = new DigitalArtCollectionAdapter(res);
  }

  cancelDeleteItem(): void {
    this.deleteItemDialog = false;
  }

  popUpTrashDialog(itemId: DigitalArtCollectionItemAdapter): void {
    this.deleteItemDialog = true;
    this.targetTrashCollectionItem = itemId;
  }
}

