














































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {Survey, SurveyAnswer} from "@/repository/artsub-backend-types";
import {postSurveyResult} from "@/repository/artsub-backend";
import {mapGetters} from "vuex";

@Component({
  computed: mapGetters(['user', 'jwtToken']),
})
export default class SurveyDialog extends Vue {
  @Prop({ default: false })
  dialog!: boolean;

  @Prop({ default: null})
  survey!: Survey;

  jwtToken!: string | null;


  step1 = true;

  step2 = false;

  tyDialog = false;

  answer: SurveyAnswer | null = null;

  answerSurvey(): void {
    console.log(this.survey);
    this.step1 = false;
    this.step2 = true;
  }

  get displayAnswers(): Array<SurveyAnswer> {
    return this.survey.answers.filter(ans => ans.display);
  }

  get declineAnswer(): SurveyAnswer | null {
    const nonDisplayAnswer = this.survey.answers.filter(ans => !ans.display);
    if (nonDisplayAnswer.length === 1) {
      return nonDisplayAnswer[0];
    }

    this.$emit("closeDialog");

    // answerにdeclineの選択肢がない場合nullを返却する。
    return null;
  }

  closeSurveyDialogAfterAnswer(): void {
    this.$emit("closeDialog");
  }

  get question(): string {
    return this.survey.question.replace("<br>", "\n");
  }

  sendAnswer(): void {
    this.dialog = false;
    this.tyDialog = true;
    if (this.answer) {
      postSurveyResult(this.jwtToken, this.survey, this.answer);
    }
  }

  closeSurveyDialog(): void {
    this.dialog = false;
    this.$emit("closeDialog");
    if (this.declineAnswer) {
      postSurveyResult(this.jwtToken, this.survey, this.declineAnswer);
    }
  }
}
