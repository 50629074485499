import { DigitalArtCollection } from "@/repository/artsub-backend-types";
import OtherUserAdapter from "@/adapter/otherUserAdapter";
import DigitalArtCollectionItemAdapter from "@/adapter/digitalArtCollectionItemAdapter";

export default class DigitalArtCollectionAdapter implements DigitalArtCollection {
  readonly id: string;

  readonly items: Array<DigitalArtCollectionItemAdapter>;

  readonly name: string;

  // eslint-disable-next-line camelcase
  readonly created_by: OtherUserAdapter;

  // eslint-disable-next-line camelcase
  readonly created_datetime: string;

  // eslint-disable-next-line camelcase
  readonly updated_by: string;

  // eslint-disable-next-line camelcase
  readonly updated_datetime: string;

  constructor(params: DigitalArtCollection) {
    this.id = params.id;
    this.items = params.items.map((item) => new DigitalArtCollectionItemAdapter(item));
    this.name = params.name;
    this.created_by = new OtherUserAdapter(params.created_by);
    this.created_datetime = params.created_datetime;
    this.updated_by = params.updated_by;
    this.updated_datetime = params.updated_datetime;
  }

  // eslint-disable-next-line camelcase
  get image_presigned_get_url(): string {
    if (this.items.length > 0) {
      return this.items[0].art.thumbnailPresignedGetUrl;
    }

    // eslint-disable-next-line global-require
    return require("@/assets/icon/frame/collection.png");
  }
}
