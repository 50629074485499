









































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import { getDigitalArt, putDigitalArt } from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['jwtToken']),
})
export default class ArtistArt extends Vue {
  jwtToken!: string | null;

  art: DigitalArtAdapter | null = null;

  newTitle = '';

  newTags: Array<string> = [];

  newDescription = '';

  isSubmitting = false;

  flagTermsOfService = false;

  termsOfServiceUrl = process.env.VUE_APP_TERMS_OF_SERVICE_URL;

  async created(): Promise<void> {
    const resDigitalArt = await getDigitalArt(this.jwtToken, this.$route.params.id, true);
    this.art = new DigitalArtAdapter(resDigitalArt.data);

    if (this.art !== null) {
      this.newTitle = this.art.title;
      this.newTags = this.art.tags.map((tag) => tag.name);
      this.newDescription = this.art.description;
    }
  }

  async update(): Promise<void> {
    if (this.art !== null) {
      this.isSubmitting = true;
      await putDigitalArt(
        this.jwtToken,
        this.art.id,
        this.newTitle,
        this.newTags.map((tag) => ({ name: tag })),
        this.newDescription,
      );

      this.isSubmitting = false;
      await this.$router.push('/artist/art');
    }
  }

  remove(item: string): void {
    this.newTags.splice(this.newTags.indexOf(item), 1);
    this.newTags = [...this.newTags];
  }

  get isUpdatable(): boolean {
    return this.newTitle !== '' && this.newDescription !== '' && this.flagTermsOfService;
  }
}
