

































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import axios from 'axios';

import store from '@/store';
import UserAdapter from '@/adapter/userAdapter';
import DialogImageCropper from '../../components/DialogImageCropper.vue';
import { Icon, User } from '../../repository/artsub-backend-types';
import {
  getUserIconPresignedPutUrl,
  postUserIcon,
  putArtistProfile,
} from '../../repository/artsub-backend';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    DialogImageCropper,
  },
})
export default class ArtistProfile extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  // eslint-disable-next-line global-require
  iconSrc: string = require('@/assets/defaults/default_icon.png');

  dialog = false;

  submitting = false;

  dialogImageCropperKey = 0;

  notificationBar = false;

  newUserName = '';

  newDescription = '';

  newIconImage: File | null = null;

  newIconSha1Digest = '';

  newIconFileName = '';

  newIconContentType = '';

  editUserName = false;

  editDescription = false;

  userNameRule = [(value : string): string | boolean => !!value || '入力は必須です'];

  async created(): Promise<void> {
    if (this.user === null) {
      // userがnullということは未承認ということなのでログイン画面に戻す
      await this.$router.push('/auth');
      return;
    }
    this.newUserName = this.user.username;
    this.newDescription = this.user.description;

    this.iconSrc = this.user.icon_presigned_get_url;
  }

  forceRender(): void {
    // Componentをre-renderさせる
    // https://michaelnthiessen.com/force-re-render/
    this.dialogImageCropperKey += 1;
  }

  dialogImageCropperSave(
    {
      canvas,
      sha1digest,
      fileName,
      contentType,
    }: { canvas: HTMLCanvasElement, sha1digest: string, fileName: string, contentType: string },
  ): void {
    this.dialog = false;
    this.forceRender();

    this.iconSrc = canvas.toDataURL();

    canvas.toBlob((blob: Blob | null) => {
      if (blob !== null) {
        this.newIconImage = new File([blob], fileName);
        this.newIconSha1Digest = sha1digest;
        this.newIconFileName = fileName;
        this.newIconContentType = contentType;
      }
    });
  }

  dialogImageCropperCancel(): void {
    this.dialog = false;
    this.forceRender();
  }

  async update(): Promise<void> {
    if (this.user === null) {
      // userがnullということは未承認ということなのでログイン画面に戻す
      await this.$router.push('/auth');
      return;
    }

    this.submitting = true;
    if (this.newIconImage !== null) {
      const resIcon = await postUserIcon(this.jwtToken, this.user.cognito_id, {
        sha1digest: this.newIconSha1Digest,
        filename: this.newIconFileName,
        conenttype: this.newIconContentType,
      });
      const icon: Icon = resIcon.data;

      const resPresignedPutUrl = await getUserIconPresignedPutUrl(
        this.jwtToken, this.user.cognito_id, icon.id,
      );
      const presignedPutUrl = resPresignedPutUrl.data.presigned_put_url;

      const headers = { 'content-type': 'application/octet-stream' };
      await axios.put(presignedPutUrl, this.newIconImage, { headers });
    }
    await putArtistProfile(
      this.jwtToken, this.user.cognito_id, this.newUserName, this.newDescription,
    );

    await store.dispatch('syncAuthenticatedUser');
    this.submitting = false;
    this.notificationBar = true;

    this.newIconImage = null;
  }
}
