






















































































































import { Component, Vue } from 'vue-property-decorator';
import {
  HazeruArtDigitalArt,
  HazeruArtDigitalArtPagination
} from '@/repository/artsub-backend-types';
import {
  getDigitalArt,
  getUserAccount,
  postArtistLike,
  deleteArtistLike,
  getDigitalArtListPagination,
} from '@/repository/artsub-backend';
import { mapGetters } from 'vuex';
import ArtistLikeBtn from '@/components/ArtistLikeBtn.vue';
import DigitalArtAdapter from '@/adapter/digitalArtAdapter';
import OtherUserAdapter from '@/adapter/otherUserAdapter';

@Component({
  components: { ArtistLikeBtn },
  computed: mapGetters(['jwtToken']),
})
export default class UserArtistProfile extends Vue {
  jwtToken!: string | null;

  arts: Array<DigitalArtAdapter> = [];

  artist: OtherUserAdapter | null = null;

  nextUrl: string | null = null;

  notificationMissingArt = false;

  async created(): Promise<void> {
    const resDigitalArts = (await getDigitalArt(
      this.jwtToken, null, false, this.$route.params.id,
    ));
    this.arts = (resDigitalArts.data.results).map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art));
    this.nextUrl = resDigitalArts.data.next;

    const resArtist = await getUserAccount(this.jwtToken, this.$route.params.id);
    // TODO:is_artist===trueのときのみ表示するようにしたい
    this.artist = new OtherUserAdapter(resArtist.data);
  }

  async fetchNextPage(): Promise<void> {
    if (this.nextUrl === null) {
      this.notificationMissingArt = true;
      return;
    }
    const res: HazeruArtDigitalArtPagination = (
      await getDigitalArtListPagination(this.jwtToken, this.nextUrl)).data;

    const nextArt = (res.results).map((art: HazeruArtDigitalArt) => new DigitalArtAdapter(art));
    this.arts = this.arts.concat(nextArt);
    this.nextUrl = res.next;
  }

  get artistUsername(): string {
    if (this.artist === null) {
      return '';
    }

    return this.artist.username;
  }

  toArtDetail(artId: string): void {
    this.$router.push(`/user/art/${artId}`);
  }

  // eslint-disable-next-line class-methods-use-this
  makeDigitalArtKey(prefix: string, art: DigitalArtAdapter): string {
    return `${prefix}-${art.id}`;
  }

  get imgSize(): string {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '45vw';
      case 'sm': return '45vw';
      default: return '130px';
    }
  }

  async addArtistLike(artistId: string): Promise<void> {
    try {
      const res = await postArtistLike(this.jwtToken, { artist: artistId });
      if (this.artist) {
        this.artist.like_id = res.data.id;
        this.artist.likes_count += 1;
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.status_code);
    }
  }
}
