

























































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import UserAdapter from '@/adapter/userAdapter';
import { User } from '../../repository/artsub-backend-types';
import { approveTermsOfService, putUsername } from '../../repository/artsub-backend';
import store from '../../store';

@Component({
  computed: mapGetters(['user', 'jwtToken']),
  components: {
    StripeElementCard,
  },
})
export default class UserTermsOfService extends Vue {
  user!: UserAdapter | null;

  jwtToken!: string | null;

  username = '';

  usernameErrorMessage = '';

  flagTermsOfService = false;

  flagSecurityPolicy = false;

  flagCopyright = false;

  termsOfServiceUrl = process.env.VUE_APP_TERMS_OF_SERVICE_URL;

  securityPolicyUrl = process.env.VUE_APP_SECURITY_POLICY_URL;

  copyrightUrl = process.env.VUE_APP_COPYRIGHT_URL;

  submitting = false;

  async register(): Promise<void> {
    this.submitting = true;
    if (this.user !== null) {
      try {
        await putUsername(
          this.jwtToken, this.user.cognito_id, this.username,
        );
      } catch (e) {
        if (Object.prototype.hasOwnProperty.call(e.response.data, 'username')) {
          [this.usernameErrorMessage] = e.response.data.username;
        }
        this.submitting = false;
        return;
      }
      await approveTermsOfService(this.jwtToken, this.user.cognito_id);

      await store.dispatch('syncAuthenticatedUser');
      await this.$router.replace({ path: '/user/home' });
    }
    this.submitting = false;
  }

  get completed(): boolean {
    return this.username !== ''
      && this.flagTermsOfService
      && this.flagSecurityPolicy
      && this.flagCopyright;
  }

  // eslint-disable-next-line class-methods-use-this
  ruleRequired(value: string): boolean | string {
    if (value === '') {
      return '必須項目です。';
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  ruleZipcode(zipcode: string): boolean | string {
    const re = /\d{7}/;
    return re.test(zipcode);
  }
}
